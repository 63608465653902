import React from "react";
import { Col, Row } from "react-bootstrap";
import ShareholderNav from "../../components/ShareholderNav";
import ShareholderSidebar from "../../components/ShareholderSidebar";
import "./ShareholderClaimsDetails.css";
import Footer from "../../components/Footer";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Icon from "@mdi/react";
import { mdiCalendarRangeOutline } from "@mdi/js";

const ShareholderClaimsDetails = () => {
  return (
    <div>
      <ShareholderNav />
      <Row style={{ paddingTop: "2%" }}>
        <Col sm={2} md={2} lg={2}>
          <div className="container">
            <ShareholderSidebar />
          </div>
        </Col>
        <Col sm={9} md={9} lg={9}>
          <div className="container ">
            <Card className="claims-details-card">
              <Card.Body>
                <Row className="claims-details-row2">
                  <Card.Title>
                    <p className="claims-details-title">
                      <Icon
                        className="me-2"
                        path={mdiCalendarRangeOutline}
                        title="User Profile"
                        size="30px"
                        color="#0A2240"
                      />
                      CL202
                    </p>
                  </Card.Title>
                  <Card.Text>
                    <h6 className="claims-details-card-title">
                      <span className="claims-span">Requested On</span> 17th
                      June, 2022
                    </h6>
                  </Card.Text>
                  <Button
                    variant="warning"
                    disabled
                    className="claims-button"
                  >
                    Pending
                  </Button>
                </Row>
                <Row className="claims-details-row1">
                  <Col className="claims-details-first-column">
                    <p className="claims-periods">PERIODS</p>
                    <p className="claims-period">Period</p>
                    <p className="claims-date">2021/2022</p>
                    <p className="claims-date">2020/2021</p>
                  </Col>
                  <Col className="claims-details-second-column"></Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default ShareholderClaimsDetails;
