import { Grid, Typography } from '@mui/material';
import React from 'react';

function ClaimTicket({
  ticket_number = 'N/A',
  shareholder_code = 'N/A',
  user_comment = 'N/A',
  created_by = 'N/A',
  comment = 'N/A',
  years_claimed = 'N/A',
}) {
  const ticketDetailsStyles = {
    marginBottom: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>Ticket Number</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder Code</Typography>
        <Typography sx={ticketDetailsStyles}>User Comment</Typography>
        <Typography sx={ticketDetailsStyles}>Comment</Typography>
        <Typography sx={ticketDetailsStyles}>Years Claimed</Typography>
        <Typography sx={ticketDetailsStyles}>Created By</Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>{ticket_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{shareholder_code}</Typography>
        <Typography sx={ticketDetailsStyles}>{user_comment}</Typography>
        <Typography sx={ticketDetailsStyles}>{comment}</Typography>
        <Typography sx={ticketDetailsStyles}>
          {Array.isArray(years_claimed) &&
            years_claimed?.map((year) => `${year}, `)}
        </Typography>
        <Typography sx={ticketDetailsStyles}>{created_by}</Typography>
      </Grid>
    </Grid>
  );
}

export default ClaimTicket;
