import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { UpdateButton } from "../../components/styled/buttons";
import { FormInputLabel } from "../../components/styled/inputs";
import { BootstrapInput } from "../../components/styled/labels";
import officerService from "../../service/officer/officerService";
import ShareholderWrapper from "./ShareholderWrapper";

// Alert imports
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../components/alertModal";
import { generateShareholderQrCode } from "../../service/userService";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import { setShareholderQrCode } from "../../store/userSlice";

function ShareholderChangePassword() {
  const navigate = useNavigate();
  const [passwordUpdateData, setPasswordUpdateData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const [isSubmittting, setIsSubmittting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  // Update my state from form change
  const setInputFieldData = (e) => {
    const { name, value } = e.target;
    setPasswordUpdateData({
      ...passwordUpdateData,
      [name]: value,
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const updateOfficerPassword = async (event) => {
    event.preventDefault();
    setIsSubmittting(true);
    await officerService
      .updateOfficerNewPassword({
        officerCode: sessionStorage.getItem('officer_code'),
        action: "UPDATE",
        data: passwordUpdateData,
      })
      .then(() => {
        const message = `The password has been successfully updated`;
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(message));
        dispatch(openAlert());
        setPasswordUpdateData({
          old_password: "",
          password: "",
          confirm_password: "",
        });
      })
      .catch((error) => {
        const errorMsg =
          error?.response?.data && error?.response?.data?.message
            ? error?.response?.data?.message
            : "An error occured while updating you password";
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage(errorMsg));
        dispatch(openAlert());
      });
    setIsSubmittting(false);
  };

  const [isPageVisible, setPageVisible] = useState(false);

  const togglePage = () => {
    setPageVisible(!isPageVisible);
  };

  const generateSecret = async () => {
    generateShareholderQrCode().then((response) => {
      dispatch(setShareholderQrCode(response.data));
    });
    navigate("/shareholder-secret");
  };

  return (
    <ShareholderWrapper title="Change officer password">
      <Box
        sx={{
          bgcolor: "#ffffff",
          mt: 4,
          borderRadius: 2,
          pl: 4,
          pr: 4,
        }}
      >
        <Container>
          {/* Alert modal for dividends claim */}
          {alert.open === true ? (
            <AlertModal
              sx={{
                margin: "20px",
                width: "50%",
                align: "right",
              }}
            ></AlertModal>
          ) : (
            <></>
          )}
          {/* End of Alert modal for dividends claim */}
          <Stack
            direction="column"
            sx={{
              borderBottom: "1px solid #00000040",
              pt: 8,
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 24,
              }}
            >
              Change Password
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 16,
                mt: 1,
                color: "#444A4A",
              }}
            >
              Manage your Password
            </Typography>
          </Stack>
          <form
            onSubmit={(e) => updateOfficerPassword(e)}
            onChange={setInputFieldData}
          >
            <Box
              noValidate
              autoComplete="off"
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 4,
                pb: 8,
                pt: 4,
              }}
            >
              <FormControl variant="standard">
                <FormInputLabel shrink htmlFor="bootstrap-input">
                  Old Password
                </FormInputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  name="old_password"
                  value={passwordUpdateData?.old_password}
                  required
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="standard">
                <FormInputLabel shrink htmlFor="bootstrap-input">
                  New Password
                </FormInputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  name="password"
                  value={passwordUpdateData?.password}
                  required
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="standard">
                <FormInputLabel shrink htmlFor="bootstrap-input">
                  Confirm New Password
                </FormInputLabel>
                <BootstrapInput
                  id="bootstrap-input"
                  name="confirm_password"
                  value={passwordUpdateData?.confirm_password}
                  required
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl variant="standard">
                <UpdateButton
                  type="submit"
                  disabled={isSubmittting}
                  sx={{ bgcolor: isSubmittting ? "grey" : "" }}
                >
                  {isSubmittting && <CircularProgress color="secondary" />}
                  {!isSubmittting && "Change Password"}
                </UpdateButton>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    borderBottom: "1px solid #00000040",
                  }}
                >
                  <Grid item xs={9}>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "24px",
                        color: "#000000",
                      }}
                    >
                      Two-factor authentication
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPageVisible}
                          onChange={togglePage}
                          color="primary"
                        />
                      }
                      // label="Toggle Page"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#444A4A",
                      }}
                    >
                      Two-factor authentication adds an additional layer of
                      security to your account by requiring more than just a
                      password to sign in.
                    </Typography>
                  </Grid>
                </Grid>

                {isPageVisible && (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={generateSecret}
                            color="primary"
                            sx={{
                              fontWeight: 400,
                              fontSize: "16px",
                              color: "#444A4A",
                            }}
                          />
                        }
                        label="Google Authenticator"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            sx={{
                              fontWeight: 400,
                              fontSize: "16px",
                              color: "#444A4A",
                            }}
                            disabled
                          />
                        }
                        label="Email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            sx={{
                              fontWeight: 400,
                              fontSize: "16px",
                              color: "#444A4A",
                            }}
                            disabled
                          />
                        }
                        label="SMS"
                      />
                    </Grid>
                  </Grid>
                )}
              </FormControl>
            </Box>
          </form>
        </Container>
      </Box>
    </ShareholderWrapper>
  );
}

export default ShareholderChangePassword;
