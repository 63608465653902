import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import PersistentDrawerLeft from "./components/navigation/sideDrawer";
import ListChangeRequests from "./components/tables/ChangeRequestsTable";
import LoginPage from "./pages/LoginPage";
import AccountOfficerProfilePage from "./pages/adminPages/AdministratorAccountOfficerProfilePage";
import AdministratorDashboard from "./pages/adminPages/AdministratorDashboard";
import AdministratorDividends from "./pages/adminPages/AdministratorDividends";
import AdministratorDividendsSummary from "./pages/adminPages/AdministratorDividendsSummary";
import AdministratorDivivdendBulkUploadView from "./pages/adminPages/AdministratorDivivdendBulkUploadView";
import EditAccountOfficerProfilePage from "./pages/adminPages/AdministratorEditAccountOfficerPage";
import AdministratorEditTicketDetails from "./pages/adminPages/AdministratorEditTicketDetails";
import AdministratorProfilePage from "./pages/adminPages/AdministratorProfilePage";
import AdministratorSchedules from "./pages/adminPages/AdministratorSchedules";
import AdministratorShareholderBulkUploadView from "./pages/adminPages/AdministratorShareholderBulkUploadView";
import ShareholderEditPage from "./pages/adminPages/AdministratorShareholderEditPage";
import ShareholderHistoryPage from "./pages/adminPages/AdministratorShareholderHistoryPage";
import ShareholderProfilePage from "./pages/adminPages/AdministratorShareholderProfilePage";
import AdministratorTickets from "./pages/adminPages/AdministratorTickets";
import AdministratorTicketsDetailsPage from "./pages/adminPages/AdministratorTicketsDetailsPage";
import AdministratorEditProfilePage from "./pages/adminPages/AdminstratorEditProfilePage";
import AdminstratorSettings from "./pages/adminPages/AdminstratorSettings";
import CreateAccountOfficerPage from "./pages/adminPages/CreateAccountOfficerPage";
import CreateAdministratorPage from "./pages/adminPages/CreateAdministratorpage";
import CreateDividendPage from "./pages/adminPages/CreateDividendPage";
import CreateShareholderPage from "./pages/adminPages/CreateShareholderPage";
import ListAccountOfficersPage from "./pages/adminPages/ListAccountOfficersPage";
import ListAdministratorsPage from "./pages/adminPages/ListAdministratorsPage";
import ListShareholdersPage from "./pages/adminPages/ListShareholdersPage";
import ShareholderClaimsDetails from "./pages/shareholderPages/ShareholderClaimsDetails";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ActivateUser from "./pages/ActivateUser";
import ErrorPage from "./pages/ErrorPage";
import NotFound from "./pages/NotFound";
import AdministratorAuthenticationLogs from "./pages/adminPages/AdministratorAuthenticationLogs";
import AdministratorShareholderBulkUploadApprove from "./pages/adminPages/AdministratorShareholderBulkUploadApprove";
import AdministratorTicketsDetailsPageApprove from "./pages/adminPages/AdministratorTicketsDetailsPageApprove";
import ShareholderChangePassword from "./pages/shareholderPages/ShareholderChangePassword";
import ShareholderRequests from "./pages/shareholderPages/ShareholderChangeRequest";
import ShareholderClaimUnpaidDividends from "./pages/shareholderPages/ShareholderClaimUnpaidDividends";
import ShareholderContactSupport from "./pages/shareholderPages/ShareholderContactSupport";
import ShareholderDividends from "./pages/shareholderPages/ShareholderDividends";
import ShareholderEditOfficerProfile from "./pages/shareholderPages/ShareholderEditOfficerProfile";
import ShareholderFAQsPage from "./pages/shareholderPages/ShareholderFAQs";
import ShareholderGenerateSecretKey from "./pages/shareholderPages/ShareholderGenerateSecretKey";
import ShareholderLandingPage from "./pages/shareholderPages/ShareholderLandingPage";
import ShareholderLoginGuide from "./pages/shareholderPages/ShareholderLoginGuide";
import ShareholderNotificationDividends from "./pages/shareholderPages/ShareholderNotificationDividends";
import ShareholderNotificationsPage from "./pages/shareholderPages/ShareholderNotificationsPage";
import ShareholderPayoutsDetailsPage from "./pages/shareholderPages/ShareholderPayoutsDetailsPage";
import ShareholderRequestsDetails from "./pages/shareholderPages/ShareholderRequestsDetails";
import ShareholderSUHLLandingPage from "./pages/shareholderPages/ShareholderSUHLLandingPage";
import { logoutShareholder, refreshToken } from "./service/userService";
import {
  AccountOfficerAuth,
  AdminAuth,
  RequiredAuth,
  useAuth,
} from "./store/authContext";
import { theme } from "./themes/theme";
import jwtDecode from "jwt-decode";
import AdministratorQRSetup from "./pages/adminPages/AdministratorQRSetupPage";
import FileEntriesPage from "./pages/adminPages/FileEntriesPage";
import FileViolationsPage from "./pages/adminPages/AdministratorFileViolationsPage";
// import ResetUser from "./pages/ResetUser";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuth();
  const [timeUp, setTimeUp] = useState(null);
  const authExpTime = useSelector((state) => state.security.AuthExp);

  sessionStorage.setItem("current_location", location?.pathname);

  const logout = () => {
    const performLogout = () => {
      logoutShareholder().then((response) => {
        localStorage.clear();
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        setUser(null);
        navigate("/", { replace: true });
      });
    };

    if (sessionStorage.getItem("refresh_token") === null) {
      performLogout(); // Call the logout function
    } else {
      performLogout(); // Call the logout function
    }
  };
  //Start of Logic to logout a user after a certain time of inactivity

  //function to set for inactivity and logout
  const checkForInactivity = () => {
    //Get Expiry time from local storage
    const expireTime = sessionStorage.getItem("expireTime");

    //if Expire Time is earlier than now, log out
    if (expireTime < Date.now()) {
      logout();
    }
  };

  //function to update expiry time
  const updateExpireyTime = () => {
    //set expire time to 1 hour from now
    const expireTime = Date.now() + 600000;

    //Set Expire time in session storage
    sessionStorage.setItem("expireTime", expireTime);
  };

  //useEffect to set Interval to check for inactivity
  useEffect(() => {
    //Check for inactivity every 5 seconds
    const Interval = setInterval(() => {
      checkForInactivity();
    }, 60000);
    // Clear interval on mount
    return () => clearInterval(Interval);
  }, []);

  //Update Expire Time on any User activity
  useEffect(() => {
    //set initial expire time
    updateExpireyTime();

    // Add event listeners to detect user activity (e.g., mousemove, keypress)
    window.addEventListener("mousemove", updateExpireyTime);
    window.addEventListener("keypress", updateExpireyTime);
    window.addEventListener("click", updateExpireyTime);
    window.addEventListener("scroll", updateExpireyTime);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", updateExpireyTime);
      window.removeEventListener("keypress", updateExpireyTime);
      window.addEventListener("click", updateExpireyTime);
      window.addEventListener("scroll", updateExpireyTime);
    };
  }, []);

  // Trim off the last three digits from the current time so it matches the format from the back
  const convertCurrentTime = () => {
    return Number(new Date().getTime().toString().slice(0, -3));
  };

  // Run this everytime a refresh is done so the timeup timer is updated
  useEffect(() => {
    if (timeUp === null && sessionStorage.getItem('token') !== null && sessionStorage.getItem('token') !== undefined ) {
      let decodedToken = jwtDecode(sessionStorage.getItem('token'));
      setTimeUp(decodedToken.exp);
    }
  }, []);

  useEffect(() => {
    if (authExpTime) {
      setTimeUp((prev) => authExpTime);
    }
  }, [authExpTime]);

  //When the timeUp time is set to the token expiry time, set a timer to run the request for a new refresh token
  useEffect(() => {
    if (timeUp !== null) {
      let currentTime = convertCurrentTime();
      let waitTime = (timeUp - currentTime) * 1000;
      setTimeout(() => getNewRefreshToken(), waitTime);
    }
  }, [timeUp]);

  // Make a call for a new refresh token when the timer expires
  const getNewRefreshToken = () => {
    if (!sessionStorage.getItem('refresh_token')) {
    logout();
  } else {
      const currentRefreshToken = sessionStorage.getItem('refresh_token');
      refreshToken({ refresh_token: currentRefreshToken }).then(response => {
        if (response?.data?.operation_code === 1) {
          logout();
        }else {
          sessionStorage.setItem('token', response?.headers?.authorization);
          sessionStorage.setItem('refresh_token', response?.data?.refresh_token);
          let decodedRfshToken = jwtDecode(response?.headers?.authorization);
          setTimeUp(() => decodedRfshToken.exp);
        }
      })
  }

  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Routes>
          {/* Open Routes */}
          {/* <Route path="/" element={<LoginPage />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/activate-user" element={<ActivateUser />} />
          {/* <Route path="/reset-user" element={<ResetUser />} /> */}
          <Route
            path="/shareholder-login-guide"
            element={<ShareholderLoginGuide />}
          />
          <Route path="/faqs" element={<ShareholderFAQsPage />} />
          <Route
            path="/claim-dividends"
            element={<ShareholderClaimUnpaidDividends />}
          />
          {/* <Route
            path="/shareholder-landing"
            element={<ShareholderSUHLLandingPage />}
          /> */}
          <Route
            path="/"
            element={<ShareholderSUHLLandingPage />}
          />
          <Route path="/contact-us" element={<ShareholderContactSupport />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<NotFound />} />

          <Route element={<RequiredAuth />}>
            2{/* Admin Routes */}
            <Route element={<AdminAuth />}>
              <Route
                path="/administrator-mfa-setup"
                element={<AdministratorQRSetup />}
              />
              <Route
                path="/administrator-dashboard"
                element={<AdministratorDashboard />}
              />
              <Route
                path="/logs"
                element={<AdministratorAuthenticationLogs />}
              />
              <Route path="/settings" element={<AdminstratorSettings />} />

              <Route
                path="/administrators"
                element={<ListAdministratorsPage />}
              />
              <Route
                path="/admin_profile/:administrator_id"
                element={<AdministratorProfilePage />}
              />
              <Route
                path="/administrator-edit-profile/:administrator_id"
                element={<AdministratorEditProfilePage />}
              />
              <Route
                path="/create-administrator"
                element={<CreateAdministratorPage />}
              />

              <Route path="/shareholders" element={<ListShareholdersPage />} />
              <Route
                path="/shareholder-details/:shareholder_code"
                element={<ShareholderProfilePage />}
              />
              <Route
                path="/admin-shareholder-edit/:shareholder_code"
                element={<ShareholderEditPage />}
              />
              <Route
                path="/admin-shareholder-history/:shareholder_code"
                element={<ShareholderHistoryPage />}
              />
              <Route
                path="/administrator-shareholder-bulkupload-view"
                element={<AdministratorShareholderBulkUploadView />}
              />
              <Route
                path="/create-shareholder"
                element={<CreateShareholderPage />}
              />

              <Route
                path="/account-officers"
                element={<ListAccountOfficersPage />}
              />
              <Route
                path="/account-officer-details/:officer_id"
                element={<AccountOfficerProfilePage />}
              />
              <Route
                path="/edit-account-officer"
                element={<EditAccountOfficerProfilePage />}
              />
              <Route
                path="/create-account-officer"
                element={<CreateAccountOfficerPage />}
              />

              {/* <Route path="/administrator-tickets" element={<AdministratorTickets />} /> */}
              <Route path="/tickets" element={<AdministratorTickets />} />
              <Route
                path="/administrator-tickets-details/:admin_id"
                element={<AdministratorTicketsDetailsPage />}
              />
              <Route
                path="/administrator-edit-tickets-details"
                element={<AdministratorEditTicketDetails />}
              />
              <Route
                path="/administrator-tickets-details-approve/:ticket_number"
                element={<AdministratorTicketsDetailsPageApprove />}
              />

              {/* <Route path="/administrator-dividends" element={<AdministratorDividends />} /> */}
              <Route
                path="/approved-payments"
                element={<AdministratorDividends />}
              />
              <Route
                path="/administrator-dividend-bulkupload-view"
                element={<AdministratorDivivdendBulkUploadView />}
              />
              <Route
                path="/file-approve"
                element={<AdministratorShareholderBulkUploadApprove />}
              />
              <Route
                path="/file-entries/:file_id"
                element={<FileEntriesPage />}
              />
              <Route
                path="/file-entries/:file_id/violations"
                element={<FileViolationsPage />}
              />
              <Route path="/create-dividend" element={<CreateDividendPage />} />

              {/* <Route path="/administrator-schedules" element={<AdministratorSchedules />} /> */}
              <Route path="/schedules" element={<AdministratorSchedules />} />
              <Route
                path="/administrator-dividends-summary"
                element={<AdministratorDividendsSummary />}
              />
            </Route>
            {/* Officer Routes */}
            <Route element={<AccountOfficerAuth />}>
              <Route path="/shareholder" element={<ShareholderLandingPage />} />
              <Route path="/requests" element={<ShareholderRequests />} />
              <Route path="/dividends" element={<ShareholderDividends />} />
              <Route
                path="/notifications"
                element={<ShareholderNotificationsPage />}
              />
              <Route
                path="/shareholder-edit-profile"
                element={<ShareholderEditOfficerProfile />}
              />
              <Route
                path="/notifications/dividends-details/:notificationId"
                element={<ShareholderNotificationDividends />}
              />

              <Route path="/test-nav" element={<PersistentDrawerLeft />} />
              {/* Shareholder and Officer end */}
              <Route
                path="/change-password"
                element={<ShareholderChangePassword />}
              />
              <Route
                path="/requests-details"
                element={<ShareholderRequestsDetails />}
              />
              <Route
                path="/payouts-details"
                element={<ShareholderPayoutsDetailsPage />}
              />
              <Route
                path="/claims-details"
                element={<ShareholderClaimsDetails />}
              />
              <Route
                path="/shareholder-secret"
                element={<ShareholderGenerateSecretKey />}
              />
              <Route path="/change-requests" element={<ListChangeRequests />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
