import {
  Box,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ShareholderCard = ({ icon, shareholderCount, name, path }) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ marginTop: "20px" }}>
      <CardContent>
        <Box>
          <Tooltip title={`View ${name}`}>
            <span>
            <IconButton disabled={path === undefined} onClick={event => {navigate(`/${path}`)}}>{icon}</IconButton>
            </span>
          </Tooltip>
        </Box>
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "30px",
            color: " #0A2240",
          }}
        >
          {shareholderCount?.toLocaleString()}
        </Typography>
        <Typography
          sx={{
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "16px",
            color: " #2D3131",
          }}
        >
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ShareholderCard;
