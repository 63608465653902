import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./alertSlice";
import dividendsSlice from "./dividendsSlice";
import securitySlice from "./securitySlice";
import settingsSlice from "./settingsSlice";
import userSlice from "./userSlice";
import ticketSlice from "./ticketSlice";
import administratorSlice from "./administratorSlice";
import accountOfficerSlice from "./accountOfficerSlice";
import fileSlice from "./fileSlice";

export default configureStore({
    reducer:{
        user: userSlice,
        ticket: ticketSlice,
        security: securitySlice,
        alert: alertSlice,
        dividend:dividendsSlice,
        settings: settingsSlice,
        administrator: administratorSlice,
        accountOfficer: accountOfficerSlice,
        files: fileSlice
    }
})