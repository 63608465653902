import { createSlice } from '@reduxjs/toolkit';
import {
  getAllModules,
  getAllPaymentModes,
  getCountries,
} from '../service/settingsService';

const settingsSlice = createSlice({
    name: "settingsStore",
    initialState: {
      country: {},
      countries: [],
      modules: [],
      paymentModes: [],
      taxRates: [],
      brokers: [],
      payMode: {},
      backendPagination: {
        page_Index:0,
        total_pages:0,
        records_displayed:0,
        total_elements:0,
      },
    },
    reducers: {
        setCountries: (state, actions) => {
            state.countries.push(...actions.payload);
            sessionStorage.setItem('countries', JSON.stringify(actions.payload));
        },
        setBrokers: (state, actions) => {
            state.brokers.push(...actions.payload);
        },
        setModules: (state, actions) => {
            state.modules.push(...actions.payload)
        },
        setPaymentModes: (state, actions) => {
            state.paymentModes.push(...actions.payload);
            sessionStorage.setItem('payModes', JSON.stringify(actions.payload));
        },
        setTaxRates: (state, actions) => {
            state.taxRates.push(...actions.payload);
            sessionStorage.setItem('taxrates', JSON.stringify(actions.payload));
        },
        setPayMode: (state, actions) => {
            state.payMode = actions.payload;
        },
        setCountry: (state, actions) => {
            state.country = (state.countries.filter(country => country.iso_code === actions.payload));
        },
        setSettingsBackendPagination: (state, actions) => {
          state.backendPagination = actions.payload
        },
    },
});

export const { setCountries, setModules, setPaymentModes, setTaxRates, setCountry, setBrokers,
              setBackendPagination, incrementBackendTotalElements, setSettingsBackendPagination, setPayMode } = settingsSlice.actions;

export default settingsSlice.reducer;
