import { createSlice } from "@reduxjs/toolkit";

const ticketSlice = createSlice({
  name: "ticketStore",
  initialState: {
    ticket: {},
    tickets: [],
    backendPagination: {
      page_Index:0,
      total_pages:0,
      records_displayed:0,
      total_elements:0,
    },
  },
  reducers: {
    replaceOutdatedTicket: (state, actions) => {
      let res = state.tickets.findIndex(
        (ticket) => ticket.ticket_number === actions.payload.ticket_number
      );
      state.tickets.splice(res, 1, actions.payload);
    },
    addTicket: (state, actions) => {
      state.tickets.splice(0, 0, actions.payload);
    },
    setTicketBackendPagination: (state, actions) => {
      state.backendPagination = actions.payload
    },

    setPage: (state, action) => {
      state.page_index = action.payload;
    },
    setTotalPages: (state, action) => {
      state.total_pages = action.payload;
    },
    setTotalDataElements: (state, action) => {
      state.data_total_elements = action.payload;
    },
    updateTicketsDetails: (state, action) => {
      state.tickets = state.tickets.map((ticket) => {
        if (action.payload.ticket_number === ticket.ticket_number) {
          state.ticket = action.payload;
        }
      });
    },
    setTickets: (state, action) => {
      for (let index = 0; index < action.payload?.length; index++) {
        const element = action.payload[index];
        if (
          !state.tickets.some((e) => e.ticket_number === element.ticket_number)
        ) {
          state.tickets.push(element);
        }
      }
    },
    setCurrentTicket: (state, action) => {
      state.ticket = action.payload;
    },
  },
});
export const {
  replaceOutdatedTicket,
  addTicket,
  setCurrentTicket,
  updateTicketsDetails,
  setTickets,
  setTicketBackendPagination
} = ticketSlice.actions;

export default ticketSlice.reducer;
