import { Button, styled } from '@mui/material';

export const UpdateButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: '#fff',
  height: 35,
  width: 200,
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff60',
  },
}));
