
import { Grid, Typography } from '@mui/material';
import React from 'react';

function DividendTicket({
  ticket_number = 'N/A',
  shareholder_code = 'N/A',
  shareholder_name = 'N/A',
  shares_held = 'N/A',
  created_by = 'N/A',
  created_on = 'N/A',
  user_comment = 'N/A',
  actual_gross_amount = 'N/A',
  actual_net_amount = 'N/A',
  actual_tax_amount = 'N/A',
  tax_rate = 'N/A',
  dividend_year = 'N/A',
  dividend_type = 'N/A',
  payment_account_name = 'N/A',
  payment_account_number = 'N/A',
  payment_branch_name = 'N/A',
  payment_approved = 'N/A',
  payment_status = 'N/A',
}){
// function DividendTicket(props) {
  const ticketDetailsStyles = {
    marginBottom: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>Ticket Number</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder Code</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder Name</Typography>
        <Typography sx={ticketDetailsStyles}>Shares Held</Typography>
        <Typography sx={ticketDetailsStyles}>Created By</Typography>
        <Typography sx={ticketDetailsStyles}>Created On</Typography>
        <Typography sx={ticketDetailsStyles}>User Comment</Typography>
        <Typography sx={ticketDetailsStyles}>Gross Amount</Typography>
        <Typography sx={ticketDetailsStyles}>Net Amount</Typography>
        <Typography sx={ticketDetailsStyles}>Tax Amount</Typography>
        <Typography sx={ticketDetailsStyles}>Tax Rate</Typography>
        <Typography sx={ticketDetailsStyles}>Dividend Year</Typography>
        <Typography sx={ticketDetailsStyles}>Dividend Type</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Account Name</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Account Number</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Branch Name</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Approved</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Status</Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>{props.ticket_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{props.shareholder_code}</Typography>
        <Typography sx={ticketDetailsStyles}>{props.shareholder_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{props.shares_held}</Typography>
        <Typography sx={ticketDetailsStyles}>{created_by}</Typography>
        <Typography sx={ticketDetailsStyles}>{created_on}</Typography>
        <Typography sx={ticketDetailsStyles}>{user_comment}</Typography>
        <Typography sx={ticketDetailsStyles}>{actual_gross_amount}</Typography>
        <Typography sx={ticketDetailsStyles}>{actual_net_amount}</Typography>
        <Typography sx={ticketDetailsStyles}>{actual_tax_amount}</Typography>
        <Typography sx={ticketDetailsStyles}>{tax_rate}</Typography>
        <Typography sx={ticketDetailsStyles}>{dividend_year}</Typography>
        <Typography sx={ticketDetailsStyles}>{dividend_type}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_account_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_account_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_branch_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_approved == true ? 'NO' : 'YES'}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_status}</Typography>
      </Grid>
    </Grid>
  );
}

export default DividendTicket;
