import { createSlice } from '@reduxjs/toolkit';
import {
  getModuleAuthorities,
  getFunctionalGroups,
} from '../service/securityService';

const securitySlice = createSlice({
  name: 'security',
  initialState: {
    functionalGroups: [],
    moduleAuthorities: [],
    currentFunctionalGroup: [],
    myModules: [],
    status: 'idle',
    error: '',
    mfaEnabled: false,
    rfshExp: null,
    AuthExp: null
  },
  reducers: {
    addFunctionalGroup: (state, action) => {
    },
    setModuleNames: (state) => {
      if (state.myModules.length === 0) {
        state.myModules.push(
          ...state.moduleAuthorities.map((mod) => mod.module_name)
        );
      }
    },
    setFunctionalGroups: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (!state.functionalGroups.some(e => e.functional_group_code === element.functional_group_code)){
          state.functionalGroups.push(element);
        }    
      }
    },
    setCurrentFg: (state, action) => {
      if (state.currentFunctionalGroup.length >= 1) {
        state.currentFunctionalGroup.splice(0, 1, action.payload);
      } else {
        state.currentFunctionalGroup.push(action.payload);
      }
    },
    setMfaEnabled: (state, action) => {
      state.mfaEnabled = action.payload;
    },
    setRfshExp: (state, action) => {
      state.rfshExp = action.payload;
    },
    setAuthExp: (state, action) => {
      state.AuthExp = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFunctionalGroups.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getFunctionalGroups.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.functionalGroups = action.payload;
      })
      .addCase(getFunctionalGroups.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getModuleAuthorities.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(getModuleAuthorities.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.moduleAuthorities = action.payload;
      })
      .addCase(getModuleAuthorities.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { addFunctionalGroup, setModuleNames, setCurrentFg, setFunctionalGroups, setMfaEnabled, setRfshExp, setAuthExp } =
  securitySlice.actions;

export default securitySlice.reducer;
