import { CalendarMonth, Close, Visibility } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Avatar, Container, Grid, Modal, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import transformArrayList from 'transform-array-list';
import shareholderService from '../../service/officer/shareholderService';
import ShareholderWrapper from './ShareholderWrapper';
// import ElementObserver from '../../hooks/ElementObserver';

const StyledTypography = styled(Typography)(() => ({
  width: '100%',
  textAlign: 'left',
  fontSize: 16,
  fontWeight: 400,
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: '#0A2240',
  fontWeight: 500,
  fontSize: 16,
}));

const DataStyledTableCell = styled(TableCell)(() => ({
  color: '#474848',
  fontWeight: 500,
  fontSize: 16,
}));

const StyledAvatar = styled(Avatar)(() => ({
  width: '60%',
  borderRadius: '8%',
  height: 30,
  fontSize: 14,
  fontWeight: 400,
}));

const StyledBox = styled(Box)(() => ({
  position: 'absolute',
  top: '70%',
  right: '1%',
  transform: 'translate(-10%, -80%)',
  width: {
    md: '40%',
    sm: '100%',
    xs: '100%',
  },
  borderRadius: 8,
  boxShadow:
    'inset 0 -3em 3em #00000010, 0 0 0 2px #00000020, 0.3em 0.3em 1em #00000050',
}));

const StyledBox1 = styled(Box)(() => ({
  margin: 'auto',
  position: 'absolute',
  top: '50%',
  left: 0,
  bottom: 0, 
  right: 0,
  transform: 'translateY(-50%)',
  borderRadius: 8,
  // boxShadow: 'inset 0 -3em 3em #00000010, 0 0 0 2px #00000020, 0.3em 0.3em 1em #00000050',
}));

const ModalHeaderTypography = styled(Typography)(() => ({
  color: '#000000',
  fontWeight: 500,
  fontSize: 12,
}));

const ModalTextTypography = styled(Typography)(() => ({
  color: '#3F4041',
  fontWeight: 400,
  fontSize: 16,
}));

function ShareholderChangeRequest() {
  // intersection observer
  const { ref: myRef, inView: myElementIsVisible } = useInView();

  const [selectedTab, setSelectedTab] = useState('1');
  const [requests, setRequests] = useState([]);
  const [singleRequest, setSingleRequest] = useState({});
  const [claims, setClaims] = useState([]);
  const [singleClaim, setSingleClaim] = useState({});
  const [claimPageNumber, setClaimPageNumber] = useState(0);
  const [requestPageNumber, setRequestPageNumber] = useState(0);
  const [claimTotalPageNumber, setClaimTotalPageNumber] = useState(0);
  const [requestTotalPageNumber, setRequestTotalPageNumber] = useState(0);
  const [claimTotalElements, setClaimTotalElements] = useState(1);
  const [requestsTotalElements, setRequestTotalElements] = useState(1);

  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [openRequestModal, setOpenRequestModal] = useState(false);
  const handleRequestOpen = () => setOpenRequestModal(true);
  const handleRequestClose = () => setOpenRequestModal(false);

  const routeLocation = useLocation();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const getAllShareholderClaimsByOfficerCode = useCallback(
    async ({ page_number }) => {
      const claimResponse =
        await shareholderService.fetchShareholderTicketsByOfficer({
          ticket_number: 'CLAIM',
          shareholder_code: sessionStorage.getItem('shareholder_code'),
          page_number,
        });

      const {
        data: newClaims,
        total_pages,
        total_elements,
      } = claimResponse.data;

      const claimsTicketNumbers = claims.map((claim) => claim.ticket_number);
      const newClaimsticketNumbers = newClaims.map(
        (claim) => claim.ticket_number
      );

      setClaimTotalPageNumber(total_pages);
      setClaimTotalElements(total_elements);
      if (
        claimsTicketNumbers.length > 0 &&
        transformArrayList.includesAll(
          claimsTicketNumbers,
          newClaimsticketNumbers
        ) === false
      ) {
        setClaims((claimList) => [...claimList, ...newClaims]);
      } else if (claimsTicketNumbers.length <= 0) {
        setClaims((claimList) => [...claimList, ...newClaims]);
      }
    },
    [claims]
  );

  const getAllShareholderRequestsByOfficerCode = useCallback(
    async ({ page_number }) => {
      const requestResponse =
        await shareholderService.fetchShareholderTicketsByOfficer({
          ticket_number: 'MOD',
          shareholder_code: sessionStorage.getItem('shareholder_code'),
          page_number,
        });

      const {
        data: newRequests,
        total_pages,
        total_elements,
      } = requestResponse.data;
      setRequestTotalPageNumber(total_pages);
      setRequestTotalElements(total_elements);

      const requestsTicketNumbers = requests.map(
        (request) => request.ticket_number
      );
      const newRequeststicketNumbers = newRequests.map(
        (request) => request.ticket_number
      );

      if (
        requestsTicketNumbers.length > 0 &&
        transformArrayList.includesAll(
          requestsTicketNumbers,
          newRequeststicketNumbers
        ) === false
      ) {
        setRequests((requestList) => [...requestList, ...newRequests]);
      } else if (requestsTicketNumbers.length <= 0) {
        setRequests((requestList) => [...requestList, ...newRequests]);
      }
    },
    [requests]
  );

  useEffect(() => {
    if (myElementIsVisible) {
      setClaimPageNumber((preValue) => preValue + 1);
      setRequestPageNumber((preValue) => preValue + 1);
    }
  }, [myElementIsVisible]);

  useEffect(() => {
    if (routeLocation?.search !== '') {
      handleChange('_', '2');
    }
    if (claimPageNumber <= claimTotalPageNumber) {
      getAllShareholderClaimsByOfficerCode({ page_number: claimPageNumber });
    }
  }, [
    claimPageNumber,
    routeLocation?.search,
    claimTotalPageNumber,
    claimTotalElements,
    getAllShareholderClaimsByOfficerCode,
  ]);

  useEffect(() => {
    if (requestPageNumber <= requestTotalPageNumber) {
      getAllShareholderRequestsByOfficerCode({
        page_number: requestPageNumber,
      });
    }
  }, [
    requestPageNumber,
    requestTotalPageNumber,
    requestsTotalElements,
    getAllShareholderRequestsByOfficerCode,
  ]);

  const loadSingleClaimData = (index) => {
    setSingleClaim(claims[index]);
    handleOpen();
  };

  const loadSingleRequestData = (index) => {
    setSingleRequest(requests[index]);
    handleRequestOpen();
  };

  return (
    <ShareholderWrapper title="Shareholder Change Requests">
      <Container
        disableGutters={true}
        sx={{ overflowX: 'auto', width: '100%' }}
      >
        <Box
          sx={{
            bgcolor: '#ffffff',
            mt: 4,
            borderRadius: 2,
            width: {
              xs: '150%',
              sm: 'auto',
            },
          }}
        >
          <Stack direction="column">
            <TabContext value={selectedTab}>
              <Box
                sx={{
                  borderBottom: '1.4px solid #90969DED',
                  borderTop: '1.4px solid #90969DED',
                  borderRadius: 2,
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="Shareholder API tabs"
                  variant="fullWidth"
                  indicatorColor="transparent"
                >
                  <Tab
                    label={
                      <StyledTypography
                        sx={{
                          color: selectedTab === '1' ? '#ffffff' : '#90969DED',
                        }}
                      >
                        Change Requests
                      </StyledTypography>
                    }
                    value="1"
                    sx={{
                      justifyContent: 'flex-start',
                      borderRadius: 2,
                      backgroundColor:
                        selectedTab === '1' ? '#052A6C' : 'transparent',
                    }}
                  />
                  {/* <Tab
                    label={
                      <StyledTypography
                        sx={{
                          color: selectedTab === '2' ? '#ffffff' : '#90969DED',
                        }}
                      >
                        Claims
                      </StyledTypography>
                    }
                    value="2"
                    sx={{
                      justifyContent: 'flex-start',
                      borderRadius: 2,
                      backgroundColor:
                        selectedTab === '2' ? '#052A6C' : 'transparent',
                    }}
                  /> */}
                </TabList>
              </Box>

              <TabPanel value="1">
                <Typography
                  variant="p"
                  sx={{ color: '#444A4A', fontWeight: 500, fontSize: 16 }}
                >
                  This table shows all the change requests you have made to your
                  profile
                </Typography>
                <TableContainer
                  sx={{
                    display: 'table',
                    tableLayout: 'fixed',
                    width: '100%',
                  }}
                >
                  <Table
                    aria-label="caption table"
                    sx={{
                      width: {
                        md: '80%',
                        sm: '100%',
                        xs: '100%',
                      },
                      border: '1px solid #B8B7B760',
                      borderRadius: 2,
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ bgcolor: '#B8B7B730' }}>
                        <StyledTableCell align="left">
                          Request Date
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Change Request Number
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Request Status
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ color: 'transparent' }}
                        >
                          Actions
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {requests?.map((request, index) => (
                        <TableRow key={request.ticket_number}>
                          <DataStyledTableCell size="medium" align="left">
                            {request && request.created_on.split('T')[0]}
                          </DataStyledTableCell>
                          <DataStyledTableCell size="medium" align="left">
                            {request.ticket_number}
                          </DataStyledTableCell>
                          <DataStyledTableCell size="medium" align="left">
                            {request.workflow_status === 'PENDING' && (
                              <StyledAvatar
                                sx={{ bgcolor: '#FFE99C', color: '#DA930A' }}
                              >
                                {request.workflow_status}
                              </StyledAvatar>
                            )}
                            {request.workflow_status === 'CLOSED' && (
                              <StyledAvatar
                                sx={{ bgcolor: '#ACE4CF', color: '#01422A' }}
                              >
                                {request.workflow_status}
                              </StyledAvatar>
                            )}

                            {request.workflow_status === 'REJECTED' && (
                              <StyledAvatar
                                sx={{ bgcolor: '#FEA2A2', color: '#850707' }}
                              >
                                {request.workflow_status}
                              </StyledAvatar>
                            )}
                          </DataStyledTableCell>
                          <TableCell size="medium" align="left">
                            <Visibility
                              onClick={() => loadSingleRequestData(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <p ref={myRef}></p>
                </TableContainer>
              </TabPanel>

              <TabPanel value="2">
                <Typography
                  variant="p"
                  sx={{ color: '#444A4A', fontWeight: 500, fontSize: 18 }}
                >
                  This table shows all the claims that you have made for unpaid
                  dividends
                </Typography>
                {/*  */}
                <TableContainer
                  sx={{
                    display: 'table',
                    tableLayout: 'fixed',
                    width: '100%',
                  }}
                >
                  <Table
                    aria-label="caption table"
                    sx={{
                      width: {
                        md: '80%',
                        sm: '100%',
                        xs: '100%',
                      },
                      border: '1px solid #B8B7B760',
                      borderRadius: 2,
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ bgcolor: '#B8B7B730' }}>
                        <StyledTableCell align="left">
                          Submission Date
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Claim Number
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Claim Status
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          sx={{ color: 'transparent' }}
                        >
                          Actions
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {claims?.map((claim, index) => (
                        <TableRow key={claim.ticket_number}>
                          <DataStyledTableCell size="medium" align="left">
                            {claim && claim.created_on.split('T')[0]}
                          </DataStyledTableCell>
                          <DataStyledTableCell size="medium" align="left">
                            {claim.ticket_number}
                          </DataStyledTableCell>
                          <DataStyledTableCell size="medium" align="left">
                            {claim.workflow_status === 'PENDING' && (
                              <StyledAvatar
                                sx={{ bgcolor: '#FFE99C', color: '#DA930A' }}
                              >
                                {claim.workflow_status}
                              </StyledAvatar>
                            )}
                            {claim.workflow_status === 'APPROVED' && (
                              <StyledAvatar
                                sx={{ bgcolor: '#ACE4CF', color: '#01422A' }}
                              >
                                {claim.workflow_status}
                              </StyledAvatar>
                            )}

                            {claim.workflow_status === 'REJECTED' && (
                              <StyledAvatar
                                sx={{ bgcolor: '#FEA2A2', color: '#850707' }}
                              >
                                {claim.workflow_status}
                              </StyledAvatar>
                            )}
                          </DataStyledTableCell>
                          <TableCell size="medium" align="left">
                            <Visibility
                              onClick={() => loadSingleClaimData(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <p ref={myRef}></p>
                </TableContainer>
                {/*  */}
              </TabPanel>
            </TabContext>
          </Stack>
        </Box>
        {singleClaim && (
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyledBox1
              sx={{
                width: {
                  md: '40%',
                  sm: '60%',
                  xs: '90%',
                },
              }}
            >
              <Stack
                direction="column"
                sx={{ borderRadius: 2, position: 'realtive', bgcolor: 'white' }}
              >
                <Close
                  fontSize="medium"
                  sx={{
                    position: 'absolute',
                    right: 40,
                    top: 30,
                    '&:hover': {
                      color: 'red',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={handleClose}
                />
                <Grid container>
                  <Stack
                    direction="column"
                    sx={{
                      width: '100%',
                      mt: 4,
                      mb: 2,
                      ml: 4,
                      mr: 4,
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      sx={{ gap: 2 }}
                    >
                      <Stack direction="row" sx={{ gap: 2, flexWrap: 'wrap' }}>
                        <Typography
                          sx={{
                            color: '#0033A1',
                            fontWeight: 700,
                            fontSize: 32,
                          }}
                        >
                          {singleClaim?.ticket_number}
                        </Typography>
                        <Avatar
                          variant="rounded"
                          sx={{
                            bgcolor:
                              singleClaim &&
                              singleClaim?.workflow_status === 'FAILED'
                                ? '#FEA2A2'
                                : singleClaim &&
                                  singleClaim?.workflow_status === 'PENDING'
                                ? '#FFE99C'
                                : '#ACE4CF',
                            color:
                              singleClaim &&
                              singleClaim?.workflow_status === 'FAILED'
                                ? '#D0021B'
                                : singleClaim &&
                                  singleClaim?.workflow_status === 'PENDING'
                                ? '#DA930A'
                                : '#01422A',
                            pl: 2,
                            pr: 2,
                            width: 'auto',
                          }}
                        >
                          {singleClaim?.workflow_status}
                        </Avatar>
                      </Stack>
                      <Stack direction="row" sx={{ columnGap: 1 }}>
                        <CalendarMonth />
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Submitted On
                        </Typography>
                        <Typography>
                          {JSON.stringify(singleClaim) !== '{}' &&
                            singleClaim?.created_on.split('T')[0]}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      bgcolor: '#F2F3F4B2',
                      width: '100%',
                      borderBottom: '1px solid #D2D1D1',
                      p: '40px 10px 6px 20px',
                      mx: 4,
                      color: '#0033A1',
                      fontWeight: 700,
                      fontSize: 20,
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    CLAIM DETAILS
                  </Stack>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      bgcolor: '#F2F3F4B2',
                      width: '100%',
                      mb: 2,
                      ml: 4,
                      mr: 4,
                      rowGap: 2,
                      p: '10px 30px',
                    }}
                  >
                    <Grid item xs={12}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Period(s)
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleClaim?.content?.years_claimed?.reduce(
                            (prev, acc) => `${prev}, ${acc}`
                          )}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Comment
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleClaim?.content?.comment}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </StyledBox1>
          </Modal>
        )}
        {singleRequest && (
          <Modal
            open={openRequestModal}
            onClose={handleRequestClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <StyledBox
              sx={{
                mr: {
                  xs: '30px',
                },
                mt: {
                  xs: '0',
                },
                width: {
                  md: '40%',
                  sm: '60%',
                  xs: '90%',
                },
              }}
            >
              <Stack
                direction="column"
                sx={{ borderRadius: 2, position: 'realtive', bgcolor: 'white' }}
              >
                <Close
                  fontSize="medium"
                  sx={{
                    position: 'absolute',
                    right: 40,
                    top: 30,
                    '&:hover': {
                      color: 'red',
                      cursor: 'pointer',
                    },
                  }}
                  onClick={handleRequestClose}
                />
                <Grid container>
                  <Stack
                    direction="column"
                    sx={{
                      width: '100%',
                      mt: 4,
                      mb: 2,
                      ml: 4,
                      mr: 4,
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      sx={{ gap: 2 }}
                    >
                      <Stack direction="row" sx={{ gap: 2 }}>
                        <Typography
                          sx={{
                            color: '#0033A1',
                            fontWeight: 700,
                            fontSize: 32,
                          }}
                        >
                          {singleRequest?.ticket_number}
                        </Typography>
                        <Avatar
                          variant="rounded"
                          sx={{
                            bgcolor:
                              singleRequest &&
                              singleRequest?.workflow_status === 'FAILED'
                                ? '#FEA2A2'
                                : singleRequest &&
                                  singleRequest?.workflow_status === 'PENDING'
                                ? '#FFE99C'
                                : '#ACE4CF',
                            color:
                              singleRequest &&
                              singleRequest?.workflow_status === 'FAILED'
                                ? '#D0021B'
                                : singleRequest &&
                                  singleRequest?.workflow_status === 'PENDING'
                                ? '#DA930A'
                                : '#01422A',
                            pl: 2,
                            pr: 2,
                            width: 'auto',
                          }}
                        >
                          {singleRequest?.workflow_status}
                        </Avatar>
                      </Stack>
                      <Stack direction="row" sx={{ columnGap: 1 }}>
                        <CalendarMonth />
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Submitted On
                        </Typography>
                        <Typography>
                          {JSON.stringify(singleRequest) !== '{}' &&
                            singleRequest?.created_on.split('T')[0]}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    sx={{
                      bgcolor: '#F2F3F4B2',
                      width: '100%',
                      borderBottom: '1px solid #D2D1D1',
                      p: '40px 10px 6px 20px',
                      mx: 4,
                      color: '#0033A1',
                      fontWeight: 700,
                      fontSize: 20,
                    }}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    NEW DETAILS
                  </Stack>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      bgcolor: '#F2F3F4B2',
                      width: '100%',
                      mb: 2,
                      ml: 4,
                      mr: 4,
                      rowGap: 2,
                      p: '10px 30px',
                    }}
                  >
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Shareholder SCD Number
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.shareholder_scd_number}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Shareholder Name
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.shareholder_name}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Primary Phone Number
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.primary_phone_number}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Payment Account Name
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.payment_account_name}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Payment Account Number
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.payment_account_number}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Shares Held
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.shares_held}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Tin
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.tin}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Primary Email
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.primary_email}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                    <Grid item sm={12} md={6}>
                      <Stack direction="column" justifyContent="space-between">
                        <ModalHeaderTypography sx={{ fontWeight: 'bold' }}>
                          Primary Address
                        </ModalHeaderTypography>
                        <ModalTextTypography>
                          {singleRequest?.content?.primary_address}
                        </ModalTextTypography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </StyledBox>
          </Modal>
        )}
      </Container>
    </ShareholderWrapper>
  );
}

export default ShareholderChangeRequest;
