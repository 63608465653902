import { Box, Grid, Stack } from '@mui/material';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from '../../components/appBar/Navbar';
import Footer from '../../components/officerFooter/Footer';
import ShareholderSidebar from '../../components/sidebar/Sidebar';

function ShareholderWrapper({
  sidebar = true,
  title = 'Shareholder',
  children,
}) {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
        </Helmet>
        {/* Navbar */}
        <Box>
          <Navbar />
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{
              minHeight: '80Vh',
              padding:{
                lg: !sidebar ? 0 : '0 2em',
                md: !sidebar ? 0 : '0 2em',
                sm: 0,
                xs: 0
              }
              // padding: !sidebar ? 0 : '0 2em',
            }}
          >
            {sidebar && (
              <Box
                flex={1}
                sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
              >
                <ShareholderSidebar />
              </Box>
            )}
            <Box flex={4}>{children}</Box>
          </Stack>
          <Stack direction="column">
            <Grid item xs={12}>
              <Footer />
            </Grid>
          </Stack>
        </Box>
      </HelmetProvider>
    </React.Fragment>
  );
}

export default ShareholderWrapper;
