import { createSlice } from "@reduxjs/toolkit";

const dividendsSlice = createSlice({
  name: "dividendsStore",
  initialState: {
    dividends: [],
    dividend: {},
    status: "idle",
    excelFileError: null,
    excelFile: null,
    excelData: null,
    fileName: "",
    fileType: "",
    schedules: [],
    schedule: {},
    scheduleStatus: "idle",
    error: "",
    accountingActive: false
  },
  reducers: {
    setAccountingActive: (state, action) => {
      state.accountingActive = action.payload;
    },
    setCurrentDividend: (state, action) => {
      state.dividend = action.payload;
    },
    setCurrentSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setExcelFile: (state, action) => {
      state.excelFile = action.payload;
    },
    setExcelFileError: (state, action) => {
      state.excelFileError = action.payload;
    },
    setExcelData: (state, action) => {
      state.excelData = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setFileType: (state, action) => {
      state.fileType = action.payload;
    },
    setSchedules: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (!state.schedules.some((e) => e.div_no === element.div_no)) {
          state.schedules.push(element);
        }
      }
    },
    setDividends: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (
          !state.dividends.some(
            (e) => e.payment_ref_number === element.payment_ref_number
          )
        ) {
          state.dividends.push(element);
        }
      }
    },
  },
});

export const {
  setAccountingActive,
  setCurrentDividend,
  setExcelFile,
  setExcelFileError,
  setExcelData,
  setFileName,
  setCurrentSchedule,
  setFileType,
  setSchedules,
  setDividends
} = dividendsSlice.actions;

export default dividendsSlice.reducer;
