import { createSlice } from "@reduxjs/toolkit";

const fileSlice = createSlice({
  name: "fileStore",
  initialState: {
    violations: []
  },
  reducers: {
    setViolations: (state, action) => {
      console.log('setViolations: ', action.payload);
      state.violations = action.payload;
    }
  },
});

export const {
    setViolations,
} = fileSlice.actions;

export default fileSlice.reducer;
