// import { TabPanel } from "@mui/lab";
import { Check, Clear, Delete, Edit, Report } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import administratorBanner from "../assets/images/admin.png";
import { API } from "../config/API";
import {
  addFGToAdmin,
  addModuleToAdmin,
  checkAuthorities,
  getAllFunctionalGroups,
  getAllModules,
  getFunctionalGroups,
  getModuleAuthorities
} from "../service/securityService";
import { getAdministratorById, resetAdministrator } from "../service/userService";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import { setCurrentFg, setModuleNames } from "../store/securitySlice";
import {
  addFunctinalGroups,
  removeFunctionalGroupAdmin,
  setAdminFunctionalGroups,
  setModuleStatus
} from "../store/userSlice";
import AlertModal from "./alertModal";
import { BootstrapCheckbox, ShareholderDialog } from "./forms/FormComponents";
import jwtDecode from "jwt-decode";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

let currentModules = [];
const AdministratorDetailsCard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useParams();

  const [value, setValue] = useState(0);
  const [openFgModal, setOpenFgModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [newModule, setNewModule] = useState("");
  const [openDelFGDialog, setOpenDelFGDialog] = useState(false);
  const [openDelModuleDialog, setOpenDelModuleDialog] = useState(false);
  const [openEditModuleDialog, setOpenEditModuleDialog] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false);  
  const [currentModule, setCurrentModule] = useState({});
  const [create_allowed, setCreate_allowed] = useState(false);
  const [read_allowed, setRead_allowed] = useState(false);
  const [update_allowed, setUpdate_allowed] = useState(false);
  const [delete_allowed, setDelete_allowed] = useState(false);
  const [administrator, setAdministrator] = useState();
  const [allModules, setAllModules] = useState([]);
  const [allFunctionalGroups, setAllFunctionalGroups] = useState([]);
  const [newFunctionalGroups, setNewFunctionalGroups] = useState([]);
  const user = useSelector((state) => state.user);
  const { ...security } = useSelector((state) => state.security);
  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    setLoading(true);
    checkAuthorities({
      can_create: "ADMINISTRATOR_MODULE:CAN_CREATE",
      can_read: "ADMINISTRATOR_MODULE:CAN_READ",
      can_update: "ADMINISTRATOR_MODULE:CAN_UPDATE",
      can_delete: "ADMINISTRATOR_MODULE:CAN_DELETE",
      can_export: "ADMINISTRATOR_MODULE:CAN_EXPORT",
      can_approve: "ADMINISTRATOR_MODULE:CAN_APPROVE",
    });
    getAdministratorById(locale.administrator_id).then((response) => {
      setAdministrator(response);
      setLoading(false);
      getIndividualAdminModules(response);
    });
    getAllModules({size: 50, pageIndex: 0}).then(response => {
      setAllModules(response.data.data);
    });
    getAllFunctionalGroups({size: 20, pageIndex: 0}).then(response => {
      setAllFunctionalGroups(response.data.data);
    })
  }, [locale.administrator_id]);


  const getIndividualAdminModules = (admin) => {
    currentModules = admin.module_authorities

    setModuleStatus();

    return currentModules;
  };

  const modules = security.myModules;
  const myAuthorities = [
    { name: "View", auth: "read_allowed" },
    { name: "Create", auth: "create_allowed" },
    { name: "Update", auth: "update_allowed" },
    { name: "Delete", auth: "delete_allowed" },
  ];
  let modAuth = {
    module_name: newModule,
    authorities: {
      read_allowed: false,
      create_allowed: false,
      update_allowed: false,
      delete_allowed: false,
    },
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpenFgModal = () => {
    setOpenFgModal(true);
  };
  const handleOpenAuthModal = () => {
    dispatch(setModuleNames());
    setOpenAuthModal(true);
  };
  const handleClose = () => {
    setOpenFgModal(false);
    setOpenAuthModal(false);
    setOpenDelFGDialog(false);
    setOpenEditModuleDialog(false);
    setOpenDelModuleDialog(false);
    setOpenResetDialog(false)
  };
  const addFunctionalGroupsAdmin = () => {
    addFGToAdmin({id: locale.administrator_id, fgs: newFunctionalGroups}).then(
      response => {
        setOpenFgModal(false);
      }
    )

    let response;
    dispatch(openAlert());
  };
  const addModuleAdmin = () => {
    let response;
    addModuleToAdmin({id: administrator.administrator_id, modAuth }).then(
      response => {
        setOpenAuthModal(false)
      }
    )
    dispatch(openAlert());
  };

  const deleteFunctionalGroup = (fg) => {
    dispatch(setCurrentFg(fg.functional_group_name));
    setOpenDelFGDialog(true);
  };

  const deleteAdminModule = (authority) => {
    setCurrentModule(authority);
    setOpenDelModuleDialog(true);
  };

  const handleSelectedModule = (event) => {
    setNewModule(event.target.value);
  };

  const handleSelectedAuth = (event) => {
    if (event.target.value === "read_allowed") {
      modAuth.authorities.read_allowed = !modAuth.authorities.read_allowed;
    }
    if (event.target.value === "create_allowed") {
      modAuth.authorities.create_allowed = !modAuth.authorities.create_allowed;
    }
    if (event.target.value === "update_allowed") {
      modAuth.authorities.update_allowed = !modAuth.authorities.update_allowed;
    }
    if (event.target.value === "delete_allowed") {
      modAuth.authorities.delete_allowed = !modAuth.authorities.delete_allowed;
    }
  };

  const updateReadAllowed = () => {
    setRead_allowed(!read_allowed);
  };

  const updateCreateAllowed = () => {
    setCreate_allowed(!create_allowed);
  };

  const updateUpdateAllowed = () => {
    setUpdate_allowed(!update_allowed);
  };

  const updateDeleteAllowed = () => {
    setDelete_allowed(!delete_allowed);
  };

  const editAuthorities = (authority) => {
    setCurrentModule(authority);
    setCreate_allowed(authority.create_allowed);
    setRead_allowed(authority.read_allowed);
    setUpdate_allowed(authority.update_allowed);
    setDelete_allowed(authority.delete_allowed);
    setOpenEditModuleDialog(true);
  };

  const updateIndividualSecuritySettings = async () => {
    let new_authorities = {
      create_allowed,
      read_allowed,
      update_allowed,
      delete_allowed,
    };
    let response;
    try {
      response = await API.put(`authorities?entity_id=${administrator.administrator_id}&module_code=${currentModule.module_code}`, new_authorities);

    } catch (error) {}
  };

  const confirmDeleteFunctionalGroup = async () => {
    let response;
    try {
      response = await API.put(`security/rmv-fg-admin/${administrator.administrator_id}`, security.currentFunctionalGroup);
      dispatch(setAlertTitle("Success"));
      dispatch(setAlertMessage(response.data.operation_description));
      dispatch(removeFunctionalGroupAdmin(security.currentFunctionalGroup[0]));
      setOpenDelFGDialog(false);
      navigate(`/admin_profile/${administrator.administrator_id}`, {replace: true});
    } catch (error) {
      dispatch(setAlertTitle("ERROR!"));
      dispatch(setAlertMessage(response.data.operation_description));
      setOpenDelFGDialog(false);
      navigate(`/admin_profile/${administrator.administrator_id}`, {replace: true});
    }
    dispatch(openAlert());
  };
  const resetAdmin = () => {
    resetAdministrator({
      'administrator_id': administrator.administrator_id,
      "account_non_expired": true,
      "account_non_locked": true,
      "credentials_non_expired": true,
      "enabled": true,
      'password': ' ',
      'confirmPassword': ' ',}).then(response => {
      if (response.data.operation_code === 0) {
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response.data.operation_description));        
      } else {
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage(response.data.operation_description));
      }
      setOpenResetDialog(false);
      dispatch(openAlert());
    })
  }

  const confirmRemoveModule = async () => {
    let moduleToDelete = [];
    moduleToDelete.push(currentModule.module_name);
    let response;
    try {
      response = await API.put(`security/rmv-mod-admin/${administrator.administrator_id}`, moduleToDelete);
      if (response.status === 202) {
        currentModules = response.data.data.module_authorities.filter(mod => mod.authorisation_type === 'INDIVIDUAL');
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response.data.operation_description));
        
      }
      setOpenDelModuleDialog(false);
      navigate(`/admin_profile/${administrator.administrator_id}`, {replace: true});
    } catch (error) {
      dispatch(setAlertTitle("ERROR!"));
      dispatch(setAlertMessage(response.data.operation_description));
      setOpenDelModuleDialog(false);
      navigate(`/admin_profile/${administrator.administrator_id}`, {replace: true});
    }
    dispatch(openAlert());
  };
  const addFunctionalGroupAdmin = (props) => {
    if (!newFunctionalGroups.includes(props)) {
      setNewFunctionalGroups([props]);      
    }
  }

  return (
    <>
      <Card>
        {loading ? (<>
          <Skeleton variant="rectangle" height={"10vh"} width={"80vw"}/>
          <Skeleton variant="circular" height={40} width={40} />
          <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={"30vw"} />
          <Skeleton variant="rectangle" height={"60vh"} width={"80vw"}/>
          </>):(<>
              <CardMedia
            component="img"
            height="180"
            image={administratorBanner}
            alt="Shareholder Banner"
          ></CardMedia>
          <CardContent>
          <Grid container>
            <Grid item xs={4} md={4}>
              <Avatar></Avatar>
              <Typography>{administrator?.username}</Typography>
            </Grid>
            <Grid item xs={4} md={6}>
              
            </Grid>
            <Grid item xs={4} md={2}>
              {alert.open === true ? (
                <AlertModal
                  sx={{ margin: "20px", width: "50%", align: "right" }}
                ></AlertModal>
              ) : (
                <>
                {administrator?.administrator_id === (jwtDecode(sessionStorage.getItem("token"))).id ? (
                  <></>
                ) : (
                  <Button
                        variant="contained"
                        onClick={() => { setOpenResetDialog(true) }}
                      >
                        Reset
                  </Button>
                )}
                </>
              )}
            </Grid>
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Personal" {...a11yProps(0)} />
              <Tab label="Security" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12} md={12} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Name:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {`${administrator?.first_name} ${administrator?.last_name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Email:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {administrator?.primary_email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Phone:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {administrator?.primary_phone_number}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Company Id:</strong>
                        </Typography>
                        <Typography color="GrayText" fontSize="small">
                          {administrator?.company_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {administrator?.administrator_id === (jwtDecode(sessionStorage.getItem("token"))).id ? (
                      <></>
                    ):(
                      <Button
                      variant="contained"
                      onClick={() =>
                        navigate(`/administrator-edit-profile/${locale.administrator_id}`, {
                          replace: true,
                        })
                      }                      
                      disabled={sessionStorage.getItem("can_update") === "false"}
                    >
                      Edit
                    </Button>)}
                    
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container sx={{ paddingBottom: 4 }}>
              <Grid item xs={8} md={10}>
                <Typography>Functional Groups</Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  sx={{ backgroundColor: "#052A6C" }}
                  variant="contained"
                  onClick={() => {
                    dispatch(setAdminFunctionalGroups());
                    handleOpenFgModal();
                  }}
                  disabled={sessionStorage.getItem("can_update") === "false"}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={8} md={10}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          <strong>Name</strong>
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {administrator?.functional_groups?.map((functional_group) => (
                      <TableRow key={functional_group.functional_group_code}>
                        <TableCell>
                          {functional_group.functional_group_name}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Delete functional group">
                            <IconButton
                              disabled={sessionStorage.getItem("can_delete") === "false"}
                              style={{color: sessionStorage.getItem("can_delete") === 'false' ? "gray" : "red",}}
                              onClick={() => {
                                deleteFunctionalGroup(functional_group);
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={8} md={10}>
                <Typography>
                  <strong>Individual Security Settings</strong>
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button
                  sx={{ backgroundColor: "#052A6C" }}
                  variant="contained"
                  onClick={handleOpenAuthModal}
                  disabled={sessionStorage.getItem("can_update") === "false"}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={8} md={10}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>
                          <strong>Module</strong>
                        </Typography>
                      </TableCell>
                      {myAuthorities.map((authority) => (
                        <TableCell key={authority.name}>
                          <Typography>
                            <strong>{authority.name}</strong>
                          </Typography>
                        </TableCell>
                      ))}
                      <TableCell colSpan={2}>Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {administrator?.module_authorities.length >= 1 ? (
                      (administrator?.module_authorities.filter(moduleAuth => moduleAuth.authorisation_type !== "GROUP")).map((authority) => (
                        <TableRow key={authority.module_code}>
                          <TableCell>{authority.module_name}</TableCell>

                          <TableCell>
                            {authority.can_read ? (
                              <Check fontSize="small" />
                            ) : (
                              <Clear fontSize="small" />
                            )}
                          </TableCell>
                          <TableCell>
                            {authority.can_create ? (
                              <Check fontSize="small" />
                            ) : (
                              <Clear fontSize="small" />
                            )}
                          </TableCell>
                          <TableCell>
                            {authority.can_update ? (
                              <Check fontSize="small" />
                            ) : (
                              <Clear fontSize="small" />
                            )}
                          </TableCell>
                          <TableCell>
                            {authority.can_delete ? (
                              <Check fontSize="small" />
                            ) : (
                              <Clear fontSize="small" />
                            )}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit Module Authorities">
                              <IconButton
                                onClick={() => {
                                  editAuthorities(authority);
                                }}
                              >
                                <Edit color="warning" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Delete Module">
                              <IconButton
                                onClick={() => {
                                  deleteAdminModule(authority);
                                }}
                              >
                                <Delete color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))

                      ) : (
                       <TableRow>
                         <TableCell colSpan={7}>
                           <Typography>
                             No individual security settings
                           </Typography>
                         </TableCell>
                       </TableRow>
                     )} 
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </TabPanel>
        </CardContent>
          </>)}
        
        
      </Card>

      <Dialog
        open={openFgModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <Typography>
            <strong>Add Functional Groups</strong>
          </Typography>
          <DialogContentText id="alert-dialog-description">
            <FormGroup sx={{ padding: "5% 0" }}>
              {allFunctionalGroups.map((functionalGroup) => {
                return (
                  <FormControlLabel
                    key={functionalGroup.functional_group_name}
                    control={
                      <BootstrapCheckbox
                        checked={user.userFunctionalGroups.includes(functionalGroup.functional_group_name)}
                        disabled={user.userFunctionalGroups.includes( functionalGroup.functional_group_name )}
                        value={functionalGroup.functional_group_name}
                        onChange={(event) => {
                          dispatch(addFunctinalGroups(event.target.value));
                          addFunctionalGroupAdmin(event.target.value);
                        }}
                      />
                    }
                    label={functionalGroup.functional_group_name}
                  />
                );
              })}
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
            onClick={() => {
              addFunctionalGroupsAdmin();
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAuthModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
          },
        }}
      >
        <DialogContent>
          <Typography>
            <strong>Add Individual Security Settings</strong>
          </Typography>
          <DialogContentText id="alert-dialog-description">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 160 }}>
                    <strong>Module</strong>
                  </TableCell>
                  {myAuthorities.map((authority) => (
                    <TableCell key={authority.name}>{authority.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableCell style={{ width: 160, padding: 0 }}>
                  <FormControl>
                    <InputLabel>Select Module</InputLabel>
                    <Select
                      label="Select"
                      value={newModule}
                      onChange={handleSelectedModule}
                      sx={{
                        width: 160,
                        "&  .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    >
                      {allModules.map((mymodule) => (
                        <MenuItem value={mymodule.module_name}>{mymodule.module_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                {myAuthorities.map((authority) => (
                  <TableCell key={authority.name}>
                    <Checkbox
                      value={authority.auth}
                      onChange={handleSelectedAuth}
                    />
                  </TableCell>
                ))}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
            onClick={(event) => {
              addModuleAdmin(event.target.value);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <ShareholderDialog
        open={openDelFGDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" />
            Are you sure you'd like to delete this Functional Group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "#B20808" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={confirmDeleteFunctionalGroup}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
          >
            Yes
          </Button>
        </DialogActions>
      </ShareholderDialog>

      <ShareholderDialog
        open={openDelModuleDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" />
            Are you sure you'd like to remove this Module from this
            administrator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "#B20808" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={confirmRemoveModule}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
          >
            Yes
          </Button>
        </DialogActions>
      </ShareholderDialog>

      <ShareholderDialog
        open={openEditModuleDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    <strong>Module</strong>
                  </Typography>
                </TableCell>
                {myAuthorities.map((authority) => (
                  <TableCell key={authority.name}>
                    <Typography>
                      <strong>{authority.name}</strong>
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{currentModule.module_name}</TableCell>

                <TableCell>
                  <Checkbox
                    checked={read_allowed}
                    value={read_allowed}
                    onChange={updateReadAllowed}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={create_allowed}
                    value={create_allowed}
                    onChange={updateCreateAllowed}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={update_allowed}
                    value={update_allowed}
                    onChange={updateUpdateAllowed}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={delete_allowed}
                    value={delete_allowed}
                    onChange={updateDeleteAllowed}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={updateIndividualSecuritySettings}
          >
            Update
          </Button>
        </DialogActions>
      </ShareholderDialog>

      <ShareholderDialog
        open={openResetDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" />
            Are you sure you'd like to reset this
            administrator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "#B20808" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => resetAdmin()}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
          >
            Yes
          </Button>
        </DialogActions>
      </ShareholderDialog>
    </>
  );
};
export default AdministratorDetailsCard;
