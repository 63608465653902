import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import ActivityTracker from "../../components/ActivityTracker";
import ShareholderDetailsCard from "../../components/shareholderDetailsCard";
import ShareholderDividendsTable from "../../components/ShareholderDividendsTable";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

function ShareholderProfilePage() {
    return (
      <MiniDrawer>
        <Grid container>
             <Grid item xs={12} md={8}>
               <ShareholderDetailsCard />
             </Grid>
             <Grid item xs={12} md={4} sx={{ paddingLeft: 2 }}>
               <ActivityTracker />
             </Grid>
           </Grid>
           <ShareholderDividendsTable />
      </MiniDrawer>
    )
}

export default ShareholderProfilePage;
