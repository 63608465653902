import { createSlice } from "@reduxjs/toolkit";

const accountOfficerSlice = createSlice({
  name: "accountOfficerStore",
  initialState: {
    backendPagination: {
      page_Index:0,
      total_pages:0,
      records_displayed:0,
      total_elements:0,
    },
    userFunctionalGroups: [],
    userModuleAuthorities: [],
    accountOfficers: [],
    error: "",
    is_authenticated: false,
  },
  reducers: {
    setAccountOfficers: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (
          !state.accountOfficers.some(
            (e) => e.officer_code === element.officer_code
          )
        ) {
          state.accountOfficers.push(element);
        }
      }
    },
    setAccountOfficerBackendPagination: (state, actions) => {
      state.backendPagination = actions.payload
    },
  },
});

export const {
    setAccountOfficerBackendPagination,
  resetDynamicUser,
  replaceOutdatedAdmin,
  // replaceOutdatedTicket,
  replaceOutdatedAccountOfficer,
  appendAccountOfficer,
  // addTicket,
  resetDynamicStatus,
  updateAdminList,
  callAccountOfficers,
  setPage,
  setTotalPages,
  setTotalDataElements,
  setShareholderAccountOfficers,
  addFName,
  addLName,
  addPrimaryEmail,
  addSecondaryEmail,
  addPrimaryPhone,
  addSecondaryPhone,
  addPrimaryAddress,
  addSecondaryAddress,
  addAUsername,
  addOfficerCode,
  addFirstName,
  addShareholderPayMode,
  addShareholderCode,
  updateFirstName,
  addLastName,
  updateLastName,
  addEmail,
  updateEmail,
  addCompanyID,
  setDynamicUser,
  setShareholderQrCode,
  setAuthenticationLogs,
  setAdminFunctionalGroups,
  setShareholderHistory,
  addFunctinalGroups,
  addModuleAuthorities,
  addPhone,
  updatePhone,
  addUsername,
  updateUsername,
  setModuleStatus,
  // setCurrentTicket,
  setCurrentCountry,
  removeAdministrator,
  removeFunctionalGroupAdmin,
  updateAdministratorDetails,
  // updateTicketsDetails,
  setShareholders,
  setAdministrators,
  setAccountOfficerStatus,
  setAccountOfficers,
  removeAccountOfficer,
  // setAuthenticated,
  // setTickets,
  setExcelFile,
  setExcelFileError,
  setExcelData,
  setFileName,
  setFileType,
  removeShareholder,
  addShareholderName,
  addShareholderTaxRate,
  addShareholderOtherName,
  addShareholderComNumber,
  addShareholderScd,
  addShareholderPrimaryPhone,
  addShareholderPrimaryEmail,
  addShareholderSecondaryPhone,
  addShareholderSecondaryEmail,
  addShareholderPaymentMode,
  addShareholderPaymentAccount,
  addShareholderPaymentAccountName,
  addShareholderPaymentBranchName,
  addShareholderPaymentBankName,
  addShareholderPaymentBranchAddress,
  addShareholderTin,
  addShareholderComment,
  addShareholderCategory,
  addBrokerCode,
  addShares,
  searchShareholders,
  setAdministratorBackendPagination
} = accountOfficerSlice.actions;

export default accountOfficerSlice.reducer;
