import { OfficerApi } from '../../config/API';

const notificationService = {
  async findAllnotifications({ page_number = 0, shareholder_code }) {
    return await OfficerApi.get(
      `notifications/shareholder/${shareholder_code}?read=false&size=10&page_index=${page_number}&direction=asc&field_name=notif_id`
    );
  },
  async findANotification({ notificationId }) {
    return await OfficerApi.get(`/notifications/${notificationId}`);
  },
  async updateNotifications({ notificationId }) {
    return await OfficerApi.put(`/notifications/${notificationId}`, {
      read: true,
    });
  },
};

export default notificationService;
