import { InfoApi, unprotectedApi } from '../config/API';

const monitoringService = {
  async fetchApplicationVersion() {
    return await InfoApi.get(`/actuator/info`);
  },
};
export const getAppVersion = async(props) => {
  return await InfoApi.get(`/actuator/info`)
}

export default monitoringService;
