import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Grid, Tab, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import shareholder1 from "../../assets/images/shareholder1.png";
import {API} from "../../config/API";
import { getPaymentModes, getTaxRates } from "../../service/userService";
import NavigationContext from "../../store/NavigationContext";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import {
  setCurrentCountry,
} from "../../store/userSlice";
import { replaceOutdatedTicket, setCurrentTicket } from "../../store/ticketSlice";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

const AdministratorEditTicketDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const [selectedTab, setSelectedTab] = useState("1");
  const [taxRates, setTaxRates] = useState([]);
  const [options, setOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  useEffect(() => {
    if (user.taxRateStatus === "idle") {
      dispatch(getTaxRates());
    }
  });
  useEffect(() => {
    if (user.paymentModesStatus === "idle") {
      dispatch(getPaymentModes());
    }
  }, []);

  const user = useSelector((state) => state.user);
  const countrytaxRates = user.country.tax_rates;
  const shSettings = useSelector((state) => state.settings);
  let content = user.ticket.content;
  const country = shSettings.country[0];

  useEffect(() => {
    const paymentModes = JSON.parse(sessionStorage.getItem("payModes"));
    setOptions(paymentModes);
  }, []);

  useEffect(() => {
    const allCountries = JSON.parse(sessionStorage.getItem("countries"));
    setCountries(allCountries);
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCountryByISOCode = async (isoCode) => {
    let response;
    try {
      response = await API.get(`app-settings/countries/${isoCode}`);
      if (response.status === 200) {
        let country = response.data.data;
        dispatch(setCurrentCountry(country));
      }
    } catch (error) {
    }
  };

  const handleSelectChange = (isoCode) => {
    getCountryByISOCode(isoCode);
    handleShow();
  };

  const handleSelectChangeTaxRate = (e) => {
    setTaxRateCode(e.target.value);
  };

  const [primary_phone_number, setPhoneNumber] = useState(
    content.primary_phone_number
  );
  const [tax_rate_code, setTaxRateCode] = useState(content.tax_rate_code);
  const [primary_address, setAddress] = useState(content.primary_address);
  const [payment_account_name, setPaymentAccountName] = useState(
    content.payment_account_name
  );
  const [payment_account_number, setPaymentAccountNumber] = useState(
    content.payment_account_number
  );
  const [payment_mode, setPaymentMode] = useState(content.payment_mode);

  const updateShareholderTicket = async (e) => {
    e.preventDefault();
    let ticketId = user.ticket.ticket_number;
    let ticketContent = content;
    let data = {
      primary_phone_number,
      tax_rate_code,
      primary_address,
      payment_account_name,
      payment_account_number,
      payment_mode,
    };

    let response;
    try {
      response = await API.put(`tickets/${ticketId}`, data);
      if (response.status === 202) {
        let updatedticketContent = response.data.data;
        dispatch(replaceOutdatedTicket(updatedticketContent));
        dispatch(setAlertMessage(response.data.operation_description));
        dispatch(setAlertTitle("Success"));
        dispatch(openAlert());
        dispatch(setCurrentTicket(updatedticketContent));
        navigate("/administrator-tickets", {
          replace: true,
        });
      }
    } catch (error) {
      dispatch(setAlertMessage(error.response.data.operation_description));
      dispatch(setAlertTitle("error"));
      dispatch(openAlert());
      navigate("/administrator-tickets", {
        replace: true,
      });
    }
  };

  const ticketDetailsStyles = {
    marginBottom: "31px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#000000",
  };

  const ticketDetailsHeaderStyles = {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    color: "#000000",
  };

  const tabLabel = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#052A6C",
  };

  const submitButton = {
    width: "90px",
    height: "40px",
    backgroundColor: "#052A6C",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#FFFFFF",
    marginLeft: "390px",
    marginTop: "15px",
  };
  const submitButtonTaxRates = {
    width: "90px",
    height: "40px",
    backgroundColor: "#052A6C",
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#FFFFFF",
    marginLeft: "377px",
    marginTop: "15px",
  };
  return (
    <MiniDrawer>
      <Card>
            <CardMedia
              sx={{ height: 140 }}
              image={shareholder1}
              title="green iguana"
            />
            <CardContent>
              <TabContext value={selectedTab}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Personal" value="1" sx={tabLabel} />
                  <Tab label="Payment Account" value="2" disabled />
                  <Tab label="Shares" value="3" disabled />
                  <Tab label="A/c officers" value="4" disabled />
                </TabList>
                <TabPanel value="1">
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: "40px",
                      marginTop: "20px",
                      borderBottom: "1px solid #D2D1D1",
                    }}
                  >
                    <Grid lg={6}>
                      <Typography sx={ticketDetailsHeaderStyles}>
                        Ticket Details
                      </Typography>
                    </Grid>
                    <Grid lg={6}></Grid>
                  </Grid>

                  <Row>
                    <Form onSubmit={updateShareholderTicket}>
                      <Row>
                        <Col lg={6}>
                          <p style={ticketDetailsStyles}>Phone</p>
                          <p style={ticketDetailsStyles}>Country</p>
                          <p style={ticketDetailsStyles}>Address</p>
                          <p style={ticketDetailsStyles}>
                            Payment Account Name
                          </p>
                          <p style={ticketDetailsStyles}>
                            Payment Account Number
                          </p>
                          <p style={ticketDetailsStyles}>Payment Mode</p>
                        </Col>
                        <Col lg={6}>
                          {" "}
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {content.primary_phone_number ? (
                              <Form.Control
                                type="text"
                                // value={content.primary_phone_number}
                                value={primary_phone_number}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                              />
                            ) : (
                              <Form.Control type="text" value="Null" readOnly />
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Select
                              aria-label="Default select example"
                              onChange={(e) =>
                                handleSelectChange(e.target.value)
                              }
                            >
                              {countries.map((country) => (
                                <option
                                  key={country.iso_code}
                                  value={country.iso_code}
                                >
                                  {country.country_name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {content.primary_address ? (
                              <Form.Control
                                type="text"
                                value={primary_address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            ) : (
                              <Form.Control type="text" value="Null" readOnly />
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {content.payment_account_name ? (
                              <Form.Control
                                type="text"
                                value={payment_account_name}
                                onChange={(e) =>
                                  setPaymentAccountName(e.target.value)
                                }
                              />
                            ) : (
                              <Form.Control type="text" value="Null" readOnly />
                            )}
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            {content.payment_account_number ? (
                              <Form.Control
                                type="text"
                                value={payment_account_number}
                                onChange={(e) =>
                                  setPaymentAccountNumber(e.target.value)
                                }
                              />
                            ) : (
                              <Form.Control type="text" value="Null" readOnly />
                            )}
                          </Form.Group>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setPaymentMode(e.target.value)}
                          >
                            {options.map((option, index) => (
                              <option
                                key={index}
                                value={option.payment_mode_code}
                              >
                                {option.payment_mode_name}
                              </option>
                            ))}
                          </Form.Select>
                          <Button
                            variant="primary"
                            type="submit"
                            style={submitButton}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header>
                      <Modal.Title>Please select tax rates</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        {countrytaxRates ? (
                          <Form.Select
                            aria-label="Default select example"
                            onChange={handleSelectChangeTaxRate}
                          >
                            {countrytaxRates.map(({ tax_rate_code }) => (
                              <option key={tax_rate_code} value={tax_rate_code}>
                                {tax_rate_code}
                              </option>
                            ))}
                          </Form.Select>
                        ) : (
                          <p>Loading tax rates...</p>
                        )}
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        type="submit"
                        style={submitButtonTaxRates}
                        onClick={handleClose}
                      >
                        Submit
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </TabPanel>
                <TabPanel value="2"> </TabPanel>
                <TabPanel value="3"> </TabPanel>
                <TabPanel value="4"> </TabPanel>
              </TabContext>
            </CardContent>
            <CardActions></CardActions>
          </Card>
    </MiniDrawer>
  )
};

export default AdministratorEditTicketDetails;
