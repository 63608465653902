import { Box, Grid, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertModal from "../../components/alertModal";
import { UpdateButton } from "../../components/styled/buttons";
import { confirmShareholderSecretKey } from "../../service/userService";
import { openAlert, setAlertMessage, setAlertTitle } from "../../store/alertSlice";
import ShareholderWrapper from "./ShareholderWrapper";

const ShareholderGenerateSecretKey = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const qrCodeData = user.userQrCode;
  const alert = useSelector((state) => state.alert);
  const [otp, setOtp] = useState("");

  const handleChange = (newValue) => {
    setOtp(newValue);
  };


  //confirm the otp
  const handleComplete = (otp) => {
    let data = {
      code: otp,
      auth_factor_method: "AUTHENTICATOR_APP",
    };
    confirmShareholderSecretKey(data).then((response) => {
      if (response.operation_code === 1) {        
        dispatch(setAlertTitle("Error"));
        dispatch(setAlertMessage(response.message));   
        dispatch(openAlert());       
      }else {
        dispatch(
          setAlertMessage(
            "Successfully enabled 2FA. You can login with your new credentials"
          )
        );
        // dispatch(setAlertMessage(error.response.data.operation_description));
        dispatch(setAlertTitle("Success"));
        dispatch(openAlert());
        navigate("/change-password", {
          replace: true,
        });
      }
      
    });
  };

  const cancel = () => {
    navigate("/change-password", {
      replace: true,
    });
  };

  const otpInputStyle = {
    "& .MuiInputBase-input": {
      border: "1px solid #000",
      borderRadius: 1,
    },
  };

  return (
    <ShareholderWrapper>
      <Box
        sx={{
          bgcolor: "#ffffff",
          mt: 4,
          borderRadius: 2,
          pl: 4,
          pr: 4,
        }}
      >
        {alert.open === true ? (
            <AlertModal
              sx={{
                margin: "20px",
                width: "50%",
                align: "right",
              }}
            ></AlertModal>
          ) : (
            <></>
          )}
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
            borderBottom: "1px solid #00000040",
          }}
        >
          <Grid item xs={4} lg={4}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "24px",
                color: "#000000",
              }}
            >
              Two-factor authentication
            </Typography>
          </Grid>
          <Grid item xs={1} lg={4}></Grid>
          <Grid item xs={7} lg={4}>
            <UpdateButton onClick={cancel}>Cancel</UpdateButton>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
          }}
        >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#444A4A",
              }}
            >
              Two-factor authentication adds an additional layer of security to
              your account by requiring more than just a password to sign in.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
          }}
        >
          <Grid item xs={12} lg={4}></Grid>
          <Grid item xs={12} lg={4}>
            <img src={qrCodeData} />
          </Grid>
          <Grid item xs={12} lg={4}></Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
          }}
        >
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#444A4A",
              }}
            >
              Please input the one time password on your authenticator app to
              confirm
            </Typography>
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
            paddingBottom: "20px",
          }}
        >
          <Grid item xs={12} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            <MuiOtpInput
              length={6}
              autoFocus
              value={otp}
              onChange={handleChange}
              onComplete={handleComplete}
              sx={otpInputStyle}
            />
          </Grid>
          <Grid item xs={12} lg={3}></Grid>
        </Grid>
      </Box>
    </ShareholderWrapper>
  );
};

export default ShareholderGenerateSecretKey;
