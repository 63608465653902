import { Skeleton } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ userType: 'pending', is_authenticated: false });

  useEffect(() => {
    if ("is_authenticated" in sessionStorage && "userType" in sessionStorage) {
      const userType = sessionStorage.getItem("userType");
      const is_authenticated = !!sessionStorage.getItem("is_authenticated");
      setUser({
        userType,
        is_authenticated,
      });
      return;
    } else {
      return setUser(null);
    }
  }, []);


  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const RequiredAuth = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const navigateToScreen = () => {
    const currentLocation = sessionStorage.getItem("current_location");
    currentLocation !== null ? currentLocation : navigate(-1);
  };


  if (user?.userType === 'pending') {
    return(
      <Skeleton variant="rectangular" width={210} height={118} />
    )   
  } else if (!user) {
      return (
        <Navigate
          to={{
            pathname: "/error",
            state: {
              from: location,
            },
          }}
        />
      );
  }

  return <Outlet />;
};

export const AccountOfficerAuth = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const navigateToScreen = () => {
    const currentLocation = sessionStorage.getItem("current_location");
    newRoute = currentLocation !== null ? currentLocation : navigate(-1);
  };

  if (user && user?.userType === "ADMINISTRATOR" && user?.is_authenticated) {
    return (
      <Navigate
        to={{
          pathname: '/error',
          state: {
            from: location,
          },
        }}
      />
      // <Navigate
      //   to={{
      //     pathname: navigateToScreen(),
      //     state: {
      //       from: location,
      //     },
      //   }}
      // />
    );
  }

  return <Outlet />;
};

export const AdminAuth = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const navigateToScreen = () => {
    const currentLocation = sessionStorage.getItem("current_location");
    currentLocation !== null ? currentLocation : navigate(-1);
  };

  if (user && user?.userType === "ACCOUNT_OFFICER" && user?.is_authenticated) {
    return (
      <Navigate
        to={{
          pathname: '/error',
          state: {
            from: location,
          },
        }}
      />
    );
  }

  return <Outlet />;
};
