import React from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { NavLink as Link } from 'react-router-dom';
import useStyles from './SidebarListItem.module';
function SidebarListItem({
  listText = 'List Item',
  to = '/',
  children,
  onClick = () => null,
}) {
  const classes = useStyles();  
  // if (to = "/dividends") {
  //   return (

  //   )
      
  // }
  return (
    <Link to={to} className={classes.links}>
      {({ isActive }) => (
        <ListItemButton
          className={
            !isActive ? classes.listItemButton : classes.listItemActiveButton
          }
          onClick={onClick}
        >
          <ListItemIcon className="list">{children}</ListItemIcon>
          <ListItemText className="list listText">
            <Typography variant="p" className={classes.listItemText}>
              {listText}
            </Typography>
          </ListItemText>
        </ListItemButton>
      )}
    </Link>
  );
}

export default SidebarListItem;
