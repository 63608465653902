import { OfficerApi, testApi } from '../../config/API';

const shareholderService = {
  async findShareholderDetails({ shareholderCode }) {
    return await OfficerApi.get(`/shareholders/${shareholderCode}`);
  },
  async updateShareholderDetails({ shareholderCode, data = {} }) {
    return await testApi.put(`/shareholders/${shareholderCode}`, data);
  },
  async fetchShareholderRevisions({ shareholderCode }) {
    return await OfficerApi.get(
      `/shareholders/revisions/${shareholderCode}?size=5&page_index=0&direction=desc&field_name=shareholder_code`
    );
  },
  async fetchShareholderTicketsByOfficer({
    ticket_number,
    shareholder_code,
    page_number = 0,
  }) {
    const data = { ticket_number };
    return await OfficerApi.post(
      `/tickets/filter/${shareholder_code}?size=10&page_index=${page_number}&direction=asc&field_name=ticket_number`,
      data
    );
  },
};

export default shareholderService;
