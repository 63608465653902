import { useContext } from 'react';
import NavigationContext from '../../store/NavigationContext';
import AccountOfficerDetailsCard from '../../components/AccountOfficerDetailsCard';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';


function AccountOfficerProfilePage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <AccountOfficerDetailsCard />      
    </MiniDrawer>
  )
}

export default AccountOfficerProfilePage;