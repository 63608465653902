import { RemoveRedEye } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Card, Dialog, Grid, IconButton, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { green } from "@mui/material/colors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentDividend } from "../../store/dividendsSlice";

const DividendsActions = ({ params }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleViewDividendProfile = (dividend) => {
    dispatch(setCurrentDividend(dividend));
    handleClickOpen();
  };

  const current_dividend = useSelector((state) => state.dividend);
  let payment_status = current_dividend.dividend.payment_status;
  let dividend_details = current_dividend.dividend;

  const dividendsCardStyles = {
    backgroundColor: "#F2F3F4",
    marginTop: "20px",
  };

  const dividendDetailsButton = {
    backgroundColor: "#052A6C",
    width: "100%",
    height: "50px",
    marginTop: "20px",
    borderRadius: "8px",
    fontWeight: "500px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#052A6C",
    },
    textTransform: "unset !important",
  };

  const dividendDetailsHeader = {
    fontWeight: "500",
    fontSize: "12px",
    color: " #000000",
    fontStyle: "normal",
  };

  const dividendDetailsHeader2 = {
    fontWeight: "500",
    fontSize: "12px",
    color: " #0A2240",
    fontStyle: "normal",
  };

  const dividendDetailsHeader3 = {
    fontWeight: "500",
    fontSize: "16px",
    color: "#5F6868",
    fontStyle: "normal",
  };

  const dividendDetailsText = {
    fontWeight: "400",
    fontSize: "16px",
    color: "#3F4041",
    fontStyle: "normal",
  };

  const dividendsDetailsGridItem = {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
  };

  return (
    <div>
      <Box>
        <Tooltip title="View dividend details">
          <IconButton
            disabled={sessionStorage.getItem("can_read") === "false"}
            onClick={() => handleViewDividendProfile(params.row)}
            style={{
              color:
                sessionStorage.getItem("can_read") === "true"
                  ? green[500]
                  : "gray",
            }}
          >
            <RemoveRedEye fontSize="small" color="success" />
          </IconButton>
        </Tooltip>

        <Dialog open={open} onClose={handleClose} scroll="body">
          <Box p={2}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "16px",
                color: "#000000",
                fontStyle: "normal",
              }}
            >
              {dividend_details.dividend_year}
            </Typography>

            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "32px",
                color: " #0033A1",
                fontStyle: "normal",
              }}
            >
              {dividend_details.actual_net_amount}{" "}
              {payment_status === "PENDING" ? (
                <Tooltip title="">
                  <Chip
                    label={payment_status}
                    fontSize="small"
                    sx={{
                      backgroundColor: "#FFE4BB",
                      color: "#01422A",
                      width: "100px",
                      height: "15.83px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  />
                </Tooltip>
              ) : payment_status === "PAID" ? (
                <Tooltip title="">
                  <Chip
                    label={payment_status}
                    fontSize="small"
                    sx={{
                      backgroundColor: "#ACE4CF",
                      color: "#01422A",
                      width: "100px",
                      height: "15.83px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="">
                  <Chip
                    label={payment_status}
                    fontSize="small"
                    sx={{
                      backgroundColor: "#FEA2A2",
                      color: "#01422A",
                      width: "100px",
                      height: "15.83px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  />
                </Tooltip>
              )}
            </Typography>
            <Card sx={dividendsCardStyles}>
              <CardContent>
                <Grid container sx={{ borderBottom: "1px solid #C4C4C4" }}>
                  <Grid item md={2} sx={dividendsDetailsGridItem}>
                    <Box>
                      <IconButton title="">
                        <AccountCircleIcon
                          sx={{ fontSize: 70, color: "#0033A1" }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: "500",
                          color: "#0C1F36",
                          fontStyle: "normal",
                        }}
                      >
                        {dividend_details.shareholder_name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: "300",
                          fontStyle: "normal",
                        }}
                      >
                        <span
                          style={{
                            color: "#FF9E0C",
                            fontSize: "12px",
                            fontWeight: "500",
                            fontStyle: "normal",
                          }}
                        >
                          {dividend_details.shares_held}{" "}
                        </span>
                        shares
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={1} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography
                        sx={{
                          color: "#444A4A",
                          fontSize: "12px",
                          fontWeight: "300",
                          fontStyle: "normal",
                        }}
                      >
                        <IconButton title="">
                          <CalendarMonthIcon
                            fontSize="small"
                            sx={{ color: "#0A2240" }}
                          />
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: "500",
                              fontStyle: "normal",
                            }}
                          >
                            Paid On
                          </Typography>
                        </IconButton>
                        {dividend_details.paid_on
                          ? dividend_details.paid_on
                          : "N/A"}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#444A4A",
                          fontSize: "12px",
                          fontWeight: "300",
                          fontStyle: "normal",
                        }}
                      >
                        <IconButton title="">
                          <CalendarMonthIcon
                            fontSize="small"
                            sx={{ color: "#0A2240" }}
                          />
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: "500",
                              fontStyle: "normal",
                            }}
                          >
                            Paid Via
                          </Typography>
                        </IconButton>
                        {dividend_details.payment_mode}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginTop: "20px" }}>
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography
                        sx={dividendDetailsHeader}
                        color="text.secondary"
                      >
                        Transaction ID
                      </Typography>
                      <Typography sx={dividendDetailsText}>
                        {dividend_details.external_ref_number
                          ? dividend_details.external_ref_number
                          : "N/A"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography
                        sx={dividendDetailsHeader}
                        color="text.secondary"
                      >
                        Payment Ref Number
                      </Typography>
                      <Typography sx={dividendDetailsText}>
                        {dividend_details.payment_ref_number}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginTop: "20px" }}>
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader}>
                        Account Number
                      </Typography>
                      <Typography
                        sx={dividendDetailsText}
                        color="text.secondary"
                      >
                        {dividend_details.payment_account_number}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography
                        sx={dividendDetailsHeader}
                        color="text.secondary"
                      >
                        Account Name
                      </Typography>
                      <Typography
                        sx={dividendDetailsText}
                        color="text.secondary"
                      >
                        {dividend_details.payment_account_name}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginTop: "20px" }}>
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader}>
                        Number of Attempts
                      </Typography>
                      <Typography sx={dividendDetailsText}>
                        {dividend_details.num_of_attempts_remaining}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader}>
                        Comment
                      </Typography>
                      <Typography sx={dividendDetailsText}>
                        {dividend_details.comment}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card sx={dividendsCardStyles}>
              <CardContent>
                <Grid container sx={{ marginTop: "20px" }}>
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader2}>
                        Tax Rate
                      </Typography>
                      <Typography
                        sx={{
                          color: "#0033A1",
                          fontSize: "28px",
                          fontWeight: "500",
                          fontStyle: "normal",
                        }}
                      >
                        {dividend_details.tax_rate}%
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader2}>
                        Gross Pay out ratio
                      </Typography>
                      <Typography
                        sx={{
                          color: "#33A920",
                          fontSize: "28px",
                          fontWeight: "500",
                          fontStyle: "normal",
                        }}
                      >
                        N/A
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ marginTop: "20px", borderBottom: "1px solid #C4C4C4" }}
                >
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader3}>
                        Gross Amount
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader3}>
                        {dividend_details.actual_gross_amount}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ marginTop: "20px", borderBottom: "1px solid #C4C4C4" }}
                >
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader3}>Tax</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader3}>
                        {dividend_details.actual_tax_amount}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  container
                  sx={{ marginTop: "20px", borderBottom: "1px solid #C4C4C4" }}
                >
                  <Grid item md={4} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader3}>
                        Net Amount
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={3} sx={dividendsDetailsGridItem}>
                    <Box></Box>
                  </Grid>
                  <Grid item md={5} sx={dividendsDetailsGridItem}>
                    <Box>
                      <Typography sx={dividendDetailsHeader3}>
                        {dividend_details.actual_net_amount}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Button
              variant="contained"
              sx={dividendDetailsButton}
              onClick={(event) => handleClose(event)}
            >
              Back
            </Button>
          </Box>
        </Dialog>
      </Box>
    </div>
  );
};

export default DividendsActions;
