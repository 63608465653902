import {
  Clear,
  Delete,
  Done,
  Edit,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  RemoveRedEye,
  Report
} from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { green, orange, red } from "@mui/material/colors";
import jwtDecode from "jwt-decode";
import { checkAuthorities } from "../../service/securityService";
import {
  deleteAdministratorService,
  filterAdministrators,
  getAllAdministrators,
} from "../../service/userService";
import {
  setAdministratorBackendPagination,
  setAdministrators,
} from "../../store/administratorSlice";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";
import {
  removeAdministrator,
  setDynamicUser,
  setPage,
} from "../../store/userSlice";
import AlertModal from "../alertModal";
import { ShareholderSelect } from "../forms/FormComponents";

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const adminState = useSelector((state) => state.administrator);
  const backendPagination = adminState.backendPagination;
  const backendPageIndex = adminState.page_Index;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    if (page >= Math.ceil(count / rowsPerPage) - 1) {
      getAllAdministrators({ size: 20, pageIndex: backendPageIndex + 1 }).then(
        (response) => {
        }
      );
    }
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={
          page >= Math.ceil(backendPagination.total_elements / rowsPerPage) - 1
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
};

const requiredResults = 20;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

function ListAdministrators() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [advanceSearching, setAdvanceSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const user = useSelector((state) => state.user);
  const adminState = useSelector((state) => state.administrator);
  const alert = useSelector((state) => state.alert);
  const admins = adminState.administrators;
  const backendPagination = adminState.backendPagination;

  const page = user.page_index;
  const headers = [
    "Admin Id",
    "First Name",
    "Last Name",
    "Email",
    "Phone Number",
    "Activated",
  ];
  const fields = [
    "company_id",
    "username",
    "first_name",
    "last_name",
    "primary_email",
    "primary_phone_number",
  ];

  useEffect(() => {
    checkAuthorities({
      can_create: "ADMINISTRATOR_MODULE:CAN_CREATE",
      can_read: "ADMINISTRATOR_MODULE:CAN_READ",
      can_update: "ADMINISTRATOR_MODULE:CAN_UPDATE",
      can_delete: "ADMINISTRATOR_MODULE:CAN_DELETE",
      can_export: "ADMINISTRATOR_MODULE:CAN_EXPORT",
      can_approve: "ADMINISTRATOR_MODULE:CAN_APPROVE",
    });
    getAllAdministrators({ size: requiredResults, pageIndex: 0 }).then(
      (response) => {
        const administratorList = response.data.data.filter(
          (admin) =>
            admin.administrator_id !==
            jwtDecode(sessionStorage.getItem("token")).id
        );
        dispatch(setAdministrators(administratorList));
        dispatch(
          setAdministratorBackendPagination({
            page_Index: response.data.page_index,
            total_pages: response.data.total_pages,
            records_displayed: response.data.records_displayed,
            total_elements: response.data.total_elements,
          })
        );
        setLoading(false);
      }
    );
  }, []);

  const handleClose = () => setOpen(false);

  const getAdministratorProfile = (admin) => {
    dispatch(setDynamicUser(admin));
    navigate(`/admin_profile/${admin.administrator_id}`, { replace: false });
  };

  const editAdministrator = (admin) => {
    dispatch(setDynamicUser(admin));
    navigate(`/administrator-edit-profile/${admin.administrator_id}`, {
      replace: false,
    });
  };

  const deleteAdministrator = (admin) => {
    dispatch(setDynamicUser(admin));
    setOpen(true);
  };

  const confirmDeleteAdministrator = async () => {
    let response;
    deleteAdministratorService(user.dynamicUser.administrator_id).then(
      (response) => {
        if (response.data.operation_code === 0) {
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
          dispatch(openAlert());
          dispatch(removeAdministrator(user.dynamicUser.administrator_id));
          setOpen(false);
          navigate("/administrators", {
            replace: true,
          });
        } else {
          dispatch(setAlertTitle("ERROR!"));
          dispatch(setAlertMessage(response.data.operation_description));
          dispatch(openAlert());
          setOpen(false);
          navigate("/administrators", {
            replace: true,
          });
        }
      }
    );
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - admins.length) : 0;

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleAdminSearch = (event) => {
  };

  const searchChange = (e) => {
    setSearching(true);
    if (
      searching &&
      admins.filter(
        (admin) =>
          admin.administrator_id?.toLowerCase().includes(e.target.value) ||
          admin.first_name?.toLowerCase().includes(e.target.value) ||
          admin.last_name?.toLowerCase().includes(e.target.value) ||
          admin.primary_email?.toLowerCase().includes(e.target.value) ||
          admin.primary_phone_number?.includes(e.target.value)
      ).length <= 0
    ) {
      setAdvanceSearching(true);
    }
    setSearchTerm(e.target.value);
  };
  const makeAdvancedSearch = () => {
    setLoading(true);
    filterAdministrators({
      size: requiredResults,
      pageIndex: 0,
      data: { company_id: searchTerm },
    }).then((response) => {
      setSearchResults(response.data.data);
      setLoading(false);
      dispatch(setAlertTitle("Success"));
      dispatch(setAlertMessage(response.data.message));
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={8} lg={6}>
          {advanceSearching ? (
            <>
              <Typography variant="h1">
                {`${searchResults?.length} of ${backendPagination.total_elements} Search results`}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1">
                {`${admins.length} of ${backendPagination.total_elements} Administrators`}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4} md={4} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl
                variant="standard"
                sx={{ width: "100%", marginBottom: "10px" }}
              >
                <BootstrapInput
                  id="searchTxt"
                  size="small"
                  type="text"
                  placeholder="Search"
                  onChange={searchChange}
                  sx={{ backgroundColor: "#FFFFFF" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                disabled={sessionStorage.getItem("can_create") === "false"}
                sx={{
                  backgroundColor: "#052A6C",
                  width: "100%",
                  height:"78%",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#052A6C",
                  },
                }}
                onClick={() =>
                  navigate("/create-administrator", {
                    replace: true,
                  })
                }
              >
                Add Administrator
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {searching && (
        <Grid container>
          <Grid item xs={6} md={4}>
            <Typography variant="h1">Advanced Search By</Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <ShareholderSelect
                    required
                    name="fieldSelect"
                    id="field-select"
                    onChange={(event) => {
                      setSearchField(event.target.value);
                    }}
                    sx={{ height: "100%" }}
                    defaultValue={"company_id"}
                  >
                    {fields?.map((field) => (
                      <MenuItem key={field} value={field}>
                        {field}
                      </MenuItem>
                    ))}
                  </ShareholderSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginBottom: "10px" }}
                >
                  <BootstrapInput
                    disabled
                    id="searchTxt"
                    size="small"
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    sx={{ backgroundColor: "#FFFFFF" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#AA0050",
                    margin: "2% 1% 2% 0",
                    width: "100%",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#B150C5",
                    },
                    fontSize: 14,
                  }}
                  onClick={makeAdvancedSearch}
                >
                  {loading ? <CircularProgress /> : "Search"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {alert.open === true ? (
        <AlertModal
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModal>
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        {loading ? (
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton>
        ) : (
          <Table aria-label="custom pagination table" size="small">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    align="left"
                    sx={{ fontWeight: "bold" }}
                  >
                    {header}
                  </TableCell>
                ))}
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold" }}
                  colSpan={3}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <>
              {advanceSearching ? (
                <TableBody>
                  {searchResults
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((admin) => (
                      <TableRow key={admin.administrator_id}>
                        <TableCell component="th">{admin.company_id}</TableCell>
                        <TableCell align="left">{admin.first_name}</TableCell>
                        <TableCell align="left">{admin.last_name}</TableCell>
                        <TableCell align="left">{`${admin.primary_email
                          .toLowerCase()
                          .substring(0, 15)}...`}</TableCell>
                        <TableCell align="left">
                          {admin.primary_phone_number}
                        </TableCell>
                        <TableCell align="left">
                          {admin.enabled ? (
                            <Done color="success" fontSize="small"></Done>
                          ) : (
                            <Clear color="error" fontSize="small"></Clear>
                          )}
                        </TableCell>

                        <TableCell sx={{ padding: 0 }}>
                          <Tooltip title="View administrator">
                            <IconButton
                              disabled={!sessionStorage.getItem("can_read")}
                              onClick={() => {
                                getAdministratorProfile(admin);
                              }}
                              style={{
                                color: !sessionStorage.getItem("can_delete")
                                  ? "gray"
                                  : green[500],
                              }}
                            >
                              <RemoveRedEye fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          <Tooltip title="Edit administrator">
                            <IconButton
                              disabled={
                                sessionStorage.getItem("can_update") === "false"
                              }
                              onClick={() => {
                                editAdministrator(admin);
                              }}
                              style={{
                                color:
                                  sessionStorage.getItem("can_update") ===
                                  "false"
                                    ? "gray"
                                    : orange[500],
                              }}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          <Tooltip title="Delete administrator">
                            <IconButton
                              disabled={
                                sessionStorage.getItem("can_delete") === "false"
                              }
                              onClick={() => {
                                deleteAdministrator(admin);
                              }}
                              style={{
                                color:
                                  sessionStorage.getItem("can_delete") ===
                                  "false"
                                    ? "gray"
                                    : red[500],
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  {admins
                    ?.filter((val) => {
                      if (searchTerm === "") {
                        return val;
                      } else if (
                        val.company_id?.toLowerCase()?.includes(searchTerm) ||
                        val.first_name?.toLowerCase()?.includes(searchTerm) ||
                        val.last_name?.toLowerCase()?.includes(searchTerm) ||
                        val.primary_email
                          ?.toLowerCase()
                          ?.includes(searchTerm) ||
                        val.primary_phone_number?.includes(searchTerm)
                      ) {
                        return val;
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((admin) => (
                      <TableRow key={admin.administrator_id}>
                        <TableCell component="th">{admin.company_id}</TableCell>
                        <TableCell align="left">{admin.first_name}</TableCell>
                        <TableCell align="left">{admin.last_name}</TableCell>
                        <TableCell align="left">{`${admin.primary_email
                          .toLowerCase()
                          .substring(0, 15)}...`}</TableCell>
                        <TableCell align="left">
                          {admin.primary_phone_number}
                        </TableCell>
                        <TableCell align="left">
                          {admin.enabled ? (
                            <Done color="success" fontSize="small"></Done>
                          ) : (
                            <Clear color="error" fontSize="small"></Clear>
                          )}
                        </TableCell>

                        <TableCell sx={{ padding: 0 }}>
                          <Tooltip title="View administrator">
                            <IconButton
                              disabled={!sessionStorage.getItem("can_read")}
                              onClick={() => {
                                getAdministratorProfile(admin);
                              }}
                              style={{
                                color: !sessionStorage.getItem("can_delete")
                                  ? "gray"
                                  : green[500],
                              }}
                            >
                              <RemoveRedEye fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          <Tooltip title="Edit administrator">
                            <IconButton
                              disabled={
                                sessionStorage.getItem("can_update") === "false"
                              }
                              onClick={() => {
                                editAdministrator(admin);
                              }}
                              style={{
                                color:
                                  sessionStorage.getItem("can_update") ===
                                  "false"
                                    ? "gray"
                                    : orange[500],
                              }}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ padding: 0 }}>
                          <Tooltip title="Delete administrator">
                            <span>
                            <IconButton
                              disabled={
                                sessionStorage.getItem("can_delete") === "false"
                              }
                              onClick={() => {
                                deleteAdministrator(admin);
                              }}
                              style={{
                                color:
                                  sessionStorage.getItem("can_delete") ===
                                  "false"
                                    ? "gray"
                                    : red[500],
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={4}
                  count={admins.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  users={admins}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    "&  .MuiTablePagination-spacer": {
                      display: "none",
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Report color="error" sx={{ marginRight: 2 }} />
            Are you sure you'd like to delete this administrator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ backgroundColor: "#B20808" }}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={confirmDeleteAdministrator}
            autoFocus
            sx={{ backgroundColor: "#052A6C" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default ListAdministrators;
