import { Box, Button, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllShareholders, getShareholders } from "../service/userService";
import { resetDynamicStatus, setShareholders } from "../store/userSlice";
import { ShareholderAutoComplete, ShareholderInput, ShareholderNumberTxt, ShareholderSelect } from "./forms/FormComponents"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import moment from "moment";
import { API } from "../config/API";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import { useNavigate } from "react-router-dom";
import { setBackendPagination } from "../store/settingsSlice";

const requiredResults = 20;
const CreateDividendForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [filteredPayModes, setFilteredPayModes] = useState();
  const [dividend_year, setDividend_year] = useState();
  const [shareholder_name, setShareholder_name] = useState();
  const [shareholder_code, setShareholder_code] = useState();
  const user = useSelector(state => state.user);
  const [payment_channel, setPayment_channel] = useState();
  const [payment_account_name, setPayment_account_name] = useState();
  const [payment_account_number, setPayment_account_number] = useState();
  const [bank, setBank] = useState();
  const [payment_branch_name, setPayment_branch_name] = useState();
  const [dividend_type, setDividend_type] = useState();
  const [payment_ref_number, setPayment_ref_number] = useState();
  const [external_ref_number, setExternal_ref_number] = useState();
  const [shares_held, setShares_held] = useState();
  const [actual_gross_amount, setActual_gross_amount] = useState(0);
  const [actual_tax_amount, setActual_tax_amount] = useState(0);
  const [actual_net_amount, setActual_net_amount] = useState();
  const [tax_rate, setTax_rate] = useState(0);
  const [payment_mode, setPayment_mode] = useState();
  const [payment_approved, setPayment_approved] = useState(false);
  const [payment_status, setPayment_status] = useState();
  const [paid_on, setPaid_on] = useState();
  const [comment, setComment] = useState();

  const sessionPayModes = sessionStorage.getItem('payModes');
  const payModes = JSON.parse(sessionPayModes);

  const shareholders = user.shareholders;
  const divYears = ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015',
    '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'];


  useEffect(() => {
    getAllShareholders({ size: requiredResults, pageIndex: 0 }).then((response) => {
      dispatch(setShareholders(response.data));
      dispatch(setBackendPagination(response));
    });
  }, []);

  const handleSetCurrentShareholder = (event, newValue) => {
    setValue(newValue);
    setShareholder_name(newValue.shareholder_name);
    setShareholder_code(newValue.shareholder_code);
    setPayment_account_name(newValue.payment_account_name);
    setPayment_account_number(newValue.payment_account_number);
  }
  const handleSetDivYear = (event, dividendYear) => {
    setDividend_year(dividendYear);
  }
  const handleSetBank = (event, bank) => {
    setBank(bank.props.children);
  }
  const handleSetBranch = (event) => {
    setPayment_branch_name(event.target.value);
  }
  const handleSetAccountName = (event) => {
    setPayment_account_name(event.target.value);
  }
  const handleSetAccountNumber = (event) => {
    setPayment_account_number(event.target.value);
  }
  const handlePayModeChange = (payMode) => {
    setPayment_channel(payMode)
    let preFilteredPayModes = payModes.filter(myPayMode => myPayMode.payment_type === payMode);
    setFilteredPayModes(preFilteredPayModes);
  }
  const handleSetDivType = (event) => {
    setDividend_type(event.target.value)
  }
  const handleSetComment = (event) => {
    setComment(event.target.value)
  }
  const handleSetPaymentStatus = (event) => {
    setPayment_status(event.target.value)
  }
  const handleSetExternalPrn = (event) => {
    setExternal_ref_number(event.target.value)
  }
  const handleSetInternalPrn = (event) => {
    setPayment_ref_number(event.target.value)
  }
  const handleSetTaxRate = (event) => {
    setTax_rate(event.target.value)
    setActual_tax_amount(((event.target.value) / 100) * actual_gross_amount);
  }
  const handleSetGrossAmount = (event) => {
    setActual_gross_amount(event.target.value);
  }
  const handleSubmit = async () => {
    const newDividend = {
      payment_ref_number,
      external_ref_number,
      shares_held: parseInt(shares_held),
      actual_gross_amount: parseInt(actual_gross_amount),
      tax_rate: parseInt(tax_rate),
      actual_tax_amount: (tax_rate / 100) * actual_gross_amount,
      actual_net_amount: actual_gross_amount - actual_tax_amount,
      payment_mode_name: bank,
      dividend_year,
      dividend_type,
      shareholder_code,
      shareholder_name,
      payment_account_name,
      payment_account_number,
      payment_branch_name,
      payment_approved,
      payment_status,
      paid_on: moment(paid_on).format("YYYY-MM-DDTHH:mm"),
      comment
    }
    let response;
    try {
      response = await API.post(`dividends`, newDividend);
      if (response.status === 202) {
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response.data.data.ticket_number + ' created'));
        dispatch(openAlert());
        navigate("/tickets");
      }

    } catch (error) {

    }

  }
  return (
    <>
      <Divider />
      <Grid container sx={{ position: 'center', padding: '5% 0' }}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink htmlFor="ShareholderAutoComplete"
              sx={{
                color: '#000000'
              }}>
              Shareholder Code
            </InputLabel>
            <ShareholderAutoComplete
              disablePortal
              options={shareholders}
              getOptionLabel={(option) => option.shareholder_code}
              renderInput={(params) => <TextField {...params} />}
              value={value}
              onChange={(event, newValue) => {
                handleSetCurrentShareholder(event, newValue);   
              }
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink htmlFor="bootstrap-input"
              sx={{
                color: '#000000'
              }}>
              Shareholder
            </InputLabel>
            <ShareholderInput
              id="lastNameTxt"
              value={shareholder_name}
              sx={{ width: '80%' }}
              disabled />
          </FormControl>

        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink htmlFor="ShareholderAutoComplete"
              sx={{
                color: '#000000'
              }}>
              Dividend Year
            </InputLabel>
            <ShareholderAutoComplete
              disablePortal
              options={divYears}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} />}
              value={dividend_year}
              onChange={(event, newValue) => {
                handleSetDivYear(event, newValue);             
              }
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel id="demo-row-radio-buttons-group-label"
              sx={{
                color: '#000000'
              }}>Dividend Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={dividend_type}
              onChange={handleSetDivType}
            >
              <FormControlLabel value='INTERIM' control={<Radio />} label="Interim" />
              <FormControlLabel value='FINAL' control={<Radio />} label="Final" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <FormControl sx={{ width: '100%' }}>
            <FormLabel id="demo-row-radio-buttons-group-label"
              sx={{
                color: '#000000'
              }}>Preferred Payment Channel</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={payment_channel}
              onChange={(event) => {
                handlePayModeChange(event.target.value)
              }}
            >
              <FormControlLabel value='BANK' control={<Radio />} label="Bank" />
              <FormControlLabel value='WALLET' control={<Radio />} label="Wallet" />
            </RadioGroup>
          </FormControl>
        </Grid>

        {filteredPayModes &&
          <Grid container>
            <Grid item xs={12} sm={6}>
              <FormControl sx={{ width: "100%", marginTop: 2 }}>
                <InputLabel
                  shrink
                  htmlFor="pay-mode-name-select"
                  sx={{
                    marginLeft: -1,
                    color: '#000000'
                  }}
                >
                  Bank/Wallet name: <span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <ShareholderSelect
                  required
                  name="payModeName"
                  id="pay-mode-name-select"
                  onChange={(event, newValue) => {
                    handleSetBank(event, newValue);
                  }}
                >
                  {filteredPayModes.map((paymode) => (
                    <MenuItem key={paymode.payment_mode_code} value={paymode.payment_mode_code}>
                      {paymode.payment_mode_name}
                    </MenuItem>
                  ))}
                </ShareholderSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink htmlFor="branch-name-txt"
                  sx={{
                    color: '#000000'
                  }}>
                  Branch Name:
                </InputLabel>
                <ShareholderInput
                  required
                  id="branch-name-txt"
                  name="branchName"
                  onChange={handleSetBranch}
                  value={user.dynamicUser.payment_branch_name}
                  sx={{ width: "80%" }}
                />
              </FormControl>
            </Grid>
          </Grid>
        }
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="bootstrap-input"
                sx={{
                  color: '#000000'
                }}>
                Account Name:
              </InputLabel>
              <ShareholderInput
                required
                id="account-name-txt"
                name="accountNameTxt"
                value={payment_account_name}
                onChange={handleSetAccountName}
                sx={{ width: "80%" }}
              />
            </FormControl>

          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel shrink htmlFor="account-number-txt"
                sx={{
                  color: '#000000'
                }}>
                Account Number:
              </InputLabel>
              <ShareholderInput
                required
                id="account-number-txt"
                name="accountNumberTxt"
                value={payment_account_number}
                onChange={handleSetAccountNumber}
                sx={{ width: "80%" }}
              />
            </FormControl>

          </Grid>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Gross Amount</InputLabel>
            <ShareholderNumberTxt
              id="gross_amount_txt"
              type="number"
              value={actual_gross_amount}
              onChange={handleSetGrossAmount}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Tax Rate</InputLabel>
            <ShareholderNumberTxt
              id="tax_rate_txt"
              type="number"
              value={tax_rate}
              onChange={handleSetTaxRate}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Payment Reference Number (Internal)</InputLabel>
            <ShareholderInput
              value={payment_ref_number}
              onChange={handleSetInternalPrn}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Payment Reference Number (External)</InputLabel>
            <ShareholderInput
              value={external_ref_number}
              onChange={handleSetExternalPrn}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Payment status</InputLabel>
            <ShareholderInput
              value={payment_status}
              onChange={handleSetPaymentStatus}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Net Amount</InputLabel>
            <ShareholderInput
              // disabled
              value={(parseInt(actual_gross_amount) - parseInt(actual_tax_amount)).toLocaleString()}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Shares</InputLabel>
            <ShareholderInput
              value={shares_held}
              onChange={(event) => setShares_held(event.target.value)}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateField']} sx={{ marginTop: 1 }}>
              <DatePicker
                label="Date paid"
                format="MM-DD-YY HH:mm"
                value={paid_on}
                onChange={(newValue) => setPaid_on(newValue.$d)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container>
        <Grid item xs={6}>
          <FormGroup variant="standard" sx={{ width: '100%' }}>
            <InputLabel shrink
              sx={{
                color: '#000000'
              }}>Comment</InputLabel>
            <ShareholderInput
              value={comment}
              onChange={handleSetComment}
              multiline
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Box
        m={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button variant="contained" onClick={handleSubmit} sx={{ backgroundColor: '#052A6C', }}>
          Submit
        </Button>
      </Box>
    </>
  )
}
export default CreateDividendForm;