import { Grid, Typography } from "@mui/material";
import React from "react";
import stanbicLogo from "../assets/images/logo.png";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        background: "white",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: "40px",
        }}
      >
        <Grid item xs={12} md={3} lg={3}></Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: "60px",
              borderRadius: "4px",
            }}
            src={stanbicLogo}
            alt="Stanbic Logo"
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}></Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "24px",
              color: "#000000",
            }}
          >
            404
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}></Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          borderBottom: "1px solid #00000040",
          marginBottom: "10px",
        }}
      >
        <Grid item xs={12} md={3} lg={3}></Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "24px",
              color: "#000000",
              marginBottom: "20px",
            }}
          >
            Oops, page not found
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} lg={3}></Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={1}></Grid>
        <Grid
          item
          xs={12}
          md={10}
          lg={10}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              color: "#444A4A",
              marginBottom: "20px",
            }}
          >
            Sorry but the requested page was not found.
          </Typography>
        </Grid>
        <Grid item xs={12} md={1} lg={1}></Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
