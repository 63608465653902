import { useContext } from 'react';
import NavigationContext from '../../store/NavigationContext';
import EditAccountOfficerCard from '../../components/EditAccountOfficerCard';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';


function EditAccountOfficerProfilePage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <EditAccountOfficerCard />      
    </MiniDrawer>
  )
}

export default EditAccountOfficerProfilePage;