// import * as React from 'react';
import { css } from '@emotion/react';
import {
  AccountCircle,
  FileOpen,
  Group,
  Home,
  ListAltOutlined,
  Logout,
  NotificationsNone,
  Paid,
  PendingActions,
  PieChart,
  Settings
} from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import stanbicLogo from '../../assets/images/logo.png';
import { checkModuleRights } from '../../service/securityService';
import { useAuth } from '../../store/authContext';
import NavigationContext from '../../store/NavigationContext';
import { resetDynamicStatus } from '../../store/userSlice';
import { logoutShareholder } from '../../service/userService';
import jwtDecode from 'jwt-decode';

const drawerWidth = 220;

export const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const PersistentDrawerLeft = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigationContext = useContext(NavigationContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState(null);
  const menuOpen = Boolean(anchor);
  const { setUser } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    checkModuleRights('hello')
  }, [])

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  const handleUserNavigation = (text) => {
    if (text === 'Home') {
      navigate('/administrator-dashboard');      
    } else if(text === 'Accounting') {
    } else {      
      let page = text.toLowerCase();
      navigate(`/${page}`);
    }
    dispatch(resetDynamicStatus);
  };
  const handleAccountingNavigation = (text) => {
    let page = text.toLowerCase();
    navigate(`/${page}`);
    dispatch(resetDynamicStatus);
  };

  function toggleSideBar() {
    navigationContext.sidebarHandler(true);
  }

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const logout = () => {
    logoutShareholder().then(response => {
      setAnchor(null);
      sessionStorage.clear();
      setUser(null);
      navigate('/');
    })
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    toggleSideBar();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    toggleSideBar();
  };
  const showProfile = () => {
    navigate(`/admin_profile/${jwtDecode(sessionStorage.getItem("token")).id}`)
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar sx={{ backgroundColor: '#ffffff' }} position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: '#ffffff' }}>
          <Grid container>
            <Grid item xs={5} sm={3} md={4} lg={10}>
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  color: '#052A6C',
                  mr: 2,
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={7} sm={9} md={8} lg={2}>
              <Grid container>
                <Grid item sm={3} lg={2}>
                  <IconButton sx={{ color: '#052A6C' }}>
                    <NotificationsNone></NotificationsNone>
                  </IconButton>
                </Grid>
                <Grid item sm={6} lg={8} sx={{ textAlign: 'right', paddingTop: '5%' }}>
                  <Typography sx={{ color: '#052A6C' }}>
                    <strong>Welcome,</strong>{' '}
                    {sessionStorage.getItem('username')}
                  </Typography>
                </Grid>
                <Grid item sm={3} lg={2}>
                  <IconButton sx={{ color: '#052A6C' }} onClick={handleClick}>
                    <AccountCircle></AccountCircle>
                  </IconButton>
                  <Menu
                    anchorEl={anchor}
                    open={menuOpen}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    sx={{
                      margin: 0, padding: 0
                    }}
                  >
                    <MenuItem onClick={showProfile}><AccountCircle fontSize='small' /> Profile</MenuItem>
                    <MenuItem onClick={logout}><Logout fontSize='small' /> Logout</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: '#052A6C',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Grid container>
            <Grid item sm={2} md={3}></Grid>
            <Grid item sm={8} md={6}>
              <img
                style={{
                  height: '60px',
                  horizontalAlign: 'center',
                  borderRadius: '4px',
                  paddingTop: '10px',
                }}
                src={stanbicLogo}
              />
            </Grid>
            <Grid item sm={2} md={3}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon sx={{ color: '#ffffff' }} />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider />
        <List component="nav">
          {['Home', 'Administrators', 'Shareholders', 'Account-officers', 'Tickets',
          'Accounting','Settings', 'file-approve', 'Logs' ].map((text, index) => (
            <>            
                <ListItemButton
                  key={index}
                  onClick={(e) => {
                    handleListItemClick(index);
                    handleUserNavigation(text);
                  }}
                  sx={{color: '#ffffff', '& .Mui-selected':{backgroundColor: '#ffffff'}}}
                >
                  <ListItemIcon sx={{ color: '#ffffff' }}>
                    {index === 0 ? (<Home />
                      ) : (
                        <>{index === 1 ? (<AccountCircle />
                        ) : (
                          <>{index === 2 ? (<PieChart />
                          ) : (
                            <>{index===3 ? (<Group />
                            ):(
                              <>{index === 4 ? (<ListAltOutlined />
                              ):(
                                <>{index === 5 ? (<Paid></Paid>
                                ):(
                                  <>{index === 6 ? (<FileOpen />
                                  ):(<PendingActions />)}
                                  </>)}
                                </>)}
                              </>)}
                            </>)}
                          </>)}
                        </>)}
                  </ListItemIcon>
                  {index === 5 ? (<>
                    <InputLabel
                      id="payout-dropdown-label"
                      sx={{ color: '#ffffff' }}
                    >
                      {text}
                    </InputLabel>
                    <Select
                      labelId="payout-dropdown-label"
                      id="payout-select"
                      label={text}
                      sx={{
                        '&  .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                      }}
                    >
                      {['Schedules', 'Approved-payments', 'Ledgers(work In Progress)', 'Reports(work In Progress)'].map((text, index) => (
                        <MenuItem disabled={index === 2 || index === 3 } key={index} onClick={()=>handleAccountingNavigation(text)}>
                          {text}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                  ):(
                    <ListItemText primary={text} />)}                
                </ListItemButton>
            </>))
          }
          </List>
      </Drawer>
    </Box>
  );
};
export default PersistentDrawerLeft;
