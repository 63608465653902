import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import DataTable from "../../components/tables/DataTable";
import { bulkDividendPost } from "../../service/dividendService";
import NavigationContext from "../../store/NavigationContext";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";

const AdministratorDivivdendBulkUploadView = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const dividend = useSelector((state) => state.dividend);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "No", headerName: "ID" },
    { field: "payment_ref_number", headerName: "Payment Ref" },
    { field: "external_ref_number", headerName: "External Ref" },
    { field: "shares_held", headerName: "Shares" },
    { field: "actual_gross_amount", headerName: "Gross Amount" },
    { field: "actual_tax_amount", headerName: "Tax" },
    { field: "actual_net_amount", headerName: "Net Amount" },
    { field: "tax_rate", headerName: "Tax Rate" },
    { field: "dividend_year", headerName: "Dividend Year" },
    { field: "shareholder_code", headerName: "Code" },
    { field: "shareholder_name", headerName: "Name" },
    { field: "payment_account_name", headerName: "Account Name" },
    { field: "payment_account_number", headerName: "Account Number" },
    { field: "payment_branch_name", headerName: "Branch Name" },
    { field: "dividend_type", headerName: "Dividend Type" },
    { field: "paid_on", headerName: "Paid On" },
    { field: "comment", headerName: "Comment" },
    { field: "payment_status", headerName: "Status" },
    { field: "primary_email", headerName: "Email" },
  ];

  const bulkDivivdendsTableStyles = {
    height: "400px",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
  };

  //bulkDividendUpload function
  const bulkDividendUpload = (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare data for bulk dividend upload
    const dividendBulkData = {
      file_name: dividend.fileName,
      dividend_list: dividend.excelData,
    };

    // Perform bulk shareholder upload
    bulkDividendPost(dividendBulkData)
      .then((response) => {
        console.log('div response: ', response);

        // Handle success response
        if (response?.data?.operation_code === 0) {
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
        } else {
          // Handle error response
          dispatch(setAlertTitle("ERROR!"));
          dispatch(setAlertMessage(response?.data?.message));
        }

        dispatch(openAlert());
        navigate("/file-approve");
      })
      .catch((error) => {

        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage("An unexpected error occurred."));
        dispatch(openAlert());
        navigate("/file-approve");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MiniDrawer>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: "20px",
        }}
      >
        <Grid item xs={8} md={8} lg={8}>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              color: " #0A2240",
              marginTop: "8px",
            }}
          >
            View uploaded dividends
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ display: "flex", justifyContent: "flex-end", columnGap: 1 }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#B20808",
              marginLeft: "160px",
              textTransform: "none",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              color: " #FFFFFF",
            }}
            onClick={() =>
              navigate("/shareholders", {
                replace: true,
              })
            }
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#052A6C",
              textTransform: "none",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              color: " #FFFFFF",
            }}
            disabled={loading}
            onClick={bulkDividendUpload}
          >
            {loading && (
              <CircularProgress size={25} style={{ color: "white" }} />
            )}
            {loading ? "" : "Upload"}
          </Button>
        </Grid>
      </Grid>

      <DataTable
        rows={dividend.excelData}
        columns={columns}
        loading={!dividend.excelData.length}
        getRowId={(row) => row.No}
        sx={bulkDivivdendsTableStyles}
      />
    </MiniDrawer>
  );
};

export default AdministratorDivivdendBulkUploadView;
