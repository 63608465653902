// import { TabPanel } from "@mui/lab";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardMedia,
    FormGroup,
    Grid,
    InputLabel
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import administratorBanner from "../assets/images/admin.png";
import {API} from "../config/API";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import { replaceOutdatedAdmin, setDynamicUser } from "../store/userSlice";
import { ShareholderInput } from "./forms/FormComponents";

const AdministratorDetailsEditCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = useParams();


  const administrator = useSelector((state) => state.user.dynamicUser);
  const [first_name, setFirst_name] = useState(administrator.first_name);
  const [last_name, setLast_name] = useState(administrator.last_name);
  const [primary_email, setPrimary_email] = useState(
    administrator.primary_email
  );
  const [primary_phone_number, setPrimary_phone_number] = useState(
    administrator.primary_phone_number
  );
  const [username, setUsername] = useState(administrator.username);
  const [company_id, setCompany_id] = useState(administrator.company_id);

  const updateAdmin = async () => {
    let response;
    let newAdminDetails = {
      first_name,
      last_name,
      primary_email,
      primary_phone_number,
      username,
      company_id,
    };
    try {
      response = await API.put(
        `administrators/${administrator.administrator_id}`,
        newAdminDetails
      );
      if (response.status === 202) {
        let updatedAdministrator = response.data.data;
        dispatch(replaceOutdatedAdmin(updatedAdministrator));
        dispatch(setAlertMessage(response.data.operation_description));
        dispatch(setAlertTitle("Success"));
        dispatch(openAlert());
        dispatch(setDynamicUser(updatedAdministrator));
        navigate(`/admin_profile/${locale.administrator_id}`, {replace: true});
      }
    } catch (error) {
      dispatch(setAlertMessage(error.response.data.operation_description));
      dispatch(setAlertTitle("error"));
      dispatch(openAlert());
      navigate("/admin-profile", {
        replace: true,
      });
    }
  };

  return (
    <>
      <Card>
        <CardMedia
          component="img"
          height="180"
          image={administratorBanner}
          alt="Shareholder Banner"
        ></CardMedia>
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ paddingBottom: 2 }}>
              <Avatar></Avatar>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>First Name</InputLabel>
                <ShareholderInput
                  value={first_name}
                  onChange={(event) => setFirst_name(event.target.value)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Last Name</InputLabel>
                <ShareholderInput
                  value={last_name}
                  onChange={(event) => setLast_name(event.target.value)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Email</InputLabel>
                <ShareholderInput
                  value={primary_email}
                  onChange={(event) => setPrimary_email(event.target.value)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Phone</InputLabel>
                <ShareholderInput
                  value={primary_phone_number}
                  onChange={(event) =>
                    setPrimary_phone_number(event.target.value)
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Username</InputLabel>
                <ShareholderInput
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Company ID</InputLabel>
                <ShareholderInput
                  value={company_id}
                  onChange={(event) => setCompany_id(event.target.value)}
                />
              </FormGroup>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
              disabled={!sessionStorage.getItem('can_update')}
                variant="contained"
                color="primary"
                sx={{ backgroundColor: "#052A6C", height: 40 }}
                onClick={updateAdmin}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
export default AdministratorDetailsEditCard;
