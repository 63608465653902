import { OfficerApi } from '../../config/API';

const dividendService = {
  async findDividendsByShareholderCode({ shareholderCode, pageIndex = 0 }) {
    return await OfficerApi.get(
      `/dividends/shareholder/${shareholderCode}?size=20&page_index=${pageIndex}&direction=desc&field_name=payment_ref_number`
    );
  },

  async claimForMissingDividends({ data }) {
    return await OfficerApi.post(`/dividends/claims`, data);
  },
};

export default dividendService;
