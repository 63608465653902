import { useContext, useState } from "react";
import AdministratorDetailsEditCard from "../../components/AdministratorDetailsEditCard";
import NavigationContext from "../../store/NavigationContext";
import "./AdministratorEditProfilePage.css";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
// import "./CreateShareholderPage.css";




function AdministratorProfileEditPage(props) {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  return (
    <MiniDrawer>
      <AdministratorDetailsEditCard />
    </MiniDrawer>
  )
}

export default AdministratorProfileEditPage;
