import { OfficerApi } from '../../config/API';

const officerService = {
  async findOfficerByCode({ officerCode }) {
    return await OfficerApi.get(`/account-officers/${officerCode}`);
  },
  async updateOfficerNewPassword({
    officerCode,
    action = 'UPDATE',
    data = {},
  }) {
    return await OfficerApi.put(
      `/account-officers/update-security/${officerCode}?action=${action}`,
      data
    );
  },
};

export default officerService;
