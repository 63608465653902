
import { useContext } from 'react'
import NavigationContext from '../../store/NavigationContext'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import CreateDividendForm from '../../components/CreateDividendForm';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';

function CreateDividendPage(props) {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <Card>
                 <CardContent>
                   <Typography variant='h6' >Add Dividend</Typography>
                   <CreateDividendForm />
                 </CardContent>
               </Card>
    </MiniDrawer>
  )
}
export default CreateDividendPage;