import { Box } from "@mui/material";
import React, { useContext } from "react";
import PersistentDrawerLeft, { DrawerHeader, Main,} from "../../components/navigation/sideDrawer";
import NavigationContext from "../../store/NavigationContext";
import ListTicketsTable from "../../components/tables/ListTicketsTable";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

const requiredResults = 240;
function AdministratorTickets() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext
  return (
    <MiniDrawer>
      <ListTicketsTable />
    </MiniDrawer>
  )
}
export default AdministratorTickets;
