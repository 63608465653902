import { useContext, useEffect, useState } from 'react';
import NavigationContext from '../../store/NavigationContext'
import ListAdministrators from '../../components/tables/ListAdministratorsTable';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';
import FileViolations from '../../components/FileViolations';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FlyTable from '../../components/tables/FlyTable';


function FileViolationsPage() {
  const navigationContext = useContext(NavigationContext)
  const { sidebar } = navigationContext

  
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const violations = useSelector((state) => state.files.violations);
  const [entryViolations, setEntryViolations] = useState([]);
  const locale = useParams();
  const headers = ['SCD/COM Number', 'Violation']
  const actions = ['View']

  
  useEffect(() => {
    setLoading(true);
    const organiseViolations = () => {      
      for (let index = 0; index < violations.length; index++) {
        const element = violations[index];
        if (locale.file_id === element.id) {
          console.log(element);
          entryViolations.push(element)       
        }
        
      }
      setLoading(false);
    }
    organiseViolations();
  }, []);
  
  return (
    <MiniDrawer >
      {/* <FileViolations /> */}
            <FlyTable
              header='Violations'
              data={entryViolations}
              headers={headers}
              // actions={actions}
              // requiredResults={requiredResults}
              // paginationDetails={paginationDetails} 
              />
    </MiniDrawer>
  )
}

export default FileViolationsPage;