import { Grid, Typography } from '@mui/material';
import React from 'react';

function CreateTicket({
  ticket_number = 'N/A',
  shareholder_name = 'N/A',
  primary_phone_number = 'N/A',
  primary_email = 'N/A',
  primary_address = 'N/A',
  tin = 'N/A',
  shareholder_com_number = 'N/A',
  shareholder_scd_number = 'N/A',
  payment_account_name = 'N/A',
  payment_account_number = 'N/A',
  shares_held = 'N/A',
  tax_rate_code = 'N/A',
  payment_mode_code = 'N/A',
  user_comment = 'N/A',
  created_by = 'N/A',
}) {
  const ticketDetailsStyles = {
    marginBottom: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#000000',
  };

  return (
    <Grid container spacing={2} sx={{ marginBottom: '40px' }}>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>Ticket Number</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder Name</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Account Name</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Account Number</Typography>
        <Typography sx={ticketDetailsStyles}>Share Held</Typography>
        <Typography sx={ticketDetailsStyles}>Email</Typography>
        <Typography sx={ticketDetailsStyles}>Phone</Typography>
        <Typography sx={ticketDetailsStyles}>Address</Typography>
        <Typography sx={ticketDetailsStyles}>Tin</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder COM Number</Typography>
        <Typography sx={ticketDetailsStyles}>Shareholder SCD Number</Typography>
        <Typography sx={ticketDetailsStyles}>Payment Code</Typography>
        <Typography sx={ticketDetailsStyles}>Tax Code</Typography>
        <Typography sx={ticketDetailsStyles}>Comment</Typography>
        <Typography sx={ticketDetailsStyles}>Created By</Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography sx={ticketDetailsStyles}>{ticket_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{shareholder_name}</Typography>
        <Typography sx={ticketDetailsStyles}>{payment_account_name}</Typography>
        <Typography sx={ticketDetailsStyles}>
          {payment_account_number}
        </Typography>
        <Typography sx={ticketDetailsStyles}>{shares_held}</Typography>
        <Typography sx={ticketDetailsStyles}>{primary_email}</Typography>
        <Typography sx={ticketDetailsStyles}>{primary_phone_number}</Typography>
        <Typography sx={ticketDetailsStyles}>{primary_address}</Typography>
        <Typography sx={ticketDetailsStyles}>{tin}</Typography>
        <Typography sx={ticketDetailsStyles}>
          {shareholder_com_number}
        </Typography>
        <Typography sx={ticketDetailsStyles}>
          {shareholder_scd_number}
        </Typography>
        <Typography sx={ticketDetailsStyles}>{payment_mode_code}</Typography>
        <Typography sx={ticketDetailsStyles}>{tax_rate_code}</Typography>
        <Typography sx={ticketDetailsStyles}>
          {user_comment ?? 'N/A'}
        </Typography>
        <Typography sx={ticketDetailsStyles}>{created_by}</Typography>
      </Grid>
    </Grid>
  );
}

export default CreateTicket;
