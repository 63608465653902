
import { Col, Form, Row, Table, Card } from 'react-bootstrap'


function ListChangeRequests() {
  const changeRequests = [
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    },
    {
      id: 'CR100112',
      date: '17th June, 2022',
      name: 'Christian Musekura',
      status: 'pending'
    }
  ]

  function renderRequest(request) {
    return (
      <tr key={request.id}>
        <td>{request.id}</td>
        <td>{request.date}</td>
        <td>{request.name}</td>
        <td>{request.status}</td>
      </tr>
    )
  }
  return (
    <div>
      <Row>
        <Col className='card_col'>
          <Form className='search_form'>
            <Row>
              <Col sm={9} md={9} lg={9} style={{display:'flex', justifyContent:'right', padding:"0"}}>
                <Form.Control type='input' placeholder='Search' className='search_form_input'>
                </Form.Control>
              </Col>
            </Row>
          </Form>
          <Card id='table_card'>
            <Card.Body>
              <Table  hover className='admin_table'>
                <thead>
                    <tr>
                    <th>Change Request Number</th>
                    <th>Request Date</th>
                    <th>Requested By</th>
                    <th>Change Request Status</th>
                    </tr>
                </thead>
                <tbody>
                  {changeRequests.map(renderRequest)}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
      )
}
export default ListChangeRequests