import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAdministratorById,
  getHistory,
  getPaymentModes,
  getShareholderAccountOfficers,
  getTaxRates,
} from "../service/userService";

const userSlice = createSlice({
  name: "userStore",
  initialState: {
    user: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      username: "",
      company_id: "",
    },
    dynamicUser: {},
    shareholder: {},
    shareholderCode: '',
    advancedSearch: false,
    searchObject: {},
    searchResults: [],
    userQrCode:"",
    // ticket: {},
    country: {},
    revisions: [],
    userFunctionalGroups: [],
    userModuleAuthorities: [],
    shareholders: [],
    administrators: [],
    authenticationLogs:[],
    accountOfficers: [],
    shareholderAccountOfficers: [],
    // tickets: [],
    activities: [],
    paymentModes: [],
    // countries: [],
    taxRates: [],
    dynamicStatus: "idle",
    status: "idle",
    shareholderStatus: "idle",
    // ticketStatus: "idle",
    Historystatus: "idle",
    AccountOfficerstatus: "idle",
    paymentModesStatus: "idle",
    taxRateStatus: "idle",
    modulesStatus: "idle",
    error: "",
    excelFileError: null,
    excelFile: null,
    excelData: null,
    fileName: "",
    fileType: "",    
    backendPagination: {
      page_Index:0,
      total_pages:0,
      records_displayed:0,
      total_elements:0,
    },
    total_elements: 0,
    data_total_elements: 0,
    total_pages: 0,
    pages: 0,
    page_index: 0,
    page_index_back: 0,
    is_authenticated: false,
  },
  reducers: {
    setShareholderCode: (state, actions) => {
      state.shareholderCode = actions.payload;
    },
    setSearchObject: (state, actions) => {
      state.searchObject = actions.payload;
    },
    setSearchResults: (state, actions) => {
      state.searchObject = actions.payload;
    },
    replaceOutdatedAdmin: (state, actions) => {
      let res = state.administrators.findIndex(
        (admin) => admin.administrator_id === actions.payload.administrator_id
      );
      state.administrators.splice(res, 1, actions.payload);
    },
    replaceOutdatedAccountOfficer: (state, actions) => {
      let res = state.accountOfficers.findIndex(
        (officer) => officer.officer_code === actions.payload.officer_code
      );
      state.accountOfficers.splice(res, 1, actions.payload);
    },
    resetDynamicStatus: (state) => {
      state.dynamicStatus = "idle";
    },
    resetDynamicUser: (state) => {
      state.dynamicUser = {};
    },
    setShareholderBackendPagination: (state, actions) => {
      state.backendPagination = actions.payload
    },
    setShareholderAdvancedSearch: (state, actions) => {
      state.advancedSearch = actions.payload
    },

    setPage: (state, action) => {
      state.page_index = action.payload;
    },
    setTotalPages: (state, action) => {
      state.total_pages = action.payload;
    },
    setTotalDataElements: (state, action) => {
      state.data_total_elements = action.payload;
    },
    appendAccountOfficer: (state, action) => {
      state.accountOfficers.splice(0, 0, action.payload);
    },

    updateFirstName: (state, action) => {
      state.dynamicUser.first_name = action.payload;
    },
    updateLastName: (state, action) => {
      state.dynamicUser.last_name = action.payload;
    },
    updateEmail: (state, action) => {
      state.dynamicUser.primary_email = action.payload;
    },
    updatePhone: (state, action) => {
      state.dynamicUser.primary_phone_number = action.payload;
    },
    updateUsername: (state, action) => {
      state.dynamicUser.username = action.payload;
    },
    addShareholderCode: (state, action) => {
      state.dynamicUser.shareholder_code = action.payload;
    },
    addShareholderCategory: (state, action) => {
      if (action.payload === true) {
        state.dynamicUser.gender = "other";
      }
      state.dynamicUser.company = action.payload;
    },
    removeAdministrator: (state, action) => {
      state.administrators = state.administrators.filter(
        (administrator) => administrator.administrator_id !== action.payload
      );
      state.total_elements -= 1;
    },
    removeFunctionalGroupAdmin: (state, action) => {
      state.dynamicUser.functional_groups =
        state.dynamicUser.functional_groups.filter(
          (fg) => fg.functional_group_name !== action.payload
        );
    },
    updateAuthority: (state, action) => {
      let index = state.dynamicUser.module_authorities
        .map((e) => e.module_name)
        .indexOf(action.payload);
    },
    updateAdministratorDetails: (state, action) => {
      state.administrators = state.administrators.map((administrator) => {
        if (
          action.payload.administrator_id === administrator.administrator_id
        ) {
          state.dynamicUser = action.payload;
        }
      });
    },
    searchShareholders: (state, action) => {
      state.shareholders = action.payload;
    },
    addFunctinalGroups: (state, action) => {
      if (state.userFunctionalGroups.includes(action.payload)) {
        let index = state.userFunctionalGroups.indexOf(action.payload);
        state.userFunctionalGroups.splice(index, 1);
      } else {
        state.userFunctionalGroups.push(action.payload);
      }
    },
    setShareholder: (state, action) => {
      state.shareholder = action.payload
    },
    setShareholders: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (
          !state.shareholders.some(
            (e) => e.shareholder_code === element.shareholder_code
          )
        ) {
          state.shareholders.push(element);
        }
      }
    },
    setAccountOfficers: (state, action) => {
      for (let index = 0; index < action.payload.length; index++) {
        const element = action.payload[index];
        if (
          !state.accountOfficers.some(
            (e) => e.officer_code === element.officer_code
          )
        ) {
          state.accountOfficers.push(element);
        }
      }
    },
    setAdminFunctionalGroups: (state) => {
      if (state.dynamicUser.hasOwnProperty("functional_groups")) {
        state.userFunctionalGroups.push(
          ...state.dynamicUser.functional_groups.map(
            (functional_group) => functional_group.functional_group_name
          )
        );
      }
    },
    addModuleAuthorities: (state, action) => {
      if (state.userModuleAuthorities.includes(action.payload)) {
        let index = state.userModuleAuthorities.indexOf(action.payload);
        state.userModuleAuthorities.splice(index, 1);
      } else {
        state.userModuleAuthorities.push(action.payload);
      }
    },
    setCurrentAdministrator: (state, action) => {
      state.dynamicUser = action.payload;
    },
    setCurrentShareholder: (state, action) => {
      state.dynamicUser = action.payload;
    },
    setDynamicUser: (state, action) => {
      state.dynamicUser = action.payload;
    },
    setShareholderQrCode:(state, action) => {
      state.userQrCode = action.payload;
    },
    setAuthenticationLogs:(state, action) => {
      state.authenticationLogs = action.payload;
    },
    setModuleStatus: (state) => {
      state.modulesStatus = "completed";
    },
    setAccountOfficerStatus: (state) => {
      state.AccountOfficerstatus = "idle";
    },
    setCurrentCountry: (state, action) => {
      state.country = action.payload;
    },
    removeShareholder: (state, action) => {
      state.shareholders = state.shareholders.filter(
        (shareholder) => shareholder.shareholder_code !== action.payload
      );
    },
    removeAccountOfficer: (state, action) => {
      state.accountOfficers = state.accountOfficers.filter(
        (accountOfficer) => accountOfficer.officer_code !== action.payload
      );
    },
    setExcelFile: (state, action) => {
      state.excelFile = action.payload;
    },
    setExcelFileError: (state, action) => {
      state.excelFileError = action.payload;
    },
    setExcelData: (state, action) => {
      state.excelData = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setFileType: (state, action) => {
      state.fileType = action.payload;
    },
    setShareholderAccountOfficers: (state, action) => {
      state.shareholderAccountOfficers = action.payload;
    },
    updateAdminList: (state, actions) => {
      state.administrators.splice(0, 0, actions.payload);
      state.total_elements += 1;
    },
    addShareholderPayMode: (state, actions) => {
      state.dynamicUser.payment_mode_code = actions.payload;
    },
  },
});

export const {
  setShareholderCode,
  setSearchObject,
  setSearchResults,
  setShareholder,
  resetDynamicUser,
  replaceOutdatedAdmin,
  setShareholderAdvancedSearch,
  replaceOutdatedAccountOfficer,
  appendAccountOfficer,
  resetDynamicStatus,
  updateAdminList,
  callAccountOfficers,
  setPage,
  setTotalPages,
  setTotalDataElements,
  setShareholderAccountOfficers,
  addSecondaryEmail,
  addSecondaryPhone,
  addSecondaryAddress,
  addOfficerCode,
  addFirstName,
  addShareholderPayMode,
  addShareholderCode,
  updateFirstName,
  addLastName,
  updateLastName,
  addEmail,
  updateEmail,
  addCompanyID,
  setDynamicUser,
  setShareholderQrCode,
  setAuthenticationLogs,
  setAdminFunctionalGroups,
  setShareholderHistory,
  addFunctinalGroups,
  addModuleAuthorities,
  addPhone,
  updatePhone,
  addUsername,
  updateUsername,
  setModuleStatus,
  setCurrentCountry,
  removeAdministrator,
  removeFunctionalGroupAdmin,
  updateAdministratorDetails,
  setShareholders,
  setAccountOfficerStatus,
  setAccountOfficers,
  removeAccountOfficer,
  setExcelFile,
  setExcelFileError,
  setExcelData,
  setFileName,
  setFileType,
  removeShareholder,
  addShareholderName,
  addShareholderTaxRate,
  addShareholderOtherName,
  addShareholderComNumber,
  addShareholderScd,
  addShareholderPrimaryPhone,
  addShareholderPrimaryEmail,
  addShareholderSecondaryPhone,
  addShareholderSecondaryEmail,
  addShareholderPaymentMode,
  addShareholderPaymentAccount,
  addShareholderPaymentAccountName,
  addShareholderPaymentBranchName,
  addShareholderPaymentBankName,
  addShareholderPaymentBranchAddress,
  addShareholderTin,
  addShareholderComment,
  addShareholderCategory,
  addBrokerCode,
  addShares,
  searchShareholders,
  setShareholderBackendPagination
} = userSlice.actions;

export default userSlice.reducer;
