import { TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Box, Grid, Tab, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import shareholder1 from "../../assets/images/shareholder1.png";
import PersistentDrawerLeft, {
  DrawerHeader,
  Main,
} from "../../components/navigation/sideDrawer";
import NavigationContext from "../../store/NavigationContext";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";

const AdministratorTicketsDetailsPage = () => {
  const navigate = useNavigate();
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const [selectedTab, setSelectedTab] = useState("1");
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const user = useSelector((state) => state.user);
  const ticketState = useSelector((state) => state.ticket);
  let content = ticketState.ticket?.content;
  const ticketDetailsStyles = {
    marginBottom: "10px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    color: "#000000",
  };

  const ticketDetailsHeaderStyles = {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    color: "#000000",
  };

  const tabLabel = {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    color: "#052A6C",
  };
  return (
    <MiniDrawer>
      <Card>
            <CardMedia
              sx={{ height: 140 }}
              image={shareholder1}
              title="green iguana"
            />
            <CardContent>
              <TabContext value={selectedTab}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Personal" value="1" sx={tabLabel} />
                </TabList>
                <TabPanel value="1">
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      marginBottom: "40px",
                      marginTop: "20px",
                      borderBottom: "1px solid #D2D1D1",
                    }}
                  >
                    <Grid item lg={6}>
                      <Typography sx={ticketDetailsHeaderStyles}>
                        Ticket Details
                      </Typography>
                    </Grid>
                    <Grid item lg={6}></Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "40px" }}>
                    <Grid item lg={6}>
                      <Typography sx={ticketDetailsStyles}>
                        Ticket Number
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        Shareholder Name
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>Email</Typography>
                      <Typography sx={ticketDetailsStyles}>Phone</Typography>
                      <Typography sx={ticketDetailsStyles}>Address</Typography>
                      <Typography sx={ticketDetailsStyles}>Category</Typography>
                      <Typography sx={ticketDetailsStyles}>Tin</Typography>
                      <Typography sx={ticketDetailsStyles}>
                        Shareholder COM Number
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        Shareholder SCD Number
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        Broker Code
                      </Typography>
                    </Grid>
                    <Grid item lg={6}>
                      <Typography sx={ticketDetailsStyles}>
                        {ticketState.ticket.ticket_number
                          ? ticketState.ticket.ticket_number
                          : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.shareholder_name
                          ? content.shareholder_name
                          : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.primary_email ? content.primary_email : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.primary_phone_number
                          ? content.primary_phone_number
                          : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.primary_address
                          ? content.primary_address
                          : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.category ? content.category : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.tin ? content.tin : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.shareholder_com_number
                          ? content.shareholder_com_number
                          : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.shareholder_scd_number
                          ? content.shareholder_scd_number
                          : "null"}
                      </Typography>
                      <Typography sx={ticketDetailsStyles}>
                        {content.broker_code ? content.broker_code : "null"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6}></Grid>
                    <Grid item lg={6}>
                      <Button
                        disabled={
                          ticketState.ticket.workflow_status === "APPROVED" ||
                          ticketState.ticket.workflow_status === "REJECTED" ||
                          sessionStorage.getItem("can_update") === "false"
                        }
                        variant="contained"
                        sx={{
                          width: "90px",
                          height: "40px",
                          backgroundColor: "#052A6C",
                          borderRadius: "8px",
                          color: "#FFFFFF",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "16px",
                          ":hover": {
                            backgroundColor: "#052A6C",
                            boxShadow: "none",
                          },
                        }}
                        onClick={() => {
                          navigate("/administrator-edit-tickets-details", {
                            replace: true,
                          });
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2"> </TabPanel>
                <TabPanel value="3"> </TabPanel>
                <TabPanel value="4"> </TabPanel>
              </TabContext>
            </CardContent>
          </Card>
    </MiniDrawer>
  )
};

export default AdministratorTicketsDetailsPage;
