import { Box, Chip, Tooltip } from "@mui/material";
import React from "react";

const DividendsStatus = ({ params }) => {
  return (
    <Box>
      {params.row.payment_status === "PENDING" ? (
        <Tooltip title="">
          <Chip
            label={params.row.payment_status}
            fontSize="small"
            sx={{ backgroundColor: "#FFE4BB", color: "#FF9E0C" }}
          />
        </Tooltip>
      ) : params.row.payment_status === "PAID" ? (
        <Tooltip title="">
          <Chip
            label={params.row.payment_status}
            fontSize="small"
            sx={{ backgroundColor: "#ACE4CF", color: "#01422A" }}
          />
        </Tooltip>
      ) : (
        <Tooltip title="">
          <Chip
            label={params.row.payment_status}
            fontSize="small"
            sx={{ backgroundColor: "#FEA2A2", color: "#D0021B" }}
          />
        </Tooltip>
      )}
    </Box>
  );
};

export default DividendsStatus;
