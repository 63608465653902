import React from 'react';
import ShareholderWrapper from './ShareholderWrapper';
import { Stack, Typography } from '@mui/material';

function ShareholderLoginGuide() {
  return (
    <ShareholderWrapper title="Shareholder Login Guide" sidebar={false}>
      <Stack
        direction="column"
        sx={{
          justifyContent: 'space-between',
          background: 'linear-gradient(to bottom,#052A6C 30%, #F5F5F5 30%)',
          margin: 0,
          padding: {
            xl: '4em 18em',
            lg: '4em 8em',
            md: '2em 1em',
            sm: '2em 1em',
            xs: '1em',
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: {
              md: 32,
              sm: 20,
            },
            color: 'white',
          }}
        >
          How to Login into your Account
        </Typography>
        <Stack direction="column" sx={{ bgcolor: 'white', borderRadius: 4 }}>
          <Stack direction="row">
            <img
              src={require('../../assets/modified-images/email-ka-header.png')}
              alt="lady with a phone"
              style={{ width: '100%', borderRadius: '8px 8px 0 0' }}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              pl: {
                md: 10,
                sm: 10,
                xs: 4,
              },
              pr: {
                md: 10,
                sm: 10,
                xs: 4,
              },
              pt: {
                md: 4,
                sm: 2,
                xs: 2,
              },
              pb: {
                md: 20,
                sm: 10,
                xs: 10,
              },
            }}
          >
            <Stack direction="column" sx={{ mt: 4 }}>
              <Typography sx={{ fontSize: 28, fontWeight: 500, pb: 4 }}>
                Do I have an account?
              </Typography>
              <Typography
                sx={{ fontSize: 20, fontWeight: 400, textAlign: 'justify' }}
              >
                Every shareholder has an account by default.
              </Typography>
            </Stack>
            <Stack direction="column" sx={{ mt: 4 }}>
              <Typography sx={{ fontSize: 28, fontWeight: 500, pb: 4 }}>
                First time access?
              </Typography>
              <Typography
                sx={{ fontSize: 20, fontWeight: 400, textAlign: 'justify' }}
              >
                <ol>
                  <li>Contact your registrar on <strong>+256-757 072773 | +256-760 451945 | +256-414 237504</strong></li>
                  <li>Or by email on <a href="mailto:shareholder@candrgroup.co.ug?body=First%20line%0Asecond%20line%0A%0ANew%20paragraph">shareholder@candrgroup.co.ug</a> to validate and activate your account</li>
                  <li>Then you will receive an activation link on the email you register with your registrar from the email <strong>no-reply@flyhub.co.ug</strong></li>
                  <li>Follow the link and you'll be required to change your password</li>
                  <li>Submit to activate your account</li>
                  <li>Select Login from the top right corner of the top navigation bar</li>
                  {/* <li>You will then be able to view a login page.</li> */}
                  <li>Enter your email and the new password you've set up</li>
                  <li>On successful login, you will be able to view your personal details.</li>
                </ol>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ShareholderWrapper>
  );
}

export default ShareholderLoginGuide;
