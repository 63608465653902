import React, { Fragment, useEffect, useState } from 'react';
import './ShareholderLandingPage.css';
// import Button from 'react-bootstrap/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ShareholderWrapper from './ShareholderWrapper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import {
  Alert,
  FormControl,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ErrorOutline } from '@mui/icons-material';
import { UpdateButton } from '../../components/styled/buttons';
import {
  getAllPayModes,
  getAllPaymentModes,
} from '../../service/settingsService';
import shareholderService from '../../service/officer/shareholderService';
import CircularProgress from '@mui/material/CircularProgress';
import AlertModal from '../../components/alertModal';
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from '../../store/alertSlice';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => {
  return {
    pageContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '1.6em 0',
    },
    tabContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 40px',
    },
    activityContainer: {
      display: 'flex',
      alignItems: 'space-between',
      flexDirection: 'column',
    },
    detailsMajorContainer: {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      position: 'relative',
    },
    detailsMainContainer: {
      backgroundColor: '#FFFFFF',
      display: 'flex',
      minHeight: '80%',
      flexDirection: 'column',
      '& .minorContainer': {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 40px',
        alignItems: 'center',
      },
    },
    userIconContainer: {
      backgroundColor: '#0033A1',
      borderRadius: '50%',
      position: 'absolute',
      bottom: -40,
      left: 40,
    },
    detailsGrid: {
      '& .gridList': {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 0.5,
        marginBottom: 2,
        '& .title': {
          color: '#000',
          fontWeight: 'bold',
          fontSize: '12px',
        },
        '& .value': {
          color: '#444A4A',
          fontWeight: 300,
          fontSize: '16px',
        },
      },
    },
  };
});

const otherStyles = {
  tabStyles: {
    color: '#5F6868',
    textTransform: 'capitalize',
    fontSize: {
      sm: 12,
      md: 16,
    },
    fontWeight: {
      sm: '300',
      md: '500',
    },
    marginRight: {
      lg: 10,
      sm: 0,
      xs: 0,
    },
  },
  icon: {
    fontSize: '100px',
    color: 'white',
  },
  tabListDividerLine: {
    borderBottom: '1.4px solid #90969DED',
  },
};

const StyledTypography = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
}));

const ShareholderEditOfficerProfile = () => {
  const { icon } = otherStyles;
  const classes = useStyles();
  const [walletList, setWalletList] = React.useState([]);
  const [bankList, setBankList] = React.useState([]);
  const [paymentType, setPaymentType] = React.useState('');
  const [shareholderData, setShareholderData] = React.useState({});
  const [isSubmittting, setIsSubmittting] = useState(false);

  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchShareholderByShareholderCode().then(() => loadAllPaymentModes());
  }, []);

  /**
   *@description Handle all changes happening in the form
   */
  const handleAutocompleteChange = (e) => {
    const { name, title } = e.target;
    setShareholderData({
      ...shareholderData,
      [name]: title,
    });
  };

  /**
   *@description Handle all changes happening in the form
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShareholderData({
      ...shareholderData,
      [name]: value,
    });
  };

  /**
   *@description Fetch shareholder data
   */
  const fetchShareholderByShareholderCode = async () => {
    try {
      const response = await shareholderService.findShareholderDetails({
        shareholderCode: searchParams.get('shareholder_code'),
      });

      const { data: info } = response.data;
      const newShareholderData = {
        ...info,
        payment_mode_code: info?.payment_mode?.payment_mode_code,
      };
      setShareholderData(newShareholderData);
    } catch (error) {
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmittting(true);
    shareholderService.updateShareholderDetails({
      shareholderCode: searchParams.get('shareholder_code'),
      data: shareholderData}).then((response) => {
        if (response?.data?.operation_code === 1) {
          dispatch(setAlertTitle("ERROR!"));
          dispatch(setAlertMessage(response.data.message));
          dispatch(openAlert());
        } else {
          dispatch(setAlertTitle("Success"));
          dispatch( setAlertMessage(response.data.message + " created"));
          navigate("/requests");    
        }
        setIsSubmittting(false);          
      })

  }

  /**
   *@description fetch all payment modes, load them according to their types (WALLET, BANK)
   *  */
  const loadAllPaymentModes = async () => {
    try {
      const bankPaymentMode = [];
      const walletPaymentMode = [];
      // let allModes = await getAllPaymentModes();
      let allModes = await getAllPayModes({size: 240, pageIndex: 0});
      
      const { data: paymentModes } = allModes.data;

      paymentModes?.forEach((mode) => {
        if (mode.payment_type === 'WALLET') {
          walletPaymentMode.push({
            payment_mode_code: mode.payment_mode_code,
            label: mode.payment_mode_name,
          });
        } else if (mode.payment_type === 'BANK') {
          bankPaymentMode.push({
            payment_mode_code: mode.payment_mode_code,
            label: mode.payment_mode_name,
          });
        }
      });

      setWalletList(walletPaymentMode);
      setBankList(bankPaymentMode);
    } catch (error) {
    }
  };

  return (
    <Fragment>
      <ShareholderWrapper title="Shareholder Edit Officer">
        {/* Alert modal for dividends claim */}
        {alert.open === true ? (
          <AlertModal
            sx={{
              margin: '20px',
              width: '50%',
              align: 'right',
            }}
          ></AlertModal>
        ) : (
          <></>
        )}
        {/* End of Alert modal for dividends claim */}
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              lg: 'row',
            },
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: '1.6em 0',
              flex: 1,
            }}
          >
            <Grid item xs={12} className={classes.detailsMajorContainer}>
              <img
                src={require('../../assets/modified-images/header-mobile.png')}
                alt="shareholder cover"
                style={{ width: '100%' }}
              />
              <div className={classes.userIconContainer}>
                <AccountCircleIcon sx={{ ...icon }} />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column">
              <Alert
                icon={
                  <ErrorOutline
                    fontSize="inherit"
                    sx={{
                      color: '#065AC2',
                    }}
                  />
                }
                severity="error"
                onClose={() => {}}
                sx={{ bgcolor: '#D9E5FE', color: '#065AC2' }}
              >
                The Information you update here will first be approved by SHUL
                before it reflects on your profile
              </Alert>
              <form onSubmit={handleSubmit} onChange={handleChange}>
                <Stack direction="column">
                  <Grid
                    container
                    xs={12}
                    sx={{ borderBottom: '1.4px solid #BABBBC', mt: 4 }}
                  >
                    PERSONAL DETAILS
                  </Grid>
                  <Grid container xs={12} sx={{ mt: 4 }}>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        variant="standard"
                        sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <StyledTypography>Name</StyledTypography>
                        <TextField
                          value={shareholderData.shareholder_name}
                          id="outlined-basic"
                          required
                          name="shareholder_name"
                          variant="outlined"
                          label="_"
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        variant="standard"
                        sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <StyledTypography>Email</StyledTypography>
                        <TextField
                          id="outlined-basic"
                          value={shareholderData.primary_email}
                          required
                          name="primary_email"
                          variant="outlined"
                          label="_"
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        variant="standard"
                        sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <StyledTypography>Phone Number</StyledTypography>
                        <TextField
                          id="outlined-basic"
                          value={shareholderData.primary_phone_number}
                          required
                          name="primary_phone_number"
                          variant="outlined"
                          label="_"
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sx={{ borderBottom: '1.4px solid #BABBBC', mt: 4 }}
                  >
                    PAYMENT ACCOUNT DETAILS
                  </Grid>
                  <Grid container xs={12} component="form" sx={{ mt: 4 }}>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        variant="outlined"
                        sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <StyledTypography>
                          Preffered Payment Channel
                        </StyledTypography>
                        <Select
                          required
                          value={paymentType}
                          name="payment_mode_type"
                          onChange={(e) => setPaymentType(e.target.value)}
                          label="_"
                          size="small"
                        >
                          <MenuItem value="">
                            <em>Payment Channel</em>
                          </MenuItem>
                          <MenuItem value={'BANK'}>BANK</MenuItem>
                          <MenuItem value={'WALLET'}>WALLET</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {paymentType === 'WALLET' && (
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          variant="outlined"
                          sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                        >
                          <StyledTypography>Payment Name</StyledTypography>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            size="small"
                            onChange={(event, newObject) => {
                              event.target.name = 'payment_mode_code';
                              event.target.title = newObject?.payment_mode_code;
                              handleAutocompleteChange(event);
                            }}
                            options={walletList}
                            sx={{ width: 'auto' }}
                            renderInput={(params) => (
                              <TextField {...params} label="_" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {paymentType === 'BANK' && (
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          variant="outlined"
                          sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                        >
                          <StyledTypography>Payment Name</StyledTypography>
                          <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            onChange={(event, newObject) => {
                              event.target.name = 'payment_mode_code';
                              event.target.title = newObject?.payment_mode_code;
                              handleAutocompleteChange(event);
                            }}
                            options={bankList}
                            sx={{ width: 'auto' }}
                            renderInput={(params) => (
                              <TextField {...params} label="_" />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        variant="standard"
                        sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <StyledTypography>
                          Account/Phone Number
                        </StyledTypography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={shareholderData.payment_account_number}
                          required
                          name="payment_account_number"
                          label="_"
                          size="small"
                          fullWidth={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        variant="standard"
                        sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <StyledTypography>
                          Account Name
                        </StyledTypography>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={shareholderData.payment_account_name}
                          required
                          name="payment_account_name"
                          label="_"
                          size="small"
                          fullWidth={true}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl
                        variant="standard"
                        sx={{ width: '80%', mb: 2, padding: '0 4px 0 4px' }}
                      >
                        <UpdateButton
                          sx={{
                            width: 'auto',
                            height: 40,
                            bgcolor: isSubmittting ? 'grey' : '',
                          }}
                          type="submit"
                          disabled={isSubmittting}
                        >
                          {isSubmittting && (
                            <CircularProgress color="secondary" />
                          )}
                          {!isSubmittting && 'Request Update'}
                        </UpdateButton>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Stack>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </ShareholderWrapper>
    </Fragment>
  );
};

export default ShareholderEditOfficerProfile;
