import { AccountCircle, Wallet } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Link, useNavigate } from 'react-router-dom';
import Banner from '../../assets/modified-images/banner.jpg';
import ShareholderWrapper from './ShareholderWrapper';

const items = [
  {
    description:
      'Update your Shareholder Details From the Comfort Of your Home',
    image: Banner,
  },
];

const QuickAccessTypography = styled(Typography)(() => ({
  flex: 1,
  color: '#0A2240',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: 2,
  justifyContent: 'center',
  borderLeft: '1px solid #88898A',
}));

function ShareholderSUHLLandingPage() {
  const navigate = useNavigate();
  return (
    <ShareholderWrapper title="Shareholder SUHL Landing page" sidebar={false} >
      <Grid container>
        <Grid item xs={12}>
          <Carousel animation="slide" duration={800} indicators={false} sx={{
            height:{
            lg: 900,
            md: 700,
            sm: 500,
            xs: 250,
          }}}>
            {items.map((item, i) => (
              <ImageCarousel
                key={i}
                item={item}
                handleAction={() => navigate('/login')}
              />
            ))}
          </Carousel>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DirectionSection />
      </Grid>
      <Grid xs={12} sx={{ bgcolor: '#' }}>
        <WelcomeSection handleAction={() => navigate('/login')} />
      </Grid>
    </ShareholderWrapper>
  );
}

const CTAButton = ({ title = 'No action', onClick }) => (
  <Button
    sx={{
      bgcolor: '#0089FF',
      color: '#ffffff',
      width:{
        lg: '100%',
        md:  '100%',
        sm: '100%',
        xs: '80%'
      },
      height: 50,
      fontWeight: 700,
      fontSize: {
        lg:14,
        md:14,
        sm:14,
        xs:10
      },
      '&:hover': {
        bgcolor: '#0033A1',
        color: '#ffffff',
      },
    }}
    onClick={onClick}
  >
    {title}
  </Button>
);

function ImageCarousel({ item, handleAction }) {
  return (
    <Paper
      sx={{
        flex: 1,
        width: '100vw',
        position: 'relative',
        maxHeight: 600,        
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          left: '10%',
          width: {
            lg: '30%',
            md: '50%',
            sm: '60%',
            xs: '100vw',
          },
          // background: 'rgba(57, 57, 57, 0.5)'
        }}
      >
        <Typography
          sx={{
            // color: '#ffffff',
            color: '#0089FF',
            fontSize: {
              lg: 30,
              md: 26,
              sm: 20,
              xs: 16,
            },
            fontWeight: 'bold',
            // fontWeight: { md: 240, sm: 200, xs: 100 },
          }}
        >
          {item.description}
        </Typography>
        <CTAButton
          title="LOGIN TO UPDATE YOUR DETAILS"
          onClick={handleAction}
        />
      </Box>

      <img
        src={item.image}
        width="100%"
        alt={item.description}
      />
    </Paper>
  );
}

function DirectionSection() {
  return (
    <Paper
      sx={{
        height: 160,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Stack
        justifyContent="space-between"
        sx={{
          flex: 1,
          alignItems: {
            sm: 'center',
            xs: 'flex-start',
          },
          flexDirection: {
            sm: 'row',
            xs: 'column',
          },
        }}
      >
        <QuickAccessTypography sx={{ borderLeft: 'none' }}>
          <AccountCircle sx={{ fontSize: 50 }} />
          <Link to="/shareholder-login-guide" sx={{ textDecoration: 'none' }}>
            <Typography
              sx={{ fontSize: 16, color: '#0A2240', textDecoration: 'none' }}
            >
              How to Login into your account
            </Typography>
          </Link>
        </QuickAccessTypography>
        <QuickAccessTypography>
          <Wallet sx={{ fontSize: 50 }} />
          <Link to="/claim-dividends" sx={{ textDecoration: 'none' }}>
            <Typography
              sx={{ fontSize: 16, color: '#0A2240', textDecoration: 'none' }}
            >
              How to Claim for Unpaid Dividends
            </Typography>
          </Link>
        </QuickAccessTypography>
      </Stack>
    </Paper>
  );
}

function WelcomeSection({ handleAction }) {
  return (
    <Container disableGutters={true}>
      <Grid
        container
        sx={{
          height: 'auto',
          bgcolor: 'white',
          m: '3em 0 7em 0',
          padding: {
            sm: '3em 6em',
            xs: '3em 1em',
          },
          rowGap: 2,
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: 3,
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 26 }}>
            Welcome to the SUHL Shareholder Management Register
          </Typography>
          <Typography sx={{ fontWeight: 300, fontSize: 16 }}>
            As a shareholder, we understand how important it is for you to stay
            informed about your investments and to have easy access to
            information that can help you make informed decisions
          </Typography>
          <CTAButton title="LOGIN TO VIEW YOUR SHARES" onClick={handleAction} />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <img
            src={Banner}
            alt=""
            width="100%"
            style={{
              borderRadius: 2,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ShareholderSUHLLandingPage;
