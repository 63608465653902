import { Grid, Skeleton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BulkUploadApproveActions from "../../components/BulkUploadApproveActions/BulkUploadApproveActions";
import BulkUploadApproveStatus from "../../components/BulkUploadApproveActions/BulkUploadApproveStatus";
import DataTable from "../../components/tables/DataTable";
import NavigationContext from "../../store/NavigationContext";

import AlertModal from "../../components/alertModal";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import { checkAuthorities } from "../../service/securityService";
import { getFileUploads } from "../../service/fileService";
import FlyTable from "../../components/tables/FlyTable";

const AdministratorShareholderBulkUploadApprove = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const alert = useSelector((state) => state.alert);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [backPagination, setBackPagination] = useState({});
  const [fileUploads, setFileUploads] = useState([]);
  const headers = ['ID', 'Name', 'Entries', 'Loading', 'Validated', 'Approved', 'Create Date', 'Status', 'Actions']
  const actions = ['Approve','View', 'Delete']
  const searchFields = ['file_id']

  const rows = fileUploads;
  console.log('rows: ', rows);

  const bulkShareholderTableStyles = {
    marginTop: "20px",
    backgroundColor: "white",
  };

  const requiredResults = 200;

  useEffect(() => {
    checkAuthorities({
      can_create: "FILE_UPLOAD_MODULE:CAN_CREATE",
      can_read: "FILE_UPLOAD_MODULE:CAN_READ",
      can_update: "FILE_UPLOAD_MODULE:CAN_UPDATE",
      can_delete: "FILE_UPLOAD_MODULE:CAN_DELETE",
      can_export: "FILE_UPLOAD_MODULE:CAN_EXPORT",
      can_approve: "FILE_UPLOAD_MODULE:CAN_APPROVE",
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    console.log('Loading: ', loading);
    getFileUploads({ size: requiredResults, pageIndex: pageIndex }).then((response) => {
      console.log('File uploads response:', response.data);
      if (response.data.operation_code === 0) {
        setBackPagination({
          total_pages: response.data.total_pages,
          page_index: response.data.page_index,
          total_elements: response.data.total_elements,
          records_displayed: response.data.records_displayed
        })

        
        const files = response.data.data.map((element) => ({
          id: element.file_id,
          file_name: element.file_name,
          total_entry_count: element.total_entry_count.toString(),
          total_entries_validated: element.total_entries_validated.toString(),
          entries_validated: element.entries_validated.toString(),
          entries_loaded: element.entries_loaded.toString(),
          date: element.created_on,
          status: element.file_upload_status
        })) 

        const updatedFiles = [...fileUploads, ...files]

        setFileUploads(updatedFiles)
      } else setFileUploads([])
    });
    setLoading(false);
  }, [pageIndex]);

  return (
    <MiniDrawer>
      {loading ? 
          <Skeleton
            variant="rectangle"
            height={"80vh"}
            width={"80vw"}
          ></Skeleton> : 
          <FlyTable
            header='Files'
            data={fileUploads}
            headers={headers}
            actions={actions}
            searchFields={searchFields}
            backPagination={backPagination}
            handleNextOrPreviousPage={(page) => {
              console.log('Settings Card page: ', page);
              setPageIndex(pageIndex + 1)
            }}/>
      }
    </MiniDrawer>
  );
};

export default AdministratorShareholderBulkUploadApprove;
