
import { useContext, useState } from 'react'
import NavigationContext from '../../store/NavigationContext';
import RegStepper from '../../components/registrationStepper';
import { Card, CardContent, Typography } from '@mui/material'
import jwtDecode from 'jwt-decode';
import MiniDrawer from '../../components/newSideDrawer/miniSideDrawer';

function CreateAdministratorPage(props) {
  let decodedToken;
  if (sessionStorage.getItem('token')) {
    decodedToken = jwtDecode(sessionStorage.getItem("token"));    
  }
  const navigationContext = useContext(NavigationContext)
  const [authorized, setAuthorized] = useState(false);
  const { sidebar } = navigationContext;
  return (
    <MiniDrawer>
      <Card>
                 <CardContent>
                   <Typography variant='h6' >Add new administrator</Typography>
                   <RegStepper />
                 </CardContent>
               </Card>
    </MiniDrawer>
  )
}
export default CreateAdministratorPage