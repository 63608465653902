// import { TabPanel } from "@mui/lab";
import {
  Avatar, Box, Button, Card, CardContent, CardMedia, Divider, FormControl, FormControlLabel,
  FormGroup, FormLabel, Grid, InputLabel, MenuItem,
  Radio, RadioGroup, Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import shareholderBanner from "../assets/images/shareholderBanner.jpg";
import { checkAuthorities } from "../service/securityService";
import { updateShareholder } from "../service/userService";
import { openAlert, setAlertMessage, setAlertTitle } from "../store/alertSlice";
import { addTicket } from "../store/ticketSlice";
import { ShareholderInput, ShareholderSelect } from "./forms/FormComponents";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShareholderEditCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shareholder = useSelector((state) => state.user.dynamicUser);
  const [currentCountry,setCurrentCountry] = useState(shareholder?.country_tax_rate);
  const [selectedTaxRates, setSelectedTaxRates] = useState((((JSON.parse(sessionStorage.getItem("countries"))).filter(country => country.country_name === currentCountry.country_name))[0].tax_rates));
  const [updatedShareholder, setUpdatedShareholder] = useState({
    broker_code: shareholder?.broker_code,
    created_by: shareholder?.created_by,
    identification_number: shareholder?.identification_number,
    industry: shareholder?.industry,
    is_company: shareholder?.is_company,
    payment_account_name: shareholder?.payment_account_name,
    payment_account_number: shareholder?.payment_account_number,
    payment_branch_address: shareholder?.payment_branch_address,
    payment_branch_name: shareholder?.payment_branch_name,
    payment_mode_code: shareholder?.payment_mode?.payment_mode_code,
    primary_address: shareholder?.primary_address,
    primary_email: shareholder?.primary_email,
    primary_phone_number: shareholder?.primary_phone_number,
    secondary_address: shareholder?.secondary_address,
    secondary_email: shareholder?.secondary_email,
    secondary_phone_number: shareholder?.secondary_phone_number,
    certificate_number: shareholder?.certificate_number,
    shareholder_name: shareholder?.shareholder_name,
    shareholder_other_name: shareholder?.shareholder_other_name,
    shareholder_scd_number: shareholder?.shareholder_scd_number,
    shares_held: shareholder?.shares_held,
    tax_rate_code: shareholder?.country_tax_rate.tax_rate_code,
    tin: shareholder?.tin,
  });
  const [currentPayType,setCurrentPayType] = useState((((JSON.parse(sessionStorage.getItem("payModes"))).filter(mode => mode.payment_mode_code === updatedShareholder.payment_mode_code))[0].payment_type));
  
  const sessionCountries = sessionStorage.getItem("countries");
  const sessionPayModes = sessionStorage.getItem("payModes");
  const locale = useParams();

  const countries = JSON.parse(sessionCountries);
  const payModes = JSON.parse(sessionPayModes);
  let taxRates = [
    {
      tax_rate_code: "TAX_RATE_6503",
      description: "FOREIGNER-SHAREHOLDERS",
      tax_rate: 13,
    },
  ];




  useEffect(() => {
    checkAuthorities({
      can_create: "SHAREHOLDER_MODULE:CAN_CREATE",
      can_read: "SHAREHOLDER_MODULE:CAN_READ",
      can_update: "SHAREHOLDER_MODULE:CAN_UPDATE",
      can_delete: "SHAREHOLDER_MODULE:CAN_DELETE",
      can_export: "SHAREHOLDER_MODULE:CAN_EXPORT",
      can_approve: "SHAREHOLDER_MODULE:CAN_APPROVE",
    });
  }, []);

  const handlePayModeChange = (event) => {
    setCurrentPayType(event.target.value);
  };
  const handleSubmitChanges = () => {
    updateShareholder({id: locale.shareholder_code ,shareholder: updatedShareholder}).then(
      response => {
        if (response.data.operation_code === 0) {
          dispatch(addTicket(response.data.data));
          dispatch(setAlertTitle("Success"));
          dispatch(
            setAlertMessage(
              "Ticket created with Id: " + response.data.data.ticket_number
            )
          );          
        } else {
            dispatch(setAlertTitle("ERROR!"));
            dispatch(setAlertMessage(error.response.data.operation_description));
        }
        dispatch(openAlert());
        navigate("/tickets");
      }
    )
  };
  
  const handleCountrySelect = (country) => {
    let countryObj = {};
    setCurrentCountry(country);
    countryObj = countries?.filter(
      (countryToReturn) => countryToReturn.country_name === country
    );
    if (countryObj[0].tax_rates.length >= 1) {
      setSelectedTaxRates(countryObj[0].tax_rates)
    } else {
    }
  };
  return (
    <>
      <Card>
        <CardMedia
          component="img"
          height="180"
          image={shareholderBanner}
          alt="Shareholder Banner"
        ></CardMedia>
        <CardContent>
          <Avatar></Avatar>
          <Divider></Divider>
          <Grid container>
            <Grid item xs={12}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Shareholder Name</InputLabel>
                <ShareholderInput
                  value={updatedShareholder.shareholder_name}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      shareholder_name: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Email</InputLabel>                
                <ShareholderInput
                  value={updatedShareholder.primary_email}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      primary_email: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Secondary Email</InputLabel>                
                <ShareholderInput
                  value={updatedShareholder.secondary_email}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      secondary_email: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Phone Number</InputLabel>               
                <ShareholderInput
                  value={updatedShareholder.primary_phone_number}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      primary_phone_number: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Secondary Phone Number</InputLabel>             
                <ShareholderInput
                  value={updatedShareholder.secondary_phone_number}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      secondary_phone_number: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Address</InputLabel>             
                <ShareholderInput
                  value={updatedShareholder.primary_address}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      primary_address: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Secondary Address</InputLabel>            
                <ShareholderInput
                  value={updatedShareholder.secondary_address}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      secondary_address: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Shareholder Certificate Number</InputLabel>           
                <ShareholderInput
                  value={updatedShareholder.certificate_number}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      certificate_number: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Shareholder SCD Number</InputLabel>           
                <ShareholderInput
                  value={updatedShareholder.shareholder_scd_number}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      shareholder_scd_number: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Tax Identification Number (TIN)</InputLabel>          
                <ShareholderInput
                  value={updatedShareholder.tin}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      tin: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Broker Code</InputLabel>        
                <ShareholderInput
                  value={updatedShareholder.broker_code}
                  sx={{ width: "100%" }}
                  onChange={(e) => {
                    setUpdatedShareholder({
                      ...updatedShareholder,
                      broker_code: e.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ width: "100%", marginTop: 2 }}>
                <InputLabel
                  shrink
                  htmlFor="country-select"
                  sx={{ marginLeft: -1, color: "#000000" }}
                >
                  Country
                </InputLabel>
                <ShareholderSelect
                  required
                  name="countryTaxRate"
                  id="country-select"
                  onChange={(event) => {
                    handleCountrySelect(event.target.value);
                  }}
                  defaultValue={currentCountry.country_name}
                >
                  {countries?.map((country) => (
                    <MenuItem
                      key={country.iso_code}
                      value={country.country_name}
                    >
                      {country.country_name}
                    </MenuItem>
                  ))}
                </ShareholderSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
            <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <InputLabel
                    shrink
                    htmlFor="tax-rate-select"
                    sx={{ marginLeft: -1, color: "#000000" }}
                  >
                   Select Tax Rate 
                  </InputLabel>
                  <ShareholderSelect
                    required
                    name="taxRate"
                    id="tax-rate-select"
                    onChange={(e) => {
                      setUpdatedShareholder({
                        ...updatedShareholder,
                        tax_rate_code: e.target.value,
                      });
                    }}
                    defaultValue={updatedShareholder.tax_rate_code}
                  >
                    {selectedTaxRates?.length > 1 ? (
                      selectedTaxRates.map(rate => (
                        <MenuItem
                          key={rate.tax_rate_code}
                          value={rate.tax_rate_code}
                        >
                          {rate.description}
                        </MenuItem>
                      ))
                    ): (
                      <MenuItem>
                        'No Tax Rates'
                      </MenuItem>
                    )}
                  </ShareholderSelect>
                </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container>
            <Grid item xs={12}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Preferred Payment Channel
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={currentPayType}
                  onChange={handlePayModeChange}
                >
                  <FormControlLabel
                    value="BANK"
                    control={<Radio />}
                    label="Bank"
                  />
                  <FormControlLabel
                    value="WALLET"
                    control={<Radio />}
                    label="Wallet"
                  />
                  <FormControlLabel
                    value="CHEQUE"
                    control={<Radio disabled />}
                    label="Cheque (Nolonger available)"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {currentPayType === "BANK" || currentPayType === "CHEQUE" ? (
              <>
              {currentPayType === "CHEQUE" && <Grid item xs={12}>
                <Typography sx={{fontSize: '0.5rem', color: 'red'}}>Notice: cheque payments are not available anymore please update to bank or wallet</Typography>
              </Grid> }
              
              <Grid item xs={6}>
                <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <InputLabel
                    shrink
                    htmlFor="pay-mode-name-select"
                    sx={{ marginLeft: -1, color: "#000000" }}
                  >
                    Bank Name:{" "}
                  </InputLabel>
                  <ShareholderSelect
                    required
                    name="payModeName"
                    id="pay-mode-name-select"
                    onChange={(e) => {
                      handlePayModeNameSelect(e);
                      setUpdatedShareholder({
                        ...updatedShareholder,
                        payment_mode_code: e.target.value,
                      });
                    }}
                    defaultValue={shareholder.payment_mode.payment_mode_code}
                  >
              {(payModes.filter(paymode => paymode.payment_type === "BANK" || paymode.payment_type === "CHEQUE")).map(payMode => (
                <MenuItem key={payMode.payment_mode_code} value={payMode.payment_mode_code} disabled={payMode.payment_type === "CHEQUE"} >{payMode.payment_mode_name}</MenuItem>
              ))}
              </ShareholderSelect>
              </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="branch-name-txt"
                    sx={{
                      color: "#000000",
                    }}
                  >
                    Branch Name:
                  </InputLabel>
                  <ShareholderInput
                    required
                    id="branch-name-txt"
                    name="branchName"
                    onChange={(e) => {
                      setNewShareholder({
                        ...updatedShareholder,
                        payment_branch_name: e.target.value,
                      });
                    }}
                    value={updatedShareholder.payment_branch_name}
                    sx={{ width: "80%" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormGroup variant="standard" sx={{ width: "100%" }}>
                  <InputLabel shrink>Account Name</InputLabel> 
                    <ShareholderInput
                      value={updatedShareholder.payment_account_name}
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setUpdatedShareholder({
                          ...updatedShareholder,
                          payment_account_name: e.target.value,
                        });
                      }}
                    />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup variant="standard" sx={{ width: "100%" }}>
                  <InputLabel shrink>Account Number</InputLabel> 
                    <ShareholderInput
                      value={updatedShareholder.payment_account_number}
                      sx={{ width: "100%" }}
                      onChange={(e) => {
                        setUpdatedShareholder({
                          ...updatedShareholder,
                          payment_account_number: e.target.value,
                        });
                      }}
                    />
                </FormGroup>
              </Grid>
              </>
            ) : (
              <>
              <Grid item xs={6}>
                <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <InputLabel
                    shrink
                    htmlFor="pay-mode-name-select"
                    sx={{ marginLeft: -1, color: "#000000" }}
                  >
                    Bank Name:{" "}
                  </InputLabel>
                  <ShareholderSelect
                    required
                    name="payModeName"
                    id="pay-mode-name-select"
                    onChange={(e) => {
                      handlePayModeNameSelect(e);
                      setUpdatedShareholder({
                        ...updatedShareholder,
                        payment_mode_code: e.target.value,
                      });
                    }}
                    defaultValue={shareholder.payment_mode.payment_mode_code}
                  >
              {(payModes.filter(paymode => paymode.payment_type === "WALLET")).map(payMode => (
                <MenuItem key={payMode.payment_mode_code} value={payMode.payment_mode_code}>{payMode.payment_mode_name}</MenuItem>
              ))}
              </ShareholderSelect>
              </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="branch-name-txt"
                    sx={{
                      color: "#000000",
                    }}
                  >
                    Branch Name:
                  </InputLabel>
                  <ShareholderInput
                    required
                    id="branch-name-txt"
                    name="branchName"
                    onChange={(e) => {
                      setUpdatedShareholder({
                        ...updatedShareholder,
                        payment_branch_name: e.target.value,
                      });
                    }}
                    value={updatedShareholder.payment_branch_name}
                    sx={{ width: "80%" }}
                  />
                </FormControl>
              </Grid>
                <Grid item xs={6}>
                  <FormGroup variant="standard" sx={{ width: "100%" }}>
                    <InputLabel shrink>Account Name</InputLabel> 
                      <ShareholderInput
                        value={updatedShareholder.payment_account_name}
                        sx={{ width: "100%" }}
                        onChange={(e) => {
                          setUpdatedShareholder({
                            ...updatedShareholder,
                            payment_account_name: e.target.value,
                          });
                        }}
                      />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <FormGroup variant="standard" sx={{ width: "100%" }}>
                    <InputLabel shrink>Account Number</InputLabel> 
                      <ShareholderInput
                        value={updatedShareholder.payment_account_number}
                        sx={{ width: "100%" }}
                        onChange={(e) => {
                          setUpdatedShareholder({
                            ...updatedShareholder,
                            payment_account_number: e.target.value,
                          });
                        }}
                      />
                  </FormGroup>
                </Grid>
              </>
            )}
          </Grid>
          <Divider></Divider>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormGroup variant="standard" sx={{ width: "100%" }}>
                <InputLabel shrink>Shares</InputLabel>
                  <ShareholderInput
                    value={updatedShareholder.shares_held}
                    sx={{ width: "100%" }}
                    disabled
                    onChange={(e) => {
                      setUpdatedShareholder({
                        ...updatedShareholder,
                        shares_held: e.target.value,
                      });
                    }}
                  />
              </FormGroup>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              variant="contained"
              sx={{ backgroundColor: "#052A6C", height: 40 }}
              onClick={handleSubmitChanges}
              disabled={sessionStorage.getItem("can_update") === "false"}
            >
              Submit
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};
export default ShareholderEditCard;
