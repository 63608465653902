import { createTheme } from '@mui/material';
import '../font/newscycle-bold-webfont.woff';
// import jetBrainsMonoWoff2 from '../font/JetBrainsMono-Regular.woff2';
import bentonSansRegular from '../font/bentonsans-regular-webfont.woff2';
import { red } from '@mui/material/colors';

export const theme = createTheme({
  
  palette: {
    primary: {
      main: '#0033A1',
      light: '#052A6C',
    },
    secondary: {
      main: '#ACE4CF',
    },
    customColor: {
      main: '#3300A1',
      light: '#ffffff',
      basic: '#0A2240',
    },
  },
  typography: {
    fontFamily: 'bentonSans',
    fontSize: 12,
    h1: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          @font-face {
              font-family: 'bentonSans';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('bentonSans'), local('bentonSans-regular'), url(${bentonSansRegular}) format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          }
          `,
    },
  },
});
