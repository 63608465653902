import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../../service/helpers/timeFormatter";
import { checkAuthorities } from "../../service/securityService";
import { filterTickets, getAllTickets } from "../../service/ticketService";
import { setAlertMessage, setAlertTitle } from "../../store/alertSlice";
import { setBackendPagination } from "../../store/settingsSlice";
import {
  setCurrentTicket,
  setTicketBackendPagination,
  setTickets,
} from "../../store/ticketSlice";
import { setTotalDataElements, setTotalPages } from "../../store/userSlice";
import AlertModal from "../alertModal";
import { ShareholderSelect } from "../forms/FormComponents";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { count, page, rowsPerPage, onPageChange } = props;
  const ticketState = useSelector((state) => state.ticket);

  const ticketBackendPagination = ticketState.backendPagination;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    if (page >= Math.ceil(count / rowsPerPage) - 1) {
      getAllTickets({
        size: requiredResults,
        pageIndex: ticketBackendPagination.page_Index + 1,
      }).then((response) => {
        dispatch(setTickets(response.data.data));
        dispatch(setBackendPagination(response.data));
        dispatch(
          setTicketBackendPagination({
            page_Index: response.data.page_index,
            total_pages: response.data.total_pages,
            records_displayed: response.data.records_displayed,
            total_elements: response.data.total_elements,
          })
        );
      });
    }
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        // disabled={page >= Math.ceil(backendTotalElements / rowsPerPage) - 1}
        disabled={
          page >=
          Math.ceil(ticketBackendPagination.total_elements / rowsPerPage) - 1
        }
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        // disabled={page >= Math.ceil(ticketBackendPagination.total_elements / rowsPerPage)-1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
      </IconButton>
    </Box>
  );
};

const requiredResults = 50;

const ListTicketsTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [searching, setSearching] = useState(false);
  const [advanceSearching, setAdvanceSearching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [searchField, setSearchField] = useState("ticket_number");

  const alert = useSelector((state) => state.alert);
  const user = useSelector((state) => state.user);
  const ticket = useSelector((state) => state.ticket);
  const backendTotalElements = useSelector(
    (state) => state.ticket.backendPagination.total_elements
  );

  const tickets = ticket.tickets;
  const total_elements = user.total_elements;
  const headers = ["Ticket", "Requested on", "Requested by", "Status"];


  useEffect(() => {
    checkAuthorities({
      can_create: "TICKETS_MODULE:CAN_CREATE",
      can_read: "TICKETS_MODULE:CAN_READ",
      can_update: "TICKETS_MODULE:CAN_UPDATE",
      can_delete: "TICKETS_MODULE:CAN_DELETE",
      can_export: "TICKETS_MODULE:CAN_EXPORT",
      can_approve: "TICKETS_MODULE:CAN_APPROVE",
    });
    getAllTickets({ size: requiredResults, pageIndex: 0 }).then((response) => {
      dispatch(setTickets(response.data.data));
      dispatch(
        setTicketBackendPagination({
          page_Index: response.data.page_index,
          total_pages: response.data.total_pages,
          records_displayed: response.data.records_displayed,
          total_elements: response.data.total_elements,
        })
      );
      dispatch(setTotalPages(response.data.total_pages));
      dispatch(setTotalDataElements(response.data.total_elements));
      setLoading(false);
    });
  }, []);
  const fields = [
    "ticket_number",
    "created_on",
    "created_by",
    "workflow_status",
  ];

  const handleViewTicket = (ticket) => {
    dispatch(setCurrentTicket(ticket));
    if (sessionStorage.getItem("can_approve") === "true") {
      navigate(
        `/administrator-tickets-details-approve/${ticket?.ticket_number}`
      );
    } else {
      navigate(`/administrator-tickets-details/${ticket?.ticket_number}`, {
        replace: true,
      });
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tickets?.length) : 0;

  const handleChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const searchChange = (e) => {
    setSearching(true);
    if (
      searching &&
      tickets.filter((ticket) =>
        ticket.ticket_number.includes(e.target.value.toUpperCase())
      ).length <= 0
    ) {
      setAdvanceSearching(true);
    }
    setSearchTerm(e.target.value);
  };
  const makeAdvancedSearch = () => {
    setLoading(true);
    filterTickets({
      size: requiredResults,
      pageIndex: 0,
      data: { ticket_number: searchTerm },
    }).then((response) => {
      setSearchResults(response.data.data);
      dispatch(
        setTicketBackendPagination({
          page_Index: response.data.page_index,
          total_pages: response.data.total_pages,
          records_displayed: response.data.records_displayed,
          total_elements: response.data.total_elements,
        })
      );
      setLoading(false);
      dispatch(setAlertTitle("Success"));
      dispatch(setAlertMessage(response.data.message));
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={8}>
          {advanceSearching ? (
            <>
              <Typography variant="h1">
                {`${searchResults?.length} of ${backendTotalElements?.toLocaleString()} Search results`}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h1">
                {`${tickets?.length} of ${backendTotalElements?.toLocaleString()} Tickets`}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4} md={4}>
          <FormControl
            variant="standard"
            sx={{ width: "100%", marginBottom: "30px" }}
          >
            <BootstrapInput
              id="searchTxt"
              size="small"
              type="text"
              placeholder="Search"
              onChange={searchChange}
              sx={{ backgroundColor: "#FFFFFF" }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {searching && (
        <Grid container>
          <Grid item xs={6} md={4}>
            <Typography variant="h1">Advanced Search By</Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControl sx={{ width: "100%", marginTop: 2 }}>
                  <ShareholderSelect
                    required
                    name="fieldSelect"
                    id="field-select"
                    onChange={(event) => {
                      setSearchField(event.target.value);
                    }}
                    sx={{ height: "100%" }}
                    defaultValue={"ticket_number"}
                  >
                    {fields?.map((field) => (
                      <MenuItem key={field} value={field}>
                        {field}
                      </MenuItem>
                    ))}
                  </ShareholderSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  variant="standard"
                  sx={{ width: "100%", marginBottom: "30px" }}
                >
                  <BootstrapInput
                    id="shareholderNameSearch"
                    size="small"
                    type="text"
                    placeholder="shareholder name"
                    value={searchTerm}
                    sx={{ backgroundColor: "#FFFFFF" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#AA0050",
                    margin: "2% 1% 2% 0",
                    width: "100%",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#B150C5",
                    },
                    fontSize: 14,
                  }}
                  onClick={makeAdvancedSearch}
                >
                  {loading ? <CircularProgress /> : "Search"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {alert.open === true ? (
        <AlertModal
          sx={{ margin: "20px", width: "50%", align: "right" }}
        ></AlertModal>
      ) : (
        <></>
      )}

      <TableContainer component={Paper}>
        {loading ? (
          <Skeleton variant="rectangle" width="80Vw" height="60Vh" />
        ) : (
          <Table aria-label="custom pagination table" size="small">
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableCell
                    key={header}
                    align="left"
                    sx={{ fontWeight: "bold" }}
                  >
                    {header}
                  </TableCell>
                ))}
                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <>
              {advanceSearching ? (
                <TableBody>
                  {searchResults
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((ticket) => (
                      <TableRow key={ticket.ticket_number}>
                        <TableCell component="th">
                          {ticket.ticket_number}
                        </TableCell>
                        <TableCell align="left">
                          {formatTime(ticket.created_on)}
                        </TableCell>
                        <TableCell align="left">{ticket.created_by}</TableCell>
                        <TableCell align="left">
                          {ticket.workflow_status}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="View ticket">
                            <IconButton
                              disabled={
                                sessionStorage.getItem("can_read") === "false"
                              }
                              onClick={() => {
                                handleViewTicket(ticket);
                              }}
                              style={{ color: green[500] }}
                            >
                              <RemoveRedEye fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  {tickets
                    ?.filter((val) => {
                      if (!searching) {
                        return val;
                      } else if (val.ticket_number?.includes(searchTerm)) {
                        return val;
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((ticket) => (
                      <TableRow key={ticket.ticket_number}>
                        <TableCell component="th">
                          {ticket.ticket_number}
                        </TableCell>
                        <TableCell align="left">
                          {formatTime(ticket.created_on)}
                        </TableCell>
                        <TableCell align="left">{ticket.created_by}</TableCell>
                        <TableCell align="left">
                          {ticket.workflow_status}
                        </TableCell>
                        <TableCell align="left">
                          <Tooltip title="View ticket">
                            <IconButton
                              disabled={
                                sessionStorage.getItem("can_read") === "false"
                              }
                              onClick={() => {
                                handleViewTicket(ticket);
                              }}
                              style={{ color: green[500] }}
                            >
                              <RemoveRedEye fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={5}
                  count={tickets.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  users={tickets}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  sx={{
                    "&  .MuiTablePagination-spacer": {
                      display: "none",
                    },
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </>
  );
};
export default ListTicketsTable;
