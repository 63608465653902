// import { TabPanel } from "@mui/lab";
import { Delete } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    IconButton,
    Skeleton,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Tooltip,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import shareholderBanner from "../assets/images/shareholderBanner.jpg";
import {
    getShareholderAccountOfficers,
    getShareholderById,
} from "../service/userService";
import { addShareholderCode, setDynamicUser, setShareholderCode } from "../store/userSlice";
import { checkAuthorities } from "../service/securityService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShareholderDetailsCard = () => {
  const [value, setValue] = useState(0);
  const [shareholder, setShareholder] = useState();
  const [accountOfficers, setAccountOfficers] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const locale = useParams();

  const fields = ["Full Name", "Phone", "Email"];

  useEffect(() => {
    checkAuthorities({
      can_create: "SHAREHOLDER_MODULE:CAN_CREATE",
      can_read: "SHAREHOLDER_MODULE:CAN_READ",
      can_update: "SHAREHOLDER_MODULE:CAN_UPDATE",
      can_delete: "SHAREHOLDER_MODULE:CAN_DELETE",
      can_export: "SHAREHOLDER_MODULE:CAN_EXPORT",
      can_approve: "SHAREHOLDER_MODULE:CAN_APPROVE",
    });
    getShareholderById(locale.shareholder_code).then((response) => {
      setShareholder(response);
    });
    getShareholderAccountOfficers(locale.shareholder_code).then((response) => {
    });
  }, []);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleAddAccountOfficer = () => {
    navigate("/create-account-officer");
  };
  if (!shareholder)
    return (
      <Skeleton variant="rectangular" width="100%" height="100%"></Skeleton>
    );
  return (
    <>
      <Card>
        <CardMedia
          component="img"
          height="180"
          image={shareholderBanner}
          alt="Shareholder Banner"
        ></CardMedia>
        <CardContent>
          <Avatar></Avatar>
          <Typography>{shareholder.shareholder_name}</Typography>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Personal" {...a11yProps(0)} />
              <Tab label="Payment Account" {...a11yProps(1)} />
              <Tab label="Shares" {...a11yProps(2)} />
              <Tab label="A/C Officers" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Email:</strong>
                        </Typography>
                        <Typography fontSize="small" sx={{ marginRight: 2 }}>
                          {shareholder?.primary_email?.toLowerCase()}
                        </Typography>
                      </Grid>
                      {shareholder.hasOwnProperty("secondary_email") &&
                        shareholder.secondary_email != null && (
                          <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                            <Typography fontSize="small">
                              <strong>Secondary Email:</strong>
                            </Typography>
                            <Typography
                              fontSize="small"
                              sx={{ marginRight: 2 }}
                            >
                              {shareholder?.secondary_email?.toLowerCase()}
                            </Typography>
                          </Grid>
                        )}
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Phone:</strong>
                        </Typography>
                        <Typography fontSize="small">
                          {shareholder.primary_phone_number}
                        </Typography>
                      </Grid>
                      {shareholder.hasOwnProperty("secondary_phone_number") &&
                        shareholder.secondary_phone_number != null && (
                          <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                            <Typography fontSize="small">
                              <strong>Secondary Phone:</strong>
                            </Typography>
                            <Typography
                              fontSize="small"
                              sx={{ marginRight: 2 }}
                            >
                              {shareholder.secondary_phone_number}
                            </Typography>
                          </Grid>
                        )}
                      {shareholder.hasOwnProperty("primary_address") &&
                        shareholder.primary_address != null && (
                          <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                            <Typography fontSize="small">
                              <strong>Address:</strong>
                            </Typography>
                            <Typography
                              fontSize="small"
                              sx={{ marginRight: 2 }}
                            >
                              {shareholder?.primary_address?.toLowerCase()}
                            </Typography>
                          </Grid>
                        )}
                      {shareholder.hasOwnProperty("secondary_address") &&
                        shareholder.secondary_address != null && (
                          <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                            <Typography fontSize="small">
                              <strong>Secondary Address:</strong>
                            </Typography>
                            <Typography
                              fontSize="small"
                              sx={{ marginRight: 2 }}
                            >
                              {shareholder?.secondary_address?.toLowerCase()}
                            </Typography>
                          </Grid>
                        )}
                      {shareholder?.hasOwnProperty("shareholder_com_number") &&
                        shareholder.shareholder_com_number != null && (
                          <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                            <Typography fontSize="small">
                              <strong>Shareholder COM Number:</strong>
                            </Typography>
                            <Typography fontSize="small">
                              {shareholder.shareholder_com_number}
                            </Typography>
                          </Grid>
                        )}
                      {shareholder.hasOwnProperty("shareholder_scd_number") &&
                        shareholder.shareholder_scd_number != null && (
                          <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                            <Typography fontSize="small">
                              <strong>Shareholder SCD Number:</strong>
                            </Typography>
                            <Typography fontSize="small">
                              {shareholder?.shareholder_scd_number?.toLowerCase()}
                            </Typography>
                          </Grid>
                        )}
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Tax Identification Number:</strong>
                        </Typography>
                        <Typography fontSize="small">
                          {shareholder.tin}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={sessionStorage.getItem("can_update") === 'false'}
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/admin-shareholder-edit/${locale.shareholder_code}`,
                          {
                            replace: true,
                          }
                        )
                      }
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Wallet Name:</strong>
                        </Typography>
                        <Typography fontSize="small">
                          {shareholder.payment_mode.payment_mode_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Account Name:</strong>
                        </Typography>
                        <Typography fontSize="small">
                          {shareholder.payment_account_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Account Number:</strong>
                        </Typography>
                        <Typography fontSize="small">
                          {shareholder.payment_account_number}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/admin-shareholder-edit/${locale.shareholder_code}`,
                          {
                            replace: true,
                          }
                        )
                      }
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container>
                      <Grid item xs={12} md={6} sx={{ paddingBottom: 2 }}>
                        <Typography fontSize="small">
                          <strong>Shares:</strong>
                        </Typography>
                        <Typography fontSize="small">
                          {shareholder.shares_held.toLocaleString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Table>
              <TableHead>
                <TableRow>
                  {fields.map((field) => (
                    <TableCell key={field}>
                      <strong>{field}</strong>
                    </TableCell>
                  ))}
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={handleAddAccountOfficer}
                      sx={{ backgroundColor: "#052A6C" }}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountOfficers?.length > 0 ? (
                  accountOfficers.map((officer) => (
                    <TableRow key={officer.officer_code}>
                      <TableCell>
                        {officer.first_name + " " + officer.last_name}
                      </TableCell>
                      <TableCell>{officer.primary_address}</TableCell>
                      <TableCell>{officer.primary_email}</TableCell>
                      <TableCell>
                        <Tooltip title="Remove account officer">
                          <IconButton>
                            <Delete fontSize="small" color="error" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TabPanel>
        </CardContent>
      </Card>
    </>
  );
};
export default ShareholderDetailsCard;
