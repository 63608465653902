import React, { useCallback, useEffect, useState } from 'react';
import ShareholderWrapper from './ShareholderWrapper';
import CoverImage from '../../assets/notifications-image.png';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useParams } from 'react-router-dom';
import notificationService from '../../service/officer/notificationService';

const StyledDiv = styled('div')(() => ({
  width: '100%',
  height: 'auto',
  backgroundPosition: 'top right',
  backgroundImage: `url(${CoverImage})`,
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  backgroundSize: '100%',
}));

function ShareholderNotificationDividends() {
  const [notification, setNotification] = useState({});
  const { notificationId } = useParams();

  const setNotificationToRead = useCallback(
    async () =>
      await notificationService.updateNotifications({ notificationId }),
    [notificationId]
  );

  const fetchSingleNotificationDetails = useCallback(async () => {
    const response = await notificationService.findANotification({
      notificationId,
    });
    setNotification(response.data.data);
  }, [notificationId]);

  useEffect(() => {
    setNotificationToRead();
    fetchSingleNotificationDetails();
  }, [setNotificationToRead, fetchSingleNotificationDetails]);

  return (
    <ShareholderWrapper>
      <Box
        sx={{
          bgcolor: '#ffffff',
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          borderRadius: 2,
          minHeight: '80%',
        }}
      >
        {/* <SkewedContainer></SkewedContainer> */}
        <StyledDiv>
          <Typography
            sx={{
              color: '#0A2240',
              fontSize: {
                lg: 28,
                md: 20,
                sm: 14,
              },
              fontWeight: {
                lg: 800,
                md: 600,
                sm: 400,
              },
            }}
          >
            CLAIM FOR UNPAID DIVIDENDS
          </Typography>
        </StyledDiv>
        <Stack
          direction="column"
          sx={{
            flex: {
              xs: 1,
              sm: 1,
              md: 0.2,
              lg: 0,
            },
            padding: '0 30px',
            textAlign: 'justify',
          }}
        >
          <Typography
            sx={{
              mb: 2,
            }}
          >
            Hi <b>{notification && (notification?.created_by ?? '')},</b>
          </Typography>
          <Typography
            sx={{
              mb: 2,
            }}
          >
            {notification && (notification?.body ?? '')}
          </Typography>
          <Typography
            sx={{
              mb: 2,
            }}
          >
            Kind Regards.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-start"
          sx={{ padding: '0 30px', mb: 2 }}
        >
          <Link to="/notifications">
            <Button
              variant="text"
              sx={{ textTransform: 'capitalize' }}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Link>
        </Stack>
      </Box>
    </ShareholderWrapper>
  );
}

export default ShareholderNotificationDividends;
