import React from "react";
import { Col, Row } from "react-bootstrap";
import ShareholderNav from "../../components/ShareholderNav";
import ShareholderSidebar from "../../components/ShareholderSidebar";
import "./ShareholderPayoutsDetailsPage.css";
import Footer from "../../components/Footer";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Icon from "@mdi/react";
import { mdiCalendarRangeOutline } from "@mdi/js";
import { mdiAccount  } from "@mdi/js";

const ShareholderPayoutsDetailsPage = () => {
  return (
    <div>
      <ShareholderNav />
      <Row style={{ paddingTop: "2%" }}>
        <Col sm={2} md={2} lg={2}>
          <div className="container">
            <ShareholderSidebar />
          </div>
        </Col>
        <Col sm={9} md={9} lg={9}>
          <div className="container ">
            <Card className="requests-details-card">
              <Card.Body>
                <Row className="requests-details-row2">
                  <Col sm={6} md={6} lg={6}>
                    <Card.Title>
                      <p className="payouts-details-title4">
                        Dividends Payout for 2021/2022
                      </p>
                    </Card.Title>
                    <Card.Title>
                      <p className="payouts-details-title">
                        UGX 887,000
                        <Button
                          className="payouts-button"
                          disabled
                          style={{
                            color: "#0033a1",
                            backgroundColor: "#D8E4FF",
                          }}
                        >
                          For 5000 Shares
                        </Button>
                      </p>
                    </Card.Title>
                  </Col>
                  <Col sm={6} md={6} lg={6}>
                    <Row>
                      <Col sm={6} md={6} lg={6}>
                        <Card.Title>
                          <p className="payouts-details-title2">
                            <Icon
                              className="me-2"
                              path={mdiCalendarRangeOutline}
                              title="User Profile"
                              size="20px"
                              color="#0A2240"
                            />
                            Paid On
                          </p>
                        </Card.Title>
                      </Col>
                      <Col sm={6} md={6} lg={6}>
                        <p className="payouts-details-title3">17th June, 2022</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6} md={6} lg={6}>
                        <Card.Title>
                          <p className="payouts-details-title2">
                            <Icon
                              className="me-2"
                              path={mdiAccount }
                              title="User Profile"
                              size="20px"
                              color="#0A2240"
                            />
                            Paid Via
                          </p>
                        </Card.Title>
                      </Col>
                      <Col sm={6} md={6} lg={6}>
                        <p className="payouts-details-title3">Mobile Money</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="payouts-details-row1">
                  <Col className="requests-details-first-column">
                    <p className="payouts-old-details">PAYMENT DETAILS</p>
                    <p className="payouts-old-details-name">Transaction ID</p>
                    <p className="payouts-old-details-name2">10037476238</p>
                  </Col>
                  <Col className="requests-details-second-column">
                    <Row>
                      <Col></Col>
                      <Col>
                        <p style={{ color: "white" }}> k</p>
                        <p className="payouts-old-details-country">
                          Account Paid to
                        </p>
                        <p className="payouts-old-details-country2">
                          0772757623
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="requests-details-row2">
                  <div className="row">
                    <div
                      className="table-responsive "
                      style={{ marginBottom: "10%" }}
                    >
                      <div>
                        <p className="payouts-table-heading">
                          AMOUNT CASHED OUT
                        </p>
                      </div>
                      {/* <table className="table table-hover table-bordered"> */}
                      <table className="table table-hover table-bordered">
                        <tbody className="table-body">
                          <tr>
                            <td className="payouts-table1">Gross Amount</td>
                            <td className="payouts-table3">UGX</td>
                            <td className="payouts-table2">930,000</td>
                          </tr>
                          <tr>
                            <td className="payouts-table1">Tax</td>
                            <td className="payouts-table3">UGX</td>
                            <td className="payouts-table2">37,000</td>
                          </tr>

                          <tr>
                            <td className="payouts-table1">
                              Net Amount (Amount on the account)
                            </td>
                            <td className="payouts-table3">UGX</td>
                            <td className="payouts-table2">887,000</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default ShareholderPayoutsDetailsPage;
