import {  FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, RemoveRedEye, Report } from "@mui/icons-material";
import { Box,  IconButton, InputBase,  Paper,
        Skeleton, Table, TableBody, TableCell, TableContainer,  TableHead, TablePagination,
        TableRow, Tooltip, alpha, styled, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
  
import { green, } from "@mui/material/colors";
import { getFileEntriesByFileId } from "../service/fileService";
import { setViolations } from "../store/fileSlice";
import FlyTable from "./tables/FlyTable";
  
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const adminState = useSelector((state) => state.administrator);
    const backendPagination = adminState.backendPagination;
    const backendPageIndex = adminState.page_Index;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      if (page >= Math.ceil(count / rowsPerPage) - 1) {
        getAllAdministrators({ size: 20, pageIndex: backendPageIndex + 1 }).then(
          (response) => {
          }
        );
      }
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={
            page >= Math.ceil(backendPagination.total_elements / rowsPerPage) - 1
          }
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    );
  };
  
  const requiredResults = 20;
  
  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }));
  
  function FileEntries() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const locale = useParams();
    const [entries, setEntries] = useState([]);

    // const [fileEntries, setFileEntries] = useState([]);
    // const [paginationDetails, setPaginationDetails] = useState({});
    // const headers = ['SCD/COM Number', 'Shareholder Name', 'Email', 'Shares', 'pay code', 'tax code', 'Status', 'Actions']
    // const actions = ['View']
  
    const headers = [
      "SCD/COM Number",
      "Shareholder Name",
      "Email",
      "Phone Number",
      "Shares",
    ];
  
    useEffect(() => {
      setLoading(true);
    getFileEntriesByFileId({ fileId:locale.file_id, size: requiredResults, pageIndex: 0 }).then(
        (response) => {
          console.log('Our file entries in components/FileEntries.js: ', response);
          setEntries(response.data.data)
          // setFileEntries(response.data?.data?.map((element) => ({
          //   id: element.content.shareholder_scd_number ? element.content.shareholder_scd_number : element.content.certificate_number,
          //   shareholder_name: element.content.shareholder_name,
          //   primary_email: element.content.primary_email,
          //   shares: element.content.shares_held.toString(),
          //   pay_code: element.content.payment_mode_code,
          //   tax_code: element.content.tax_rate_code,
          //   violations: element.content.violations,
          //   status: element.file_upload_status
          // })))
          setLoading(false);
        }
      );
    }, []);
    
  
    const viewViolations = (violations) => {
      console.log('Our violations in components/FileEntries.js: ', response);
      dispatch(setViolations(violations));
      navigate(`/file-entries/${locale.file_id}/violations`, {replace: true});
    }
  
    return (
      <>
      {/* <FlyTable
        header='File Entries'
        data={fileEntries}
        headers={headers}
        actions={actions}
        requiredResults={requiredResults}
        paginationDetails={paginationDetails} /> */}
        <TableContainer component={Paper}>
          {loading ? (
            <Skeleton
              variant="rectangle"
              height={"80vh"}
              width={"80vw"}
            ></Skeleton>
          ) : (
            <Table aria-label="custom pagination table" size="small">
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableCell
                      key={header}
                      align="left"
                      sx={{ fontWeight: "bold" }}
                    >
                      {header}
                    </TableCell>
                  ))}
                  <TableCell
                    align="center"
                    sx={{ fontWeight: "bold" }}
                    colSpan={3}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
  
              <>
                  <TableBody>
                    {entries.map((record) => (
                        <TableRow key={record.entry_id}>
                          <TableCell component="th">{record.content.shareholder_scd_number}</TableCell>
                          <TableCell align="left">{record.content.shareholder_name}</TableCell>
                          <TableCell align="left">{`${record.content.primary_email
                            .toLowerCase()
                            .substring(0, 15)}...`}</TableCell>
                          <TableCell align="left">
                            {record.content.primary_phone_number}
                          </TableCell>
                          <TableCell align="left">
                            {record.content.shares_held}
                          </TableCell>
  
                          <TableCell>
                            <Tooltip title="View violations">
                              <IconButton
                                onClick={() => {
                                  viewViolations(record.violations);
                                }}
                                sx={{ color: green[500] }}
                              >
                                <RemoveRedEye fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
              </>
            </Table>
          )}
        </TableContainer>
      </>
    );
  }
  export default FileEntries;
  