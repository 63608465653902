import {
  AccountCircle,
  Email,
  Lock,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  ToggleButton,
  Typography
} from '@mui/material';
import jwtDecode from 'jwt-decode';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import stanbicLogo from '../assets/images/logo.png';
import { getAppVersion } from '../service/monitoringService';
import { passOtp } from '../service/securityService';
import { forgotMyPassword, generateShareholderQrCode, loginUser, logoutShareholder } from '../service/userService';
import { openAlert, setAlertMessage, setAlertTitle } from '../store/alertSlice';
import { useAuth } from '../store/authContext';
import { setAuthExp, setMfaEnabled, setRfshExp } from '../store/securitySlice';
import useValidation from '../hooks/useValidate';
import { setShareholderQrCode } from "../store/userSlice";

const LoginCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [applicationVersion, setApplicationVersion] = useState({});
  const [myUsername, setMyUsername] = useState("");
  const [password, setPassword] = useState("");
  const mfaEnabled =useSelector(state => state.security.mfaEnabled);
  const [loginErrors, setLoginErrors] = useState('');
  const [networkError, setNetworkError] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailToReset, setEmailToReset] = useState('');
  const [loading, setLoading] = useState(false);
  const [enablingMFA, setEnablingMFA] = useState(false);
  const [showPassword, setShowPassword] = useState(false);  
  const { handleValidationCheck, values, errors } = useValidation();


  const { setUser } = useAuth();

  useEffect(() => {
    getAppVersion().then((response) => {
      if ("build" in response) {
        setApplicationVersion(response.build);
      } else {
        setNetworkError(response.message);
      }
    });
  }, []);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const handleClose = () => {
    dispatch(setMfaEnabled(false));
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const numericCheck = (text) => {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || isString) && !isNaN(Number(text));
  };

  const validateChar = (value, index) => {
    return numericCheck(value);
  };
  const handleComplete = (value) => {
    let OTP = { code: value };
    passOtp(OTP).then((response) => {
      setLoading(false);
      if (response.operation_code === 1) {
        setLoginErrors("Invalid code. Please input the correct code");
      } else {
        let decodedToken;
        let decodedRefreshToken;
        if (
          sessionStorage.getItem("refresh_token") &&
          sessionStorage.getItem("refresh_token") !== undefined
        ) {
          decodedRefreshToken = jwtDecode(
            sessionStorage.getItem("refresh_token")
          );
        }
        if (
          sessionStorage.getItem("token") &&
          sessionStorage.getItem("token") !== undefined
        ) {
          decodedToken = jwtDecode(sessionStorage.getItem("token"));
        }
        dispatch(setMfaEnabled(false));
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response.data.message));
        sessionStorage.setItem("is_authenticated", true);
        sessionStorage.setItem("token", response.headers.authorization);
        sessionStorage.setItem("refresh_token", response?.data?.refresh_token);
        decodedRefreshToken = jwtDecode(response?.data?.refresh_token);
        dispatch(setRfshExp(decodedRefreshToken.exp));
        dispatch(setAuthExp(decodedToken.exp));
        sessionStorage.setItem("username", decodedToken.sub);
        sessionStorage.setItem("userType", decodedToken.user_type);
        setUser({
          userType: decodedToken.user_type,
          is_authenticated: true,
        });
        if (decodedToken.user_type === "ADMINISTRATOR") {
          navigate("/administrator-dashboard", { replace: true });
        } else if (decodedToken.user_type === "ACCOUNT_OFFICER") {
          sessionStorage.setItem("officer_code", decodedToken?.id);
          sessionStorage.setItem(
            "shareholder_code",
            decodedToken?.shareholder_code
          );
          navigate(
            `/shareholder?officer_code=${decodedToken?.id}&shareholder_code=${decodedToken?.shareholder_code}`,
            { replace: true }
          );
        }
      }
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    let username = myUsername.replace(/\s/g, '');
    let loggingInUser = { username, password };
    loginUser(loggingInUser).then((response) => {
      let decodedToken;
      if (response.operation_code === 1) {
        setLoading(false);
        setLoginErrors(response?.message);
      } else {
        sessionStorage.setItem("token", response?.headers?.authorization);
        if (response.data?.mfa_enabled === true) {
          dispatch(setMfaEnabled(true));
        } else {
          decodedToken = jwtDecode(response.headers.authorization);
          if (decodedToken.user_type === "ADMINISTRATOR" && decodedToken.mfa_enabled === false) {
            setEnablingMFA(true);
            dispatch(setAlertTitle("Success"));
            dispatch(setAlertMessage(response.message));
            sessionStorage.setItem("is_authenticated", true);
            sessionStorage.setItem( "refresh_token", response?.data?.refresh_token );
            sessionStorage.setItem("username", decodedToken.sub);
            sessionStorage.setItem("userType", decodedToken.user_type);
            setUser({
              userType: decodedToken.user_type,
              is_authenticated: true,
            }); 
          } else {
            dispatch(setMfaEnabled(false));
            setLoading(false);
            dispatch(setAlertTitle("Success"));
            dispatch(setAlertMessage(response.message));
            sessionStorage.setItem("is_authenticated", true);
            sessionStorage.setItem( "refresh_token", response?.data?.refresh_token );
            sessionStorage.setItem("username", decodedToken.sub);
            sessionStorage.setItem("userType", decodedToken.user_type);
            setUser({
              userType: decodedToken.user_type,
              is_authenticated: true,
            });
            if (decodedToken.user_type === "ADMINISTRATOR") {
              navigate("/administrator-dashboard", { replace: true });
            } else if (decodedToken.user_type === "ACCOUNT_OFFICER") {
              sessionStorage.setItem("officer_code", decodedToken?.id);
              sessionStorage.setItem(
                "shareholder_code",
                decodedToken?.shareholder_code
              );
              navigate(
                `/shareholder?officer_code=${decodedToken?.id}&shareholder_code=${decodedToken?.shareholder_code}`,
                { replace: true }
              );
            }
          }}
      }
    });
  };

  const enableMFAForAdmin = () => {
    generateShareholderQrCode().then(response => {
      dispatch(setShareholderQrCode(response.data));
      navigate(
        `/administrator-mfa-setup`,
        { replace: true }
      );
    })

  }
  
  const logout = () => {
    logoutShareholder().then((response) => {
      sessionStorage.clear();
      setUser(null);
      navigate("/login");
    });
  };

  const otpInputStyle = {
    "& .MuiInputBase-input": {
      border: "1px solid #000",
      borderRadius: 1,
    },
  };
  const resetUserAccountCredentials = () => {
    forgotMyPassword(emailToReset).then((response) => {
      if (response?.data?.operation_code === 0) {
        dispatch(setAlertTitle("Success"));
        dispatch(setAlertMessage(response?.data?.message));
      }else{
        dispatch(setAlertTitle("Error"));
        dispatch(setAlertMessage(response?.message));
      }
      dispatch(openAlert());
      setForgotPassword(false);
    })
  }
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: 3,
          marginBottom: 2,
        }}
      >
        <img
          style={{
            height: "60px",
            borderRadius: "4px",
            paddingTop: "10px",
          }}
          src={stanbicLogo}
        />

        <Typography variant="h1" color={"#FFFFFF"}>
          Shareholder Management Register
        </Typography>
      </Grid>
      <Card>
        <CardContent
          onSubmit={handleLogin}
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
          }}
        >
          <Typography
            variant="h1"
            textAlign="center"
            sx={{
              color: "#000000",
              marginBottom: 4,
              marginTop: 2,
            }}
          >
            Please enter your credentials to login
          </Typography>
          {networkError && (
            <Typography color="red" fontSize="small">
              {networkError}
            </Typography>
          )}
          <FormControl
            variant="standard"
            sx={{
              marginBottom: 4,
            }}
          >
            <Input
              id="username-input"
              placeholder="Username/Email/Phone"
              value={myUsername}
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
              onChange={(event) => setMyUsername(event.target.value)}
            />
          </FormControl>
          <FormControl
            variant="standard"
            sx={{
              marginBottom: 4,
            }}
          >
            <Input
              id="password-input"
              label="Filled"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              startAdornment={
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormControl>
          {loginErrors && (
            <Typography color="red" fontSize="small">
              {loginErrors}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{
              marginTop: 2,
            }}
            onClick={(e) => {
              handleLogin(e)}}
          >
            Login
            {loading && <CircularProgress size={25} />}
          </Button>
        </CardContent>
        <p
          style={{
            textAlign: "right",
            padding: "0 14px 0 0",
            margin: "0 0 10px 0",
            cursor: "pointer",
          }}
          onClick={() => setForgotPassword(true)}
        >
          Forgot Password?
        </p>
      </Card>

      <Dialog
        open={mfaEnabled}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .css-hlj6pa-MuiDialogActions-root": {
            paddingRight: "24px",
            paddingLeft: "24px",
          },
          "& .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "60%",
          },
        }}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ paddingBottom: 2 }}
          >
            <Lock color="#0033A1" /> Please input your One Time Passcode
          </DialogContentText>
          <MuiOtpInput
            value={otp}
            length={6}
            autoFocus
            onChange={handleChange}
            validateChar={validateChar}
            onComplete={handleComplete}
            TextFieldsProps={{ size: "small" }}
            display="flex"
            sx={otpInputStyle}
          />
        </DialogContent>
      </Dialog>
      
      <Dialog open={forgotPassword} onClose={() => setForgotPassword(false)}>
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            please enter your email address here.
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            name='email'
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setEmailToReset(e.target.value);              
              handleValidationCheck(e);
            }}
            value={emailToReset}
          />
          {errors.email && (
            <Typography
              color="red"
              fontSize="small"
              sx={{ marginBottom: 2 }}
            >
              {errors.email}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setForgotPassword(false)} sx={{ backgroundColor: "#B20808" }}>Cancel</Button>
          <Button disabled={ emailToReset === '' ||  Object.keys(errors).length > 0} variant='contained' onClick={resetUserAccountCredentials} sx={{ backgroundColor: "#052A6C" }}>Reset</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={enablingMFA}
        onClose={() => {
          logout();
          setLoading(false);
          setEnablingMFA(false);}}>
        <DialogTitle>Configure MFA</DialogTitle>
        <DialogContent>
          <DialogContentText>
            As an administrator you need to enable MFA.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              logout();
            }}
            sx={{ backgroundColor: "#Be2525" }}>
              Logout
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              enableMFAForAdmin();
            }}
            sx={{ backgroundColor: "#052A6C" }}>
              Setup MFA
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default LoginCard;
