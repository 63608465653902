import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import ShareholderWrapper from './ShareholderWrapper';
import { LocalPhone, Send } from '@mui/icons-material';

function ShareholderClaimUnpaidDividends() {
  return (
    <ShareholderWrapper title="Claim unpaid dividends" sidebar={false}>
      <Stack
        direction="column"
        sx={{
          justifyContent: 'space-between',
          background: 'linear-gradient(to bottom,#052A6C 30%, #F5F5F5 30%)',
          margin: 0,
          padding: {
            xl: '4em 18em',
            lg: '4em 8em',
            md: '2em 1em',
            sm: '2em 1em',
            xs: '1em',
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: {
              md: 32,
              sm: 20,
            },
            color: 'white',
          }}
        >
          How to Claim for unpaid dividends
        </Typography>
        <Stack direction="column" sx={{ bgcolor: 'white', borderRadius: 4 }}>
          <Stack direction="row">
            <img
              src={require('../../assets/modified-images/dividendsFlag.jpg')}
              alt="unpaid dividends"
              style={{ width: '100%', height:"600px" }}
            />
          </Stack>
          <Stack
            direction="column"
            sx={{
              pl: {
                md: 10,
                sm: 10,
                xs: 4,
              },
              pr: {
                md: 10,
                sm: 10,
                xs: 4,
              },
              pt: {
                md: 4,
                sm: 2,
                xs: 2,
              },
              pb: {
                md: 20,
                sm: 10,
                xs: 10,
              },
            }}
          >
            {/* <Stack direction="column" sx={{ mt: 4 }}> */}
          <Grid
            container
            component="form"
            sx={{
              p: {
                md: '3em 6em 6em 6em',
                sm: '3em 3em 3em 3em',
              },
            }}
          >
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                marginBottom: '2%',
                color: '#000000',
              }}><strong>Please contact the registra for more information</strong></Typography>
              </Grid>
            <Grid item xs={5}> 
              
               
            <LocalPhone/> 
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>Dial</Typography>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>+256-7577072773</Typography>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>+256-760415945</Typography>
            <Typography
              sx={{
                fontWeight: {
                  md: 500,
                  sm: 400,
                  xs: 300,
                },
                fontSize: { md: 28, sm: 24, xs: 20 },
                color: '#000000',
              }}>+256-414237504</Typography>
              </Grid>
              <Grid item xs={2}>
              <Typography
                sx={{
                  fontWeight: {
                    md: 500,
                    sm: 400,
                    xs: 300,
                  },
                  fontSize: { md: 28, sm: 24, xs: 20 },
                  color: '#000000',
                }}>OR</Typography>
                </Grid>
              <Grid item xs={5}>
                <Send/>
              <Typography
                sx={{
                  fontWeight: {
                    md: 500,
                    sm: 400,
                    xs: 300,
                  },
                  fontSize: { md: 28, sm: 24, xs: 20 },
                  color: '#000000',
                }}>Send an email to:</Typography>
                <Typography
                  sx={{
                    fontWeight: {
                      md: 500,
                      sm: 400,
                      xs: 300,
                    },
                    fontSize: { md: 28, sm: 24, xs: 20 },
                    color: '#000000',
                  }}> <a href="mailto:shareholder@candrgroup.co.ug?body=First%20line%0Asecond%20line%0A%0ANew%20paragraph">shareholder@candrgroup.co.ug</a></Typography>
                {/* <FormControl
                  variant="standard"
                  sx={{ width: '100%', mb: 2, padding: '0 4px 0 4px' }}
                >
                  <StyledTypography>Email</StyledTypography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="_"
                    size="small"
                  />
                </FormControl> */}
              </Grid>
              </Grid>
              {/* <Typography sx={{ fontSize: 26, fontWeight: 500, pb: 4 }}>
                Why were my dividends not paid?
              </Typography>
              <Typography
                sx={{ fontSize: 22, fontWeight: 300, textAlign: 'justify' }}
              >
                Have your dividends not been paid? Worry not! There could be
                various reasons why this is happening. One, you could have made
                an error in the account you provided for payouts, or your still
                using an outdated challenge blah blah blah. After coming to an
                understanding of why this is happening, you can now look at the
                steps to take while claiming for unpaid dividends.
              </Typography> */}
            {/* </Stack> */}
            <Stack direction="column" sx={{ mt: 4 }}>
              {/* <Typography sx={{ fontSize: 26, fontWeight: 500, pb: 4 }}>
                How to claim unpaid dividends
              </Typography>
              <Typography
                sx={{ fontSize: 22, fontWeight: 300, textAlign: 'justify' }}
              >
                In case a shareholder has not claimed/ not been able to receive
                dividend for any of the last seven years and which has not yet
                been transferred to the Investor Education & Protection Fund
                (IEPF), he /she can claim this unpaid dividend by clicking the
                link below.
              </Typography> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ShareholderWrapper>
  );
}

export default ShareholderClaimUnpaidDividends;
