import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store/store';
import { NavigationContextProvider } from './store/NavigationContext';
import { AuthProvider } from './store/authContext';
// import { getFunctionalGroups } from './store/securitySlice';

// store.dispatch(getFunctionalGroups());

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <NavigationContextProvider>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
      ,
    </NavigationContextProvider>
  </AuthProvider>
);

reportWebVitals();
