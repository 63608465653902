import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MiniDrawer from "../../components/newSideDrawer/miniSideDrawer";
import DataTable from "../../components/tables/DataTable";
import { shareholderBulkUpload } from "../../service/userService";
import NavigationContext from "../../store/NavigationContext";
import {
  openAlert,
  setAlertMessage,
  setAlertTitle,
} from "../../store/alertSlice";

const AdministratorShareholderBulkUploadView = () => {
  const navigationContext = useContext(NavigationContext);
  const { sidebar } = navigationContext;
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "No", headerName: "ID" },
    { field: "shareholder_name", headerName: "Name" },
    { field: "shareholder_other_name", headerName: "Other Name" },
    { field: "shareholder_scd_number", headerName: "SCD Number" },
    { field: "certificate_number", headerName: "Certificate Number" },
    { field: "broker_code", headerName: "Broker Code" },
    { field: "primary_phone_number", headerName: "Phone Number" },
    { field: "secondary_phone_number", headerName: "Secondary Number" },
    { field: "primary_email", headerName: "Email" },
    { field: "secondary_email", headerName: "Secondary Email" },
    { field: "primary_address", headerName: "Address" },
    { field: "secondary_address", headerName: "Secondary Address" },
    { field: "payment_account_number", headerName: "Payment Number" },
    { field: "payment_account_name", headerName: "Payment Account Name" },
    { field: "payment_mode_code", headerName: "Payment Mode Code" },
    { field: "tax_rate_code", headerName: "Tax Rate Code" },
    { field: "shares_held", headerName: "Shares Held" },
    { field: "tin", headerName: "Tin" },
    { field: "individual", headerName: "Individual" },
  ];

  const bulkShareholderTableStyles = {
    height: "400px",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "white",
  };

  //submit data function

  const bulkShareholderUpload = (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare data for bulk shareholder upload
    const bulkShareholderData = {
      file_name: user.fileName,
      shareholders_list: user.excelData,
    };

    // Perform bulk shareholder upload
    shareholderBulkUpload(bulkShareholderData)
      .then((response) => {
        console.log('Bulk upload Resp:', response);

        // Handle success response
        if (response?.data?.operation_code === 0) {
          dispatch(setAlertTitle("Success"));
          dispatch(setAlertMessage(response.data.operation_description));
        } else {
          dispatch(setAlertTitle("ERROR!"));
          dispatch(setAlertMessage(response?.data?.message));
        }

        dispatch(openAlert());
        navigate("/file-approve");
      })
      .catch((error) => {
        dispatch(setAlertTitle("ERROR!"));
        dispatch(setAlertMessage("An unexpected error occurred."));
        dispatch(openAlert());
        navigate("/file-approve");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <MiniDrawer>
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: "20px",
        }}
      >
        <Grid item xs={8} md={8} lg={8}>
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "20px",
              color: " #0A2240",
              marginTop: "10px",
            }}
          >
            View uploaded shareholders
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          lg={4}
          sx={{ display: "flex", justifyContent: "flex-end", columnGap: 1 }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#B20808",
              // marginLeft: "160px",
              textTransform: "none",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              color: " #FFFFFF",
            }}
            onClick={() =>
              navigate("/shareholders", {
                replace: true,
              })
            }
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{
              backgroundColor: "#052A6C",
              textTransform: "none",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              color: " #FFFFFF",
            }}
            disabled={loading}
            onClick={bulkShareholderUpload}
          >
            {loading && (
              <CircularProgress size={25} style={{ color: "white" }} />
            )}
            {loading ? "" : "Upload"}
          </Button>
        </Grid>
      </Grid>

      <DataTable
        rows={user.excelData}
        columns={columns}
        loading={!user.excelData.length}
        getRowId={(row) => row.No}
        sx={bulkShareholderTableStyles}
      />
    </MiniDrawer>
  );
};

export default AdministratorShareholderBulkUploadView;
