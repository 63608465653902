// import React, { useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/logo.png";
import avatar from "../assets/images/avatar.png";
import "./ShareholderNav.css";
import { NavLink } from "react-router-dom";

const ShareholderNav = () => {
  return (
    <Navbar className="nav-bar" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand href="#">
          <img
            src={logo}
            width="65px"
            height="70px"
            className="d-inline-block align-top"
            alt="Stanbic logo"
          />
        </Navbar.Brand>
        <Navbar.Brand className="navbar-brand ">
          Shareholder Management Solution
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="justify-content-end flex-grow-1 pe-3 navbar-menu">
            <NavLink
              to="/shareholder"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link non-active-link"
              }
            >
              Home
            </NavLink>
            {/* <NavLink
              to="/claim-dividends"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link non-active-link"
              }
            >
              Claim Unpaid Dividends
            </NavLink> */}
          </Nav>

          <img
            src={avatar}
            alt="User avatar"
            className="avatar"
            style={{ marginRight: "7%" }}
          ></img>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ShareholderNav;
